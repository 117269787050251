import { permissions } from 'variables/InstancePermissions'

const getNav = (CONSTANTS) => [
  {
    collapse: false,
    name: CONSTANTS.DASHBOARD,
    icon: 'feather-icons pie-chart',
    path: '/dashboard',
    layout: '',
    permission: [permissions.DASHBOARD_READ, permissions.DASBOARD_WRITE],
  },
  {
    collapse: true,
    name: CONSTANTS.EXECUTION_DETAILS,
    icon: 'feather-icons server',
    state: 'executionDetailsCollapse',
    layout: '',
    permission: [permissions.ACCESS_TOKEN_WRITE],
    views: [
      {
        path: '/execution-details/data-ingestion',
        name: CONSTANTS.DATA_INGESTION,
        miniName: 'DI',
        layout: '',
        permission: [permissions.ACCESS_TOKEN_WRITE],
      },
      {
        path: '/execution-details/dq-executor',
        name: CONSTANTS.DQ_EXECUTOR,
        miniName: 'EX',
        layout: '',
        permission: [permissions.ACCESS_TOKEN_WRITE],
      },
    ],
  },
  {
    collapse: false,
    name: CONSTANTS.TODO_LIST,
    icon: 'feather-icons cloud-lightning',
    path: '/predictions',
    layout: '',
    permission: [permissions.TODO_LIST],
  },
  {
    collapse: false,
    name: CONSTANTS.PREDICTION_HISTORY,
    icon: 'feather-icons folder',
    path: '/prediction-history',
    layout: '',
    permission: [permissions.PREDICTION_HISTORY],
  },
  {
    collapse: false,
    name: CONSTANTS.QUERY_LOG,
    icon: 'feather-icons layers',
    path: '/query-log',
    layout: '',
    permission: [permissions.QUERY_LOG],
  },
  {
    collapse: true,
    name: CONSTANTS.STUDY_DQ,
    icon: 'feather-icons file-text',
    state: 'componentsCollapse',
    layout: '',
    permission: [permissions.EDIT_CHECKS],
    views: [
      {
        path: '/edit-checks/preconf-metadata',
        name: CONSTANTS.PRECONF_METADATA,
        miniName: 'P',
        layout: '',
        permission: [permissions.PRECONF_WRITE],
      },
      {
        path: '/edit-checks/new',
        name: CONSTANTS.NEW_DQ,
        miniName: 'N',
        layout: '',
        permission: [permissions.NEW_RULE_WRITE],
      },

      {
        path: '/edit-checks/for-approval',
        name: CONSTANTS.FOR_APPROVAL,
        miniName: 'A',
        layout: '',
        permission: [permissions.RULE_FOR_APPROVAL_WRITE],
      },
      {
        path: '/edit-checks/unassigned',
        name: CONSTANTS.FOR_DEVELOPMENT,
        miniName: 'U',
        layout: '',
        permission: [permissions.RULE_FOR_DEVELOPMENT_WRITE],
      },
      {
        path: '/edit-checks/unassigned',
        name: CONSTANTS.FOR_TESTING,
        miniName: 'U',
        layout: '',
        permission: [permissions.RULE_FOR_TESTING_WRITE],
      },
      {
        path: '/edit-checks/assigned',
        name: CONSTANTS.ASSIGNED_TO_ME,
        miniName: 'M',
        layout: '',
        permission: [
          permissions.DEV_RULE_ASSIGNED_TO_ME_WRITE,
          permissions.TEST_RULE_ASSIGNED_TO_ME_WRITE,
        ],
      },
      {
        path: '/edit-checks/active',
        name: CONSTANTS.ACTIVE_DQ,
        miniName: 'R',
        layout: '',
        permission: [
          permissions.ACTIVE_RULE_WRITE,
          permissions.ACTIVE_RULE_READ,
        ],
      },
      {
        path: '/edit-checks/inactive',
        name: CONSTANTS.INACTIVE_DQ,
        miniName: 'I',
        layout: '',
        permission: [
          permissions.INACTIVE_RULE_WRITE,
          permissions.INACTIVE_RULE_READ,
        ],
      },
    ],
  },
  {
    collapse: true,
    name: CONSTANTS.STUDY_DQ_FUNCTION,
    icon: 'feather-icons clipboard',
    state: 'functionsCollapse',
    layout: '',
    permission: [permissions.EDIT_CHECKS_FUNCTION],
    views: [
      {
        path: '/edit-checks/functions/new',
        name: CONSTANTS.NEW_FUNCTIONS,
        miniName: 'N',
        layout: '',
        permission: [permissions.NEW_FUNCTION_WRITE],
      },
      {
        path: '/edit-checks/functions/for-approval',
        name: CONSTANTS.FOR_APPROVAL_FUNCTIONS,
        miniName: 'A',
        layout: '',
        permission: [permissions.FUNCTION_FOR_APPROVAL_WRITE],
      },
      {
        path: '/edit-checks/functions/active',
        name: CONSTANTS.ACTIVE_FUNCTIONS,
        miniName: 'AC',
        layout: '',
        permission: [permissions.ACTIVE_FUNCTION_READ],
      },
    ],
  },
  {
    collapse: true,
    name: CONSTANTS.SETTINGS,
    icon: 'feather-icons settings',
    state: 'settingsCollapse',
    layout: '',
    permission: [
      permissions.USER_MANAGEMENT_WRITE,
      permissions.ACCESS_TOKEN_WRITE,
      permissions.AUDIT_LOG_WRITE,
    ],
    views: [
      {
        path: '/user-management/UserList',
        name: CONSTANTS.USER_MANAGEMENT,
        miniName: 'U',
        layout: '',
        permission: [permissions.USER_MANAGEMENT_WRITE],
      },
      {
        path: '/auth/access-token',
        name: CONSTANTS.ACCESS_TOKENS,
        miniName: 'A',
        layout: '',
        permission: [permissions.ACCESS_TOKEN_WRITE],
      },
    ],
  },
  {
    collapse: false,
    name: CONSTANTS.STUDY_REGISTRATION,
    icon: 'feather-icons bookmark',
    state: 'settingsCollapse',
    layout: '',
    permission: [permissions.STUDY_REGISTRATION],
    path: '/study-registration',
  },
  {
    collapse: false,
    name: CONSTANTS.JOB_MANAGEMENT,
    icon: 'feather-icons briefcase',
    state: 'jobCollapse',
    layout: '',
    permission: [permissions.JOB_MANAGEMENT],
    path: '/jobs',
  },
  {
    collapse: false,
    name: CONSTANTS.REPORTS,
    icon: 'feather-icons bar-chart-2',
    layout: '',
    path: '/reports',
    permission: [permissions.REPORTS],
  },
  {
    collapse: false,
    name: CONSTANTS.RELEASE_MANAGEMENT,
    icon: 'feather-icons refresh-ccw',
    layout: '',
    path: '/release',
    permission: [permissions.RELEASE_MANAGEMENT],
  },
  {
    collapse: false,
    name: CONSTANTS.STUDY_ASSIGNMENT,
    icon: 'feather-icons bookmark',
    state: 'settingsCollapse',
    layout: '',
    permission: [
      permissions.STUDY_MANAGEMENT_WRITE,
      permissions.STUDY_MANAGEMENT_READ,
    ],
    path: '/study-assignment',
  },
  {
    collapse: false,
    name: CONSTANTS.IRL,
    icon: 'feather-icons layers',
    path: '/irl',
    layout: '',
    permission: [permissions.IRL_READ, permissions.IRL_WRITE],
  },
  {
    collapse: false,
    name: CONSTANTS.QDR,
    icon: 'feather-icons layers',
    path: '/query-detail-report',
    layout: '',
    permission: [permissions.QDR],
  },
  {
    collapse: false,
    name: CONSTANTS.SYSTEM_CONFIGURATION,
    icon: 'feather-icons tool',
    layout: '',
    permission: [permissions.ACCESS_TOKEN_WRITE],
    path: '/admin/configuration',
  },
  {
    collapse: false,
    name: CONSTANTS.SQL_CONSOLE,
    icon: 'feather-icons monitor',
    layout: '',
    permission: [permissions.ACCESS_TOKEN_WRITE, permissions.SQL_CONSOLE_WRITE],
    path: '/admin/sql-console',
  },
]

export default getNav
