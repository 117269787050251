import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import exportFromJSON from 'export-from-json'
import Papa from 'papaparse'
import moment from 'moment'

import { getItem } from 'utils/localStorageController'

const study_id = getItem('_study_id')
const initialState = {
  graphData: {
    graph:{},
    count:{
        Open : 0,
        Closed : 0,
        Cancelled : 0,
        Answered : 0,
    }
  },
  QDRData:{
      data:[],
      paging: {
        page_number: 1,
        per_page: 10,
        total_count: 0,
      },
      sorting: {
        sort_field: '',
        sort_order: '',
      },
  },
  filterOption:{
  },
  filterData:{
  }
}

const queryDetailReport = createSlice({
  name: 'queryDetailReport',
  initialState,
  reducers: {
    setGraphData: (state, action) => {
      const { data } = action.payload
      if(data.count){
        state.graphData = data
      }
      
    },
    setQDRData: (state, action) => {
        state.QDRData.data = action.payload.data
        state.QDRData.paging = action.payload.paging
      },
    setFilterOption:  (state, action) => {
      state.filterOption = action.payload
    },
    setFilterData:  (state, action) => {
      console.log("j", state.filterData, action.payload)
      state.filterData = action.payload
    },
    updateSortField(state, action) {
      state.QDRData.sorting.sort_field = action.payload.sortField 
      state.QDRData.sorting.sort_order = action.payload.sortOrder
    }
  },
})

export const {
    setGraphData,
    setQDRData,
    setFilterOption,
    setFilterData,
    updateSortField
} = queryDetailReport.actions

export const getGraphReport = () => async (dispatch) => {
  try {
    const { data } = await DataService.get(`/query_report?study_id=${study_id}&view_type=graph`)
    console.log("getGraphReport",data)
    dispatch(setGraphData(data))
  } catch (error) {
    console.error('getGraphReport error', error)
  }
}

export const getFilterData = () => async (dispatch) => {
  try {
    const { data } = await DataService.get(`/query_report/filters?study_id=${study_id}`)
    dispatch(setFilterData(data.data))
    console.log("getFilterData",data.data)
  } catch (error) {
    console.error('getFilterData error', error)
  }
}

export const getQDRData = (params) => async (dispatch) => {

  console.log("params",params);

  let page = params?.page ? `&page=${params.page}` : ''
  let per_page = params?.per_page ? `&per_page=${params.per_page}` : ''
  let filterOption = params?.filterOption ? params.filterOption:null
  let sortField = params?.sortField ? `&sort_by=${params.sortField}` : ''
  let sortOrder = params?.sortOrder ? `&order=${params.sortOrder}` : ''
    try {
      const { data } = await DataService.get(`/query_report?study_id=${study_id}${page}${per_page}${sortField}${sortOrder}&view_type=table`,filterOption)
      console.log("getQDRData",data)
      dispatch(setQDRData(data))
    } catch (error) {
      console.error('getQDRData error', error)
    }
  }

export const downloadQDR = () => async () => {
    try {
        const { data } = await DataService.get(`/query_report/download?study_id=${study_id}`,{ Accept: 'text/csv' })

        const parse_data = Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
        })

        const logData =
          parse_data['data'].length > 0
            ? parse_data['data']
            : [
                Object.assign(
                  {},
                  ...parse_data['meta']['fields'].map((key) => ({ [key]: '' }))
                ),
              ]
        const fileName = `sdq_query_detail_report_${study_id}_study_id_${moment().format(
          'DD-MM-YYYY-HH-mm-ss'
        )}`
        exportFromJSON({
          data: logData,
          fileName,
          exportType: exportFromJSON.types.csv,
        })

    } catch (error) {
      console.error('downloadQDR error', error)
    }
  }

export default queryDetailReport.reducer
