import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'reactstrap'
import { debounce } from 'utils/debounce'
import './index.scss'

export default function CustomSearch(props) {
  const clearSearch = useSelector((state) => state.columnLevelSearch.clearSearch)
  const inputRef = useRef(null)
  const { onFilter } = props
  const [searchValue,setSearchValue] = useState('')
  useEffect(() => {
    inputRef.current.value = ''
    setSearchValue('')
  }, [clearSearch])

  const filter = () => {
    onFilter(getValue())
  }

  const optimizedVersion = debounce(filter, 600)

  const handleSearchChange = (value) => {
    optimizedVersion(value)
    setSearchValue(inputRef.current.value)
  }

  const getValue = () => {
    return inputRef.current.value
  }
  return (
    <span className="c-search-input">
      <Input
        type="search"
        innerRef={inputRef}
        placeholder="&#xF002;"
        fontFamily="Arial, FontAwesome"
        onChange={handleSearchChange}
        className="form-control-sm table-search"
        value={searchValue}
        style={{ height: '26px' }}
      />
    </span>
  )
}
