import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import {
    activateAlert,
  } from 'reduxStore/slices/alert/AlertSlice'
import { getItem } from 'utils/localStorageController'

const study_id = getItem('_study_id');

const initialState = {
    list : [],
    colManagerId: null
}

const columnManager = createSlice({
  name: 'columnManager',
  initialState,
  reducers: {
    setColManagerList: (state, action) => {
        state.list = action.payload
      },
    setQDRColManId: (state, action) => {
        state.colManagerId = action.payload
    },
  },
})

export const {
    setColManagerList,
    setQDRColManId
} = columnManager.actions


export const getColManagerList = ({entity_key,entity_value}) => async (dispatch) => { 
    try {
      const { data } = await DataService.get(`/irl/filter?study_id=${study_id}&entity_key=${entity_key}&entity_value=${entity_value}`)
      console.log("setColManagerList",data.data.data)
      dispatch(setColManagerList(data.data.data));
      dispatch(setQDRColManId(data.data.id));
      
    } catch (error) {
      console.error('getColManagerList error', error)
    }
  }

  export const updateColManagerList = (updatedColumn,id) => async (dispatch) => {

    DataService.put(`/irl/filter/${id}?study_id=${study_id}`,{data:updatedColumn})
    .then((res) => {
      dispatch(setColManagerList(updatedColumn));  
      dispatch(
        activateAlert({
          title: 'Column Management Filter Updated Successfully',
          color: 'success',
        })
      )
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : 'Update Failed',
          color: 'danger',
        })
      )
    })
  }


export default columnManager.reducer
