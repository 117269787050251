export const downloadBlob = (data, filename, type = 'application/pdf') => {
    const blob = new Blob([data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

export const downloadCSVblob = (data, filename, type = 'text/csv') => {
    const blob = new Blob([data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}
