const global_permissions = {
    ACCOUNT_ADMIN: [
      'user_management_write',
      'audit_log_write',
      'access_token_write',
      'study_management_write',
    ],
    SDQ_ADMIN: [
      'sql_console_write'
    ]
  }
const lsac_global_permission = {
  ACCOUNT_ADMIN: [
    'audit_log_write',
    'access_token_write',
    'study_management_write',
  ],
  SDQ_ADMIN: [
    'sql_console_write'
  ]
}

export {global_permissions, lsac_global_permission}
