import axios from 'axios'
import { API_URL } from './config'
import { getItem } from './utils/localStorageController'
import i18next from 'i18next'
const API_ENDPOINT = API_URL

const authHeader = () => ({
  _sdq_user_token: `${getItem('_token')}`,
})

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
  isPolling: false
})

const validateStudyId = (path) => {
  let paramString = path.split('?')?.[1];
  let queryString = new URLSearchParams(paramString)

  for (let pair of queryString.entries()) {
    if(pair[0] == "study_id" && !pair[1])
      throw new Error(i18next.t("CONSOLE_ERROR_MESSAGES.STUDY_ID"));
  }

}

window.axios = client

const MSG_SERVER_ERROR = 'INTERNAL SERVER ERROR'
/**
Dataservice class handles all operations by creating an instance of the mentods.
To access the local storage use the utility
 */
class DataService {
  static get(path = '', optionalHeader = {}, cancelToken = null, isPolling = false) {
    validateStudyId(path)
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader(), ...optionalHeader },
      cancelToken,
      isPolling
    })
  }

  static post(path = '', data = {}, optionalHeader = {}, isPolling = false) {
    validateStudyId(path)
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
      isPolling
    })
  }

  static patch(path = '', data = {}) {
    validateStudyId(path)
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    })
  }

  static put(path = '', data = {}) {
    validateStudyId(path)
    return client({
      method: 'PUT',
      url: path,
      data: data,
      headers: { ...authHeader() },
    })
  }

  static delete(path = '', data = {}) {
    validateStudyId(path)
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    })
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */

// client.interceptors.request.use(
//     config => {
//         if (!config.headers._sdq_user_token) {
//             const token = getItem('_token');
//             if (token !== undefined && token !== '' && token !== null) {
//                 config.headers._sdq_user_token = `${getItem('_token')}`;
//             } else {
//                 window.open('/?src=auth-error', '_self');
//             }
//         }

//         return config;
//     },
//     error => Promise.reject(error)
// );
// client.interceptors.request.use((config) => {
//   const requestConfig = config;
//   const { headers } = config;
//   requestConfig.headers = {
//     ...headers,
//     Authorization: `Bearer ${getItem("access_token")}`,
//   };

//   return requestConfig;
// });

export { DataService }
