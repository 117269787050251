import React, { Fragment } from 'react'
import { HeaderWrap } from './style'
import LeftSection from './LeftSection'
import RightSection from './RightSection'

const AppHeader = ({history}) => {
  return (
    <Fragment>
      <HeaderWrap>
        <LeftSection />
        <RightSection  history={history}/>
      </HeaderWrap>
    </Fragment>
  )
}

export default AppHeader
