import { useSelector, useDispatch } from 'react-redux'
import { roleSwitch } from 'reduxStore/slices/user/UserSlice'
import { Nav, UncontrolledDropdown, DropdownToggle, CardTitle, DropdownMenu,DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next'
import { getItem } from 'utils/localStorageController'
import { setStudyID } from 'reduxStore/slices/study'

function RoleList() {
  const { t } = useTranslation()
  const CONSTANTS = t('COMPONENTS.ROLE_LIST')
  const dispatch = useDispatch()

  const {signIn} = useSelector((state) => state.user)
  let source_system = getItem('source_system')

  return (
    <>
      <Nav className="mr-3 switch_role">
        <span className='topNavCompTitle'>{CONSTANTS.HEADER}</span>
              <UncontrolledDropdown>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                <CardTitle
                      tag="h5"
                      className="mb-0 text-white mt-1"
                    >
                       {signIn.activeUserRole} <span class="btn-inner--icon float-right"><i class="ni ni-bold-down"></i></span>
                    </CardTitle>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{CONSTANTS.HEADER}</h6>
                  </DropdownItem>
                  <DropdownItem divider />
                  {signIn.all_roles.map((role) => (<>
                  <DropdownItem
                    className={`${signIn.activeUserRole == role ? 'active' : ''}`}
                    onClick={() => {
                      dispatch(roleSwitch(role))
                      dispatch(setStudyID({ study_id: '', id: '', src_system: source_system }))
                    }}
                  >
                    {/* <span className={`avatar rounded-circle mr-2 ${signIn.activeUserRole == role ? 'bg_primary' : ''} `}>{role?.charAt(0)?.toUpperCase()}</span> */}
                    <span>{role}</span>
                    {signIn.activeUserRole == role && <i className='fa fa-check'></i> }
                  </DropdownItem>
                  </>
                  ) )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
    </>
  )
}

export default RoleList

