import { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from 'components/Table/index'
import {
  getStudyList,
  setStudyID,
  setModalState,
  makeStudyFavorite,
  updateSortField,
} from 'reduxStore/slices/study/index'
import SearchBar from 'components/SearchBar'
import { Card, Row, ModalComponent, Col } from 'sdq-ui'
import { columns } from 'variables/StudyListColumn'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

function StudyList() {
  const searchBarRef = useRef(null)
  const { t } = useTranslation()
  const CONSTANTS = t('COMPONENTS.STUDY_LIST')
  const COMMON_CONSTANTS = t('COMMON')
  const dispatch = useDispatch()

  const INSTANCE = useSelector((state) => state.instance.instance)
  const { activeInstanceID } = INSTANCE

  const { list, activeStudyID, modalState, pagination, sorting } = useSelector(
    (state) => state.study.study
  )
  const { records_per_page, total_records } = pagination
  const { sort_field, sort_order } = sorting

  const apiCall = () => {
    dispatch(
      getStudyList({
        instance_id: activeInstanceID,
        sortField: sort_field || 'is_favorite',
        sortOrder: sort_order || 'desc',
        searchText: searchBarRef.current.value,
        per_page: records_per_page,
      })
    )
  }

  const study_fav_list_data = list.map((data) => {
    return {
      id: data.id,
      fav_study: (
        <strong
          className={`favorite ${
            data.is_favorite ? 'fas fa-heart' : 'far fa-heart'
          }`}
        ></strong>
      ),
      study_id: data.study_id,
      description: data.description,
      status: data.status,
      src_system: data.source_system,
    }
  })

  const options = {
    onClick: (event) => {
      let { originalEvent: e, data: row, index: rowIndex} = event
      if(e?.target?.textContent != '...'+COMMON_CONSTANTS.READ_MORE && e?.target?.textContent != COMMON_CONSTANTS.READ_LESS) {
        if (e.target.tagName.toLowerCase() == 'strong') {
          handleFavoriteStudy(list.find((data) => data.id == row.id))
        } else {
          dispatch(setStudyID({ study_id: row.study_id, id: row.id, src_system: row.src_system, status: row.status}))
          window.location.reload(false)
          toggleState()
        }
      }
    }
  }
  
  function handleFavoriteStudy(data) {
    const value = data.is_favorite ? 0 : 1
    dispatch(makeStudyFavorite({ id: data.id, favorite_value: value }))
  }

  const toggleState = () => {
    dispatch(setModalState(!modalState))
  }

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    sortField = sortField || 'is_favorite'
    sortOrder = sortOrder || 'desc'
    if (
      type === 'pagination' ||
      type === 'sort' ||
      sortField !== sort_field ||
      sortOrder !== sort_order
    ) {
      dispatch(
        getStudyList({
          instance_id: activeInstanceID,
          page,
          per_page: sizePerPage,
          sortField: sortField,
          sortOrder: sortOrder,
          searchText: searchBarRef.current.value
        }))
      }
      dispatch(updateSortField({ sortField, sortOrder }))
  }
  return (
    <>
      <ModalComponent
        size="lg"
        isOpen={modalState}
        classes="study-modal-table"
        onClose={activeStudyID || list.length === 0 ? toggleState : null}
        keyboard={false}
        headerCharCode={(activeStudyID || list.length === 0) && 'x'}
        headerClasses={classNames({
          'disable-close-action': !(activeStudyID || list.length === 0),
        })}
        headerElement={CONSTANTS.HEADER}
        bodyElement={
          <>
            <Row>
              <Col>
                <SearchBar
                  forwardRef={searchBarRef}
                  id={'search'}
                  placeholder={'Search Study'}
                  apiCall={apiCall}
                  classes={'col-md-2'}
                  isLabelHidden={true}
                  wrapperClass="px-3"
                />
              </Col>
            </Row>
            <div className="text-center">
              <Row>
                <div className="col">
                  <Card className="study-list-card">
                    <Table
                      remotePagination={true}
                      total_records={total_records}
                      records_per_page={records_per_page}
                      onTableChange={handleTableChange}
                      columns={columns}
                      data={study_fav_list_data}
                      keyField={'id'}
                      onRowClick={options.onClick}
                      hideFilters={true}
                      rowClassName={'cursor-pointer'}
                    />
                  </Card>
                </div>
              </Row>
            </div>
          </>
        }
        isFooterVisible={false}
      />
      <button
        className="btn btn-icon btn-default btn-study mr-4"
        type="button"
        onClick={() => {
          dispatch(setModalState(true))
          dispatch(
            getStudyList({
              instance_id: activeInstanceID,
              sortField: 'is_favorite',
              sortOrder: 'desc',
              per_page: records_per_page,
            })
          )
        }}
      >
        <span className="btn-inner--text">
          {activeStudyID || CONSTANTS.SELECT_STUDY_LABEL}
        </span>
        <span className="btn-inner--icon">
          <i className="ni ni-bold-down"></i>
        </span>
      </button>
    </>
  )
}

export default StudyList
