import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import {
  activateAlert,
} from 'reduxStore/slices/alert/AlertSlice'
import { setClearSearch, updateFilter } from '../column-level-search'
import moment from 'moment'
import store from 'store'
import { checkCondition } from 'utils/conditional'

const sameJobLabel =  'Job with same label'

const initialState = {
  newJobReqData: {
    study_id: null,
    job_type: 'DATA_INGESTION_AND_RULE_EXECUTION',
    rrule_string: null,
    job_run_type: "default",
    default_job_id: null,
    ec_ids: [],
    job_label: ''
  },
  job_label_duplicate: false,
  allJobs: {
    data: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting:{
      sort_field:'',
      sort_order:''
    },
    data_load: false,
  },
  jobHistoryById: {
    data: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting:{
      sort_field:'',
      sort_order:''
    }
  },
  jobRunById: {
    data: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting:{
      sort_field:'',
      sort_order:''
    },
    job_exception_string:'',
  },
  bulk_update: false,
  study_data:[],
  job_edit_checks: [],
}

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setNewJobReqData: (state, action) => {
      state.newJobReqData = action.payload
    },
    setDefaultNewJobReqData: (state) => {
      state.newJobReqData = {
        study_id: null,
        job_type: 'DATA_INGESTION_AND_RULE_EXECUTION',
        rrule_string: null,
        job_run_type: "default",
        default_job_id: null,
        ec_ids: [],
        job_label: ''
      }
    },
    createSplitJobsFail: (state, action) => {
      state.job_label_duplicate = action.payload
    },
    setJobHistoryById: (state, action) => {
      const { data, paging } = action.payload
      state.jobHistoryById.data = data
      state.jobHistoryById.pagination.current_page = paging?.page_number ?? 1
      state.jobHistoryById.pagination.records_per_page = paging?.per_page ?? 10
      state.jobHistoryById.pagination.total_records = paging?.total_count ?? 0
      state.jobHistoryById.pagination.pages = Math.ceil(
        paging?.total_count / paging?.per_page
      ) ?? 0
    },
    setJobs: (state, action) => {
      const { data, paging } = action.payload
      state.allJobs.data = data
      state.allJobs.pagination.current_page = paging.page_number
      state.allJobs.pagination.records_per_page = paging.per_page
      state.allJobs.pagination.total_records = paging.total_count
      state.allJobs.pagination.pages = Math.ceil(
        paging['total_count'] / paging['per_page']
      )
    },
    setJobRunById: (state, action) => {
      const { data, paging = {} } = action.payload
      state.jobRunById.data = data
      state.jobRunById.pagination.current_page = paging.page_number ?? 1 
      state.jobRunById.pagination.records_per_page = paging.per_page ?? 10
      state.jobRunById.pagination.total_records = paging.total_count ?? 0
      state.jobRunById.pagination.pages = Math.ceil(
        paging['total_count'] / paging['per_page']
      )
    },
    getJobsFail: (state, action) => {
      state.allJobs.data = []
      state.allJobs.pagination.total_records = 0
      state.allJobs.pagination.pages = 0
    },
    getJobHistoryByIdFail: (state, action) => {
      state.jobHistoryById.data = []
      state.jobHistoryById.pagination.total_records = 0
      state.jobHistoryById.pagination.pages = 0
    },
    getJobRunByIdFail: (state, action) => {
      state.jobRunById.data = []
      state.jobRunById.pagination.total_records = 0
      state.jobRunById.pagination.pages = 0
    },
    bulkUpdateJobStatusSuccess(state, action) {
      state.bulk_update = action.payload.bulk_update
    },
    updateJobSort(state, action){
      state.allJobs.sorting.sort_field = action.payload.sort_field
      state.allJobs.sorting.sort_order = action.payload.sort_order
    },

    updateJobHistorySort(state, action){
      state.jobHistoryById.sorting.sort_field = action.payload.sort_field
      state.jobHistoryById.sorting.sort_order = action.payload.sort_order
    },
    updateJobRunSort(state, action){
      state.jobRunById.sorting.sort_field = action.payload.sort_field
      state.jobRunById.sorting.sort_order = action.payload.sort_order
    },
    updateStudyData (state, action){
      state.study_data = action.payload.data.data
    },
    loadAllJobsAPI (state){
      state.allJobs.data_load = !state.allJobs.data_load
    },
    updateJobEceptionString(state, action){
      state.jobRunById.job_exception_string = action.payload.string
    },
    getJobEditChecks(state, action) {
      state.job_edit_checks = action.payload
    }
    },
})

export const {
  setDefaultNewJobReqData,
  setNewJobReqData,
  setJobs,
  setJobHistoryById,
  setJobRunById,
  bulkUpdateJobStatusSuccess,
  getJobsFail,
  getJobHistoryByIdFail,
  getJobRunByIdFail,
  updateJobHistorySort,
  updateJobRunSort,
  updateJobSort,
  updateStudyData,
  loadAllJobsAPI,
  updateJobEceptionString,
  getJobEditChecks,
  createSplitJobsFail,
} = jobsSlice.actions

export const newJob = (data, MSG) => (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  DataService.post(`/jobs/`, data)
    .then((res) => {
      dispatch(setDefaultNewJobReqData())
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: MSG.SUCCESS,
          color: 'success',
        })
      )
      dispatch(createSplitJobsFail(''))
      if(filterCount !== 0){
        dispatch(setClearSearch())
        dispatch(updateFilter({filter : {}}))
      }
    })
    .catch((err) => {
      console.error(err)
      if(data.job_run_type == 'split' && err?.toString()?.includes(sameJobLabel)) {
        dispatch(createSplitJobsFail('fail'))
      } else {
        dispatch(
          activateAlert({
            title: typeof err === 'string' ? err : MSG.FAILED,
            color: 'danger',
          })
        )
      }
    })
}

export const getAllJobs = (params) => async (dispatch) => {
  let instanceId = checkCondition(
    params?.instance_id,
    `instance_id=${params.instance_id}`
  )
  let page = checkCondition(params?.page, `&page=${params.page}`)
  let sizePerPage = checkCondition(
    params?.sizePerPage,
    `&per_page=${params.sizePerPage}`
  )
  let sort_by = checkCondition(
    params?.sortField,
    `&sort_by=${params.sortField}`
  )
  let sort_order = checkCondition(
    params?.sortOrder,
    `&order=${params.sortOrder}`
  )
  let searchParams = ''
  let rruleStringParam = ''
  let isactiveStringParam = 0

  if(params?.column_search){
    let rruleKeys = ['frequency', 'time']
    const multipleExist = () => rruleKeys.some(key => Object.keys(params.column_search).includes(key))
    if(multipleExist()){
      if(params.column_search["time"] && params.column_search["frequency"]){
        rruleStringParam = `FREQ=${params.column_search["frequency"]};BYHOUR=${moment(params.column_search["time"]).format("HH")};BYMINUTE=${moment(params.column_search["time"]).format("mm")};`
      }
      else if(params.column_search["frequency"]){
        rruleStringParam = `FREQ=${params.column_search["frequency"]}` 
      }
      else if(params.column_search["time"]){
        rruleStringParam = `;BYHOUR=${moment(params.column_search["time"]).format("HH")};BYMINUTE=${moment(params.column_search["time"]).format("mm")};`
      }
      
      rruleKeys.forEach(e => delete params.column_search[e])
      searchParams +=`&search_rrule_string=${rruleStringParam}`
    }

    Object.keys(params?.column_search).forEach(
      (searchKey) =>
        (searchParams += `&search_${searchKey}=${params.column_search[searchKey]}`)
    )
  }
  try {
    const { data } = await DataService.get(
      `/jobs?${instanceId}${page}${sizePerPage}${sort_by}${sort_order}${searchParams}`
    )
    dispatch(setJobs(data))
    const activeJobCount = data.data.filter(
      (data) => data.is_active === true
    ).length

    let isBulkUpdate = false;
    if(activeJobCount === data.data.length) {
      isBulkUpdate = true;
    }

    dispatch(
      bulkUpdateJobStatusSuccess({
        bulk_update: isBulkUpdate
      })
    )
  } catch (error) {
    console.error('Get jobs error:', error)
    dispatch(getJobsFail())
    
  }
}

export const getJobHistoryById = (params) => async (dispatch) => {
  let jobId = params?.id ?? ''
  let page = params?.page ? `?page=${params.page}` : ''
  let sizePerPage = params?.sizePerPage ? `&limit=${params.sizePerPage}` : ''
  let fromDate = params?.fromdate ? `&from_date=${params.fromdate}` : ''
  let toDate = params?.todate ? `&to_date=${params.todate}` : ''
  let sort_by = params?.sortField ? `&sort_by=${params.sortField}` : ''
  let sort_order = params?.sortOrder ? `&order=${params.sortOrder}` : ''
  let searchParams = ''

  if (params?.column_search) {
    Object.entries(params.column_search).map(([key, value]) => {
      searchParams += `&search_${key}=${value}`
    })
  }

  try {
    const { data } = await DataService.get(
      `/jobs/${jobId}/history${page}${sizePerPage}${fromDate}${toDate}${sort_by}${sort_order}${searchParams}`
    )
    dispatch(setJobHistoryById(data))
  } catch (error) {
    console.error('Get job history error:', error)
    dispatch(getJobHistoryByIdFail())
  }
}

export const getJobRunById = (params) => async (dispatch) => {
  let jobId = params?.id ?? ''
  let page = checkCondition(params?.page, `?page=${params.page}`)
  let sizePerPage = checkCondition(
    params?.sizePerPage,
    `&limit=${params.sizePerPage}`
  )
  let type = checkCondition(params?.type, `&rule_type=${params.type}`)
  let sort_by = checkCondition(
    params?.sortField,
    `&sort_by=${params.sortField}`
  )
  let sort_order = checkCondition(
    params?.sortOrder,
    `&order=${params.sortOrder}`
  )
  let searchParams = ''

  if (params?.column_search) {

    Object.entries(params.column_search).map(([key, value]) => {
      searchParams += `&search_${key}=${value}`
    })
  }
  try {
    const { data } = await DataService.get(
      `/jobs/job-run/${jobId}${page}${sizePerPage}${type}${searchParams}${sort_by}${sort_order}`
    )
    dispatch(setJobRunById(data))
  } catch (error) {
    console.error('Get job run error:', error)
    dispatch(getJobRunByIdFail())
    
  }
}

export const updateBulkJobStatus = (is_active) => async (dispatch) => {
  DataService.put(`/jobs/all/status/`, {
    is_active,
  })
    .then((res) => {
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: 'Updated Job status successfully',
          color: 'success',
        })
      )
      dispatch(bulkUpdateJobStatusSuccess({ bulk_update: is_active }))
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : 'Job Status Update Failed',
          color: 'danger',
        })
      )
    })
}

export const updateJobStatus = (jobId, study_id, is_active) => async (dispatch) => {
  DataService.put(`/jobs/${jobId}?study_id=${study_id}`, {
    is_active,
  })
    .then((res) => {
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: 'Updated Job status successfully',
          color: 'success',
        })
      )
      
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : 'Job Status Update Failed',
          color: 'danger',
        })
      )
    })
}

export const updateSelectedJobStatuses = (params, MSG) => async (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  DataService.put(`/jobs`, {
    data : params.data,
    is_active : params.is_active
  })
    .then((res) => {
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: MSG.SUCCESS,
          color: 'success',
        })
      )
      if( filterCount !== 0){
        dispatch(setClearSearch())
        dispatch(updateFilter({filter : {}}))
      }
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : MSG.FAILED,
          color: 'danger',
        })
      )
    })
}

export const fetchJobEditChecks = (params, MSG) => (dispatch) => {
  DataService.post(`/jobs/edit-checks/${params.job_id}`, {
    study_id : params.study_id, job_run_type: params.job_run_type
  })
    .then((res) => {
       dispatch(getJobEditChecks(res?.data))
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : MSG,
          color: 'danger',
        })
      )
    })
}

export const updateSplitJob = (params, MSG) => async (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  DataService.put(`/jobs/edit-checks/update/${params.job_id}`, {add: params.add, remove: params.remove, rrule_string: params.rrule_string})
    .then((res) => {
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: MSG.SUCCESS,
          color: 'success',
        })
      )
      if(filterCount !== 0){
        dispatch(setClearSearch())
        dispatch(updateFilter({filter : {}}))
      }
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : MSG.FAILED,
          color: 'danger',
        })
      )
    })
}

export const updateJobTime = (data, study_id, reqData) => async (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  DataService.put(`/jobs?study_id=${study_id}`,{data : data, rrule_string : reqData.rrule_string })
    .then((res) => {
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: 'Updated Schedule time successfully',
          color: 'success',
        })
      )
      if(filterCount !== 0){
        dispatch(setClearSearch())
        dispatch(updateFilter({filter : {}}))
      }
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : 'Schedule Time Update Failed',
          color: 'danger',
        })
      )
    })
}

export const deleteJob = (jobId, is_active) => async (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  DataService.delete(`/jobs/${jobId}`, {
    is_active,
  })
    .then((res) => {
      dispatch(loadAllJobsAPI())
      dispatch(
        activateAlert({
          title: 'Job deleted successfully',
          color: 'success',
        })
      )
      if(filterCount  !== 0){
        dispatch(setClearSearch())
        dispatch(updateFilter({filter : {}}))
      }
    })
    .catch((err) => {
      console.error(err)
      dispatch(
        activateAlert({
          title: typeof err === 'string' ? err : 'Job Delete Failed',
          color: 'danger',
        })
      )
    })
}

export const DownloadLogFile = (job_run_id, rule_type) => async (dispatch) => {
  try{
    const { data } = await DataService.get(`/jobs/log-file/${job_run_id}?rule_type=${rule_type}`)
    window.open(data?.data?.download_file_path, '_blank')
  }
  catch(error){
    console.error(error)
  }
} 

export const GetExceptionString = (job_run_id, rule_type) => async (dispatch) => {
  try{
    const { data } = await DataService.get(`/jobs/exception/${job_run_id}?rule_type=${rule_type}`)
    dispatch(updateJobEceptionString({string: data?.data?.exception}))
    
  }
  catch(error){
    console.error(error)
  }
} 

export const GetUnassignedStudies = (instance_id) => async (dispatch) => {
  try{
    const {data} = await DataService.get(`/jobs/studies/unassigned?page=1&per_page=200&instance_id=${instance_id}`)
    dispatch(updateStudyData({data}))
  }
  catch(error){
    console.error(error)
  }
}

export default jobsSlice.reducer
