import { useEffect, useState } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { DataService } from 'config.axios'
import './styles/apiHealthStatusStyles.scss'
import { Button } from 'sdq-ui'
import {
  SDQAPIHealthEntities,
  SDQAPIHealthStatusEndpoints,
} from 'variables/constants'

function SDQAPIHealthCheck() {
  const [apiStatus, setApiStatus] = useState([])

  useEffect(() => {
    getSDQApiHealthStatus()
  }, [])

  const getSDQApiHealthStatus = () => {
    const apiCall = async (endPoint) => {
      try {
        let { data } = await DataService.get(`/health/${endPoint}`)
        data.isStatusShown = false
        setApiStatus((apiStatus) => {
          return [...apiStatus, data]
        })
      } catch (error) {
        console.error('Get SDQ API health status error:', error)
      }
    }
    SDQAPIHealthStatusEndpoints.forEach((endPoint) => {
      apiCall(endPoint)
    })
  }

  const onStatusClick = (index) => {
    let healthStatusObj = [...apiStatus]
    let statusEntity = {}
    statusEntity = healthStatusObj[index]
    statusEntity.isStatusShown = !statusEntity.isStatusShown

    healthStatusObj[index] = statusEntity

    setApiStatus(healthStatusObj)
  }

  return (
    <div className="d-flex flex-column status-container">
      <Button
        primary
        small
        className="mb-2 refresh-button"
        onClick={() => getSDQApiHealthStatus()}
      >
        Refresh
      </Button>
      <ListGroup>
        {SDQAPIHealthEntities.map((entity, index) => {
          return (
            <ListGroupItem
              onClick={() => onStatusClick(index)}
              key={entity}
              className="api-health-status-entity-list"
            >
              <span>{entity}</span>
              <span
                className={`float-right health-status ${apiStatus[index]?.status}`}
              >
                {apiStatus[index]?.status}
              </span>
              {/* {apiStatus[index]?.status.toLowerCase() === 'success' && */}
              {apiStatus[index]?.isStatusShown && (
                <pre className="status-error mt-2 ">
                  {JSON.stringify(apiStatus[index])}
                </pre>
              )}
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </div>
  )
}

export default SDQAPIHealthCheck
