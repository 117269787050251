import  {useEffect} from 'react';
import queryString from 'query-string';
import {useDispatch} from 'react-redux';
import {loginByLSAC} from 'reduxStore/slices/user/UserSlice';

function LSACLogin (props){
    const dispatch = useDispatch()
    const { history, location } = props 
    const search = location.search
    const searchParams = queryString.parse(search)
    const auth_token = searchParams.auth_token 
    const exp = searchParams.exp 
    const tz = searchParams.tz 

    useEffect(() => {
        if(auth_token && auth_token!== ''){

            console.log(auth_token,exp,tz)
            
            dispatch(loginByLSAC(auth_token,exp,tz))
        }
    },[])

    return (
        <>  </>
    )
}

export default LSACLogin
