import { useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import role_based_page from 'RoleFirstpage'

// Containers
import DefaultLayout from './layouts/DefaultLayout'

// Login Page
import Login from './views/Login'
import Activate from './views/Activate'
import LSACLogin from 'views/LSACLogin'

// Home Page
import Home from './views/Home'
import { Container } from 'sdq-ui'
import { roles } from 'variables/constants'

import SDQAPIHealthCheckStatus from 'views/pages/api-health-check/pages'


function App({ history }) {
  const user = useSelector((state) => state.user)
  const { signIn } = user
  const { role, auth_info: { type } } = signIn
  const [roleBasePage, setRoleBasePage] = useState({}) 

  //Logout on WindowFocus Workaround
  if(signIn?.auth_info?.type === 'lsac'){
    import('./utils/windowFocus')
  }

  useEffect(() => {
    let role_base = {
      ...role_based_page,
      ...{ [roles.ACCOUNT_ADMIN]: type === 'lsac' ? role_based_page[roles.ACCOUNT_ADMIN].lsac : role_based_page[roles.ACCOUNT_ADMIN]?.jwt }
    }
    setRoleBasePage(role_base);
  }, [type])

  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Switch>
        <Route
            exact
            path='/status'
            name='status'
            render={(routerProps) => <SDQAPIHealthCheckStatus {...routerProps}/>}
          />
          <Route
            exact
            path="/activate"
            render={({ location }) => (
              <Container isNoPadding={true}>
                <Activate location={location} />
              </Container>
            )}
          />
          <Route
            exact
            path="/lsac"
            render={({location}) =>
            signIn.status === 1 && role ? (
              <Redirect to={roleBasePage[role]} />
            ) : (
            
                <Container isNoPadding={true}>
                 <LSACLogin location={location}/>
                </Container>)
              
            }
          />
          <Route
            exact
            path="/"
            name="Login"
            render={({ history }) =>
              signIn.status === 1 && role ? (
                <Redirect to={roleBasePage[role]} />
              ) : (
                <Container isNoPadding={true}>
                  <Login signIn={signIn} />
                </Container>
              )
            }
          />
          <Route
            path="/home"
            name="App"
            render={({ history }) =>
              signIn.status === 1 ? (
                <Redirect to={roleBasePage[role]} />
              ) : (
                <Home signIn={signIn} history={history} />
              )
            }
          />
          <Route
            path="/"
            name="App"
            render={(routerProps) => <DefaultLayout {...routerProps} />}
          />
        </Switch>
      </BrowserRouter>
    </ConnectedRouter>
  )
}

export default App
