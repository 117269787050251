import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap'
import { Button } from 'sdq-ui'


export const ModalComponent = props => {
  const {
    // modal props
    id = null,
    size,
    classes = '',
    backdrop = true,
    keyboard = true,
    isOpen = false,
    fade = true,
    autoFocus = false,
    centered = true,
    onClose,
    contentClassName,

    // header props
    isHeaderVisible = true,
    headerElement = "",
    headerClasses = "",
    headerCharCode,
    headerTag = "h5",

    //body props
    bodyClasses = "",
    bodyTag = "div",
    bodyElement,

    // footer props
    isFooterVisible = true,
    footerClasses = "",
    footerTag = "div",
    footerElement,

    // default footer props
    isDefaultFooter = true,
    btnClose = "Cancel",
    btnSubmit = "Save",
    closeBtnProps,
    submitBtnProps,
    btnSubmitDisabled = false,
    submitBtnClasses,
    onSubmit
  } = props;
  return (
    <Modal
      id={id}
      size={size}
      className={classes}
      isOpen={isOpen}
      backdrop={backdrop}
      toggle={() => onClose(() => !isOpen)}
      keyboard={keyboard}
      fade={fade}
      autoFocus={autoFocus}
      centered={centered}
      contentClassName={contentClassName}
    >
      {isHeaderVisible && (<ModalHeader
        charCode={headerCharCode}
        className={headerClasses}
        tag={headerTag}
        toggle={() => onClose(() => !isOpen)}
      >
        {headerElement}
      </ModalHeader>)}
      <ModalBody
        className={bodyClasses}
        tag={bodyTag}
      >
        {bodyElement}
      </ModalBody>
      {isFooterVisible && (
        <ModalFooter
          className={footerClasses}
          tag={footerTag}
        >
          {isDefaultFooter && (<>
            <Button
              secondary
              data-dismiss="modal"
              {...closeBtnProps}
              onClick={() => onClose(() => !isOpen)}
            >
              {closeBtnProps?.text || btnClose}
            </Button>
            <Button
              primary
              disabled={btnSubmitDisabled}
              className={submitBtnClasses}
              onClick={onSubmit}
              {...submitBtnProps}
            >
              {submitBtnProps?.text || btnSubmit}
            </Button>
          </>)}
          {footerElement}
        </ModalFooter>)}
    </Modal>
  )
}
