import { clearLocalStorage } from 'reduxStore/slices/user/UserSlice'
import { DataService } from 'config.axios'

export const logOut = async (obj) => {

  try
    {
      await DataService.get('/auth/logout')
    }
    catch (error) 
    {
      console.error('user logout error : ', error)
    }
    finally{
      await clearLocalStorage('phase1')
    }

  if (obj.login_type === 'lsac') {
    const base_url = 'https://' + window.location.hostname
    let url = `${obj.logout_url}?redirect_uri=${base_url}`
    window.location.replace(url)
  } else if (obj.fromSuperNav) {
    window.location.reload()
  }
}
