const roles = {
  DATA_MANAGER: 'DATA_MANAGER',
  DEVELOPER: 'DEVELOPER',
  MODEL_MANAGER: 'MODEL_MANAGER',
  TESTER: 'TESTER',
  LEAD_DEVELOPER: 'LEAD_DEVELOPER',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  SDQ_ADMIN: 'SDQ_ADMIN',
}

const text_length = 20
const text_length_70 = 70

const auth_info = {
  sch: 'SCH',
  jwt: 'jwt'
}

const supernavConfig = {
  topnavDisplayMode: 'all',
  displayMode: 'minimal_sidebar',
  sidebarDisplayMode: 'all',
}

const source_systems = { INFORM: 'inform', RAVE: 'rave' }

const dateFormat = 'DD-MMM-YYYY'
const dateTimeFormat = 'DD-MMM-YYYY HH:mm:ss'

const preconfig_domain_const = {
  CONFIG: 'CONFIG',
  VARIABLE: 'VARIABLE',
}

const datasetSourceConst = {
  edc: 'EDC',
  vendor: 'VENDOR',
}

const auditLogConst = {
  predictions: {
    entity: 'PREDICTION_ANALYZE',
    entity_display_name: 'Prediction ID',
  },
  StudyAssignment: {
    entity: 'STUDY_ASSIGNMENT',
    entity_display_name: 'Audit Log - Study Assignment',
  },
}

const routesConstants = {
    Dashboard: {
        name: 'Dashboard',
        path: '/dashboard'
    },
    DataIngestionDetails: {
        name: 'Data Ingestion Details',
        path: '/execution-details/data-ingestion'
    },
    DQExecutorDetails: {
        name: 'DQ Executor Details',
        path: '/execution-details/dq-executor'
    },
    Predictions: {
        name: 'Predictions',
        path: '/predictions'
    },
    PredictionAnalyze: {
        name: 'Prediction Analyze',
        path: '/predictions/analyze/:id'
    },
    PredictionAnalyzeLogs: {
        name: 'Prediction Analyze Logs',
        path: '/predictions/analyze/logs/:id'
    },
    PredictionHistory: {
        name: 'Prediction History',
        path: '/prediction-history',
    },
    New:{
        name: 'New',
        path: '/edit-checks/new',
    },
    PreconfMetadata:{
        name: 'Preconf metadata',
        path: '/edit-checks/preconf-metadata',
    },
    Create:{
        name: 'Create',
        path: '/edit-checks/create',
    },
    ForApproval:{
        name: 'ForApproval',
        path: '/edit-checks/for-approval',
    },
    UnAssigned:{
        name: 'UnAssigned',
        path: '/edit-checks/unassigned',
    },
    AssignedRules:{
        name: 'Assigned',
        path: '/edit-checks/assigned',
    },
    Active:{
        name: 'Active',
        path: '/edit-checks/active',
    },
    Inactive:{
        name: 'Inactive',
        path: '/edit-checks/inactive',
    },
    Edit:{
        name: 'Edit',
        path: '/edit-checks/edit',
    },
    CodeEditor:{
        name: 'CodeEditor',
        path: '/edit-checks/code',
    },
    TestHarness:{
        name: 'TestHarness',
        path: '/edit-checks/testharness',
    },
    EditUpVersion:{
        name: 'Edit',
        path: '/edit-checks/up-version',
    },
    NewFunc:{
        name: 'New',
        path: '/edit-checks/functions/new',
    },
    CreateFunc:{
        name: 'Create',
        path: '/edit-checks/functions/code',
    },
    ActiveFunc:{
        name: 'Active',
        path: '/edit-checks/functions/active',
    },
    InactiveFunc:{
        name: 'Inactive',
        path: '/edit-checks/functions/inactive',
    },
    FuncForApproval:{
        name: 'ForApproval',
        path: '/edit-checks/functions/for-approval',
    },
    Reports:{
        name: 'Reports',
        path: '/reports',
    },
    QueryLog:{
        name: 'Query Log',
        path: '/query-log',
    },
    UserList:{
        name: 'UserList',
        path: '/user-management/userList',
    },
    StudyView:{
        name: 'StudyView',
        path: '/study-registration',
    },
    MLSubcat:{
        name: 'MLSubcatList',
        path: '/study-registration/ml-configuration',
    },
    DQConfigList:{
        name: 'DQConfigPageList',
        path: '/study-registration/dq-configuration',
    },
    StudyUserList:{
        name: 'StudyUserList',
        path: '/study-assignment/user-permission',
    },
    DesignSystem:{
        name: 'DesignSystem',
        path: '/design-system',
    },
    ScheduledJobs:{
        name: 'ScheduledJobs',
        path: '/jobs',
    },
    ScheduledJobsHistory:{
        name: 'ScheduledJobsHistory',
        path: '/jobs/job-history',
    },
    ReviewJobs:{
        name: 'ReviewJobs',
        path: '/jobs/job-history/review',
    },
    JobRunHistory:{
        name: 'JobRunHistory',
        path: '/jobs/run-history',
    },
    AccessToken:{
        name: 'accessToken',
        path: '/auth/access-token',
    },
    AccessDenied:{
        name: 'accessDenied',
        path: '/access-denied',
    },
    ReleaseManagement:{
        name: 'releaseManagement',
        path: '/release',
    },
    ReleaseReview:{
        name: 'releaseReview',
        path: '/release/review',
    },
    StudyManagement:{
        name: 'StudyView',
        path: '/study-assignment',
    },
    QueryDetailReport:{
        name: 'QueryDetailReport',
        path: '/query-detail-report',
    },
    IRL:{
        name: 'IRL',
        path: '/irl',
    },
    LSACAuth:{
        name: 'LSACAuth',
        path: '/lsac',
    },
    Configuration:{
        name: 'Configuration',
        path: '/admin/configuration',
    },
    SqlConsole:{
        name: 'SqlConsole',
        path: '/admin/sql-console',
    },
    SDQApiStatus:{
        name: 'SDQAPIStatus',
        path: '/status',
    },
    Logout:{
        name: 'logout',
        path: '/logout',
    },

}

const SDQAPIHealthStatusEndpoints = [
    'be/app',
    'be/db/read',
    'be/db/write',
    'be/s3/read',
    'be/s3/read',
    'rule-service',
    'connector',
    'be/fetch-studies',
  ]
  
  const SDQAPIHealthEntities = [
    'Backend Health',
    'DB Read',
    'DB Write',
    'S3 file read',
    'S3 file write',
    'Rule Executor',
    'Connector',
    'HA Studies',
  ]
  

export {
  roles,
  auth_info,
  supernavConfig,
  source_systems,
  text_length,
  text_length_70,
  preconfig_domain_const,
  datasetSourceConst,
  auditLogConst,
  routesConstants,
  dateFormat,
  dateTimeFormat,
  SDQAPIHealthEntities,
  SDQAPIHealthStatusEndpoints
}
