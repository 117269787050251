import { Button } from 'reactstrap'
import styled, {css} from 'styled-components'

export const StyledButton = ({ 
  primary,
  secondary,
  danger,
  success,
  info,
  disabled,
  small,
  large,
  transparent,
  ['primary-outline']: primary_outline, 
  ['primary-default']: primary_default, 
  ['default-outline']: default_outline, 
  ['success-outline']: success_outline, 
  ['info-outline']: info_outline, 
  ['danger-outline']: danger_outline, 
  ...props 
}) => {

  
  const NewButton = styled(Button)`
  ${primary && css`
    color: #FFFFFF;
    background: #0052CC;
    border-color: #0052CC;
  `}
  ${secondary && css`
    color: #172B4D;
    background: #FFFFFF;
    border-color: #A5ADBA;
  `}
  ${danger && css`
    color: #FFFFFF;
    background: #DE350B;
    border-color: #DE350B;
  `}
  ${transparent && css`
    color: #172B4D;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  `}
  ${success && css`
    color: #FFFFFF;
    background: #00875A;
    border-color: #00875A;
  `}
  ${info && css`
    color: #FFFFFF;
    background: #00A3BF;
    border-color: #00A3BF;
  `}
  ${primary_outline && css`
    color: #0052CC;
    background-color: #FFFFFF;;
    border-color: #0052CC;
  `}
  ${primary_default && css`
    color: #212529;
    background-color: f7fafc;
    border-color: #f7fafc;
  `}
  ${default_outline && css`
    color: #172b4d;
    background-color: transparent;
    border-color: #172b4d;
  `}
  ${success_outline && css`
    color: #00875A;
    background-color: #FFFFFF;
    border-color: #00875A;
  `}
  ${info_outline && css`
    color: #00A3BF;
    background-color: #FFFFFF;
    border-color: #00A3BF;
  `}
  ${danger_outline && css`
    color: #DE350B;
    background-color: #FFFFFF;
    border-color: #DE350B;
  `}
  &:hover {
    ${primary && css`
        color: #FFFFFF;
        background: #0065FF;
        border-color: #0065FF;
    `}
    ${secondary && css`
        background: #F4F5F7;
        border-color: #A5ADBA;
    `}
    ${danger && css`
        color: #FFFFFF;
        background: #FF5630;
        border-color: #FF5630;
    `}
    ${success && css`
      color: #FFFFFF;
      background: #36B37E;
      border-color: #36B37E;
    `}
    ${info && css`
      color: #FFFFFF;
      background: #00B8D9;
      border-color: #00B8D9;
    `}
    ${primary_outline && css`
      color: #0052CC;;
      background: #E8F1FF;
      border-color: #0052CC;
  `}
    ${primary_default && css`
      color: #212529;
      background: #f7fafc;
      border-color: #f7fafc;
  `}
    ${default_outline && css`
      color: #FFFFFF;
      background: #172b4d;
      border-color: #172b4d;
  `}
  ${success_outline && css`
      color: #00875A;;
      background: #D3FFF0;
      border-color: #00875A;
  `}
  ${info_outline && css`
      color: #00A3BF;
      background: #E6FCFF;
      border-color: #00A3BF;
  `}
  ${danger_outline && css`
      color: #DE350B;
      background: #FEEEEA;
      border-color: #DE350B;
  `}
  ${transparent && css`
    color: #172B4D;
    background-color: #EBECF0;
    border-color: transparent;
    box-shadow: none;
  `}
  }
  &:not(:disabled):not(.disabled):active {
    ${primary && css`
        color: #FFFFFF;
        background: #0747A6;
        border-color: #0747A6;
    `}
    ${secondary && css`
        background: #DFE1E6;
        border-color: #A5ADBA;
    `}
    ${danger && css`
        color: #FFFFFF;
        background: #BF2600;
        border-color: #BF2600; 
    `}
    ${success && css`
      color: #FFFFFF;
      background: #006644;
      border-color: #006644;
    `}
    ${info && css`
      color: #FFFFFF;
      background: #008DA6;
      border-color: #008DA6;
    `}
    ${primary_outline && css`
        color: #FFFFFF;
        background: #0065FF;
        border-color: #0052CC;
    `}
    ${primary_default && css`
      color: #212529;
      background: #f7fafc;
      border-color: #f7fafc;
    `}
    ${default_outline && css`
        color: #FFFFFF;
        background: #172b4d;
        border-color: #172b4d;
    `}
    ${success_outline && css`
        color: #FFFFFF;
        background: #00875A;
        border-color: #00875A;
    `}
    ${info_outline && css`
        color: #FFFFFF;
        background: #00A3BF;
        border-color: #00A3BF;
    `}
    ${danger_outline && css`
        color: #FFFFFF;
        background: #DE350B;
        border-color: #DE350B;
    `}
    ${transparent && css`
      color: #172B4D;
      background-color: #DFE1E6;
      border-color: transparent;
      box-shadow: none;
    `}
  }

  &:disabled {
    ${disabled && css`
    cursor: not-allowed;
    color: #B3BAC5;
    background: #F4F5F7;
    border: 1px solid #C1C7D0;
    ` }
  }
  
  ${small && css`
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  `}
  ${large && css`
    padding: 0.875rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.4375rem;
  `}

`
  return (<NewButton { ...props} disabled={disabled}>{props.children}</NewButton>);
}

// export const StyledButton1 = styled(Button)`
//   ${props => props.primary && css`
//     color: #FFFFFF;
//     background: #0052CC;
//     border-color: #0052CC;
//   `}
//   ${props => props.secondary && css`
//     color: #253858;
//     background: #f7fafc;
//     border-color: #f7fafc;
//   `}
//   ${props => props.danger && css`
//     color: #FFFFFF;
//     background: #f5365c;
//     border-color: #f5365c;
//   `}
//   ${props => props.transparent && css`
//     color: #253858;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
//   `}
//   ${props => props["primary-outline"] && css`
//     color: #0052CC;
//     background-color: transparent;
//     border-color: #0052CC;
//   `}
//   ${props => props["default-outline"] && css`
//     color: #172b4d;
//     background-color: transparent;
//     border-color: #172b4d;
//   `}
//   ${props => props["success-outline"] && css`
//     color: #2dce89;
//     background-color: transparent;
//     border-color: #2dce89;
//   `}
//   ${props => props["info-outline"] && css`
//     color: #11cdef;
//     background-color: transparent;
//     border-color: #11cdef;
//   `}
//   ${props => props["danger-outline"] && css`
//     color: #f5365c;
//     background-color: transparent;
//     border-color: #f5365c;
//   `}
//   &:hover {
//     ${props => props.primary && css`
//         color: #FFFFFF;
//         background: #0065FF;
//         border-color: #0065FF;
//     `}
//     ${props => props.secondary && css`
//         background: #f7fafc;
//         border-color: #f7fafc;
//     `}
//     ${props => props.danger && css`
//         color: #FFFFFF;
//         background: #f5365c;
//         border-color: #f5365c;
//     `}
//     ${props => props["primary-outline"] && css`
//       color: #FFFFFF;
//       background: #0052CC;
//       border-color: #0052CC;
//   `}
//     ${props => props["default-outline"] && css`
//       color: #FFFFFF;
//       background: #172b4d;
//       border-color: #172b4d;
//   `}
//   ${props => props["success-outline"] && css`
//       color: #FFFFFF;
//       background: #2dce89;
//       border-color: #2dce89;
//   `}
//   ${props => props["info-outline"] && css`
//       color: #FFFFFF;
//       background: #11cdef;
//       border-color: #11cdef;
//   `}
//   ${props => props["danger-outline"] && css`
//       color: #FFFFFF;
//       background: #f5365c;
//       border-color: #f5365c;
//   `}
//   ${props => props.transparent && css`
//     color: #253858;
//     background-color: transparent;
//     border-color: transparent;
//     box-shadow: none;
//   `}
//   }
//   &:not(:disabled):not(.disabled):active {
//     ${props => props.primary && css`
//         color: #FFFFFF;
//         background: #0747A6;
//         border-color: #0747A6;
//     `}
//     ${props => props.danger && css`
//         color: #FFFFFF;
//         background: #f5365c;
//         border-color: #f5365c; 
//     `}
//     ${props => props["primary-outline"] && css`
//         color: #FFFFFF;
//         background: #0052CC;
//         border-color: #0052CC;
//     `}
//     ${props => props["default-outline"] && css`
//         color: #FFFFFF;
//         background: #172b4d;
//         border-color: #172b4d;
//     `}
//     ${props => props["success-outline"] && css`
//         color: #FFFFFF;
//         background: #2dce89;
//         border-color: #2dce89;
//     `}
//     ${props => props["info-outline"] && css`
//         color: #FFFFFF;
//         background: #11cdef;
//         border-color: #11cdef;
//     `}
//     ${props => props["danger-outline"] && css`
//         color: #FFFFFF;
//         background: #f5365c;
//         border-color: #f5365c;
//     `}
//     ${props => props.transparent && css`
//       color: #253858;
//       background-color: transparent;
//       border-color: transparent;
//       box-shadow: none;
//     `}
//   }
//   &:disabled {
//     ${props => props.primary && css`
//         color: #FFFFFF;
//         background: #0747A6;
//         border-color: #0747A6;
//     `}
//     ${props => props.danger && css`
//         color: #FFFFFF;
//         background: #f5365c;
//         border-color: #f5365c;
//     `}
//     ${props => props["primary-outline"] && css`
//         color: #0052CC;
//         background-color: transparent;
//         border-color: #0052CC;
//     `}
//     ${props => props["default-outline"] && css`
//         color: #172b4d;
//         background-color: transparent;
//         border-color: #172b4d;
//     `}
//     ${props => props["success-outline"] && css`
//         color: #2dce89;
//         background-color: transparent;
//         border-color: #2dce89;
//     `}
//     ${props => props["info-outline"] && css`
//         color: #11cdef;
//         background-color: transparent;
//         border-color: #11cdef;
//     `}
//     ${props => props["danger-outline"] && css`
//         color: #f5365c;
//         background-color: transparent;
//         border-color: #f5365c;
//     `}
//   }
//   ${props => props.disabled && css`
//     cursor: not-allowed;
//   `}
//   ${props => props.small && css`
//     font-size: 0.75rem;
//     padding: 0.25rem 0.5rem;
//     line-height: 1.5;
//     border-radius: 0.25rem;
//   `}
//   ${props => props.large && css`
//     padding: 0.875rem 1rem;
//     font-size: 0.875rem;
//     line-height: 1.5;
//     border-radius: 0.4375rem;
//   `}


// `