import { createSlice } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { DataService } from 'config.axios'
import { setItem, getItem } from 'utils/localStorageController'
import { getInstanceList , updateInstanceID} from '../instance'
import {getStudyList, setStudyID, setModalState} from '../study/index'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import { getAccountMetaData } from 'reduxStore/slices/accounts/index'
import { UIPermission } from 'utils/uiPermission'
import { global_permissions } from "variables/UserGlobalPermission"
import { roles } from 'variables/constants'
import { logOut } from 'utils/logoutProgress'

const initialState = {
  signIn: {
    userName: '',
    email: '',
    password: '',
    id: null,
    status: 0,
    role: '',
    errorMsg: '',
    load: false,
    CDHError: false,
    pageLoad: false,
    loginUrl: '',
    loadLoginPage: false,
    loginLoadError: {
      isOpen: false,
      error: {}
    },
    showDefaultLayout: false,
    auth_info: { type: '' },
    instance_permissions: {
      api_fetched: false,
      permissions: [],
    },
    global_permissions: global_permissions,
    study_permission: {},
    active_permissions: [],
    user_ui_permission: [],
    all_roles:[],
    activeUserRole:''
  },
  LSACdata: {
    logout_url: '',
    account_switch_url: '',
    app_info: {},
    token: '',
    data: {}
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginInputChange: (state, action) => {
      const { name, value } = action.payload
      state.signIn[name] = value
    },
    updateUserSession: (state, action) => {
      state.signIn.role = action.payload.role
      state.signIn.id = action.payload.id
      state.signIn.status = action.payload.status
    },
    userLoginRequest: (state, action) => {
      state.signIn.load = true
      state.signIn.pageLoad = true
    },
    userLoginSuccess: (state, action) => {
      state.signIn.load = false
      state.signIn.status = action.payload.status
    },
    userLoginFail: (state, action) => {
      state.signIn.errorMsg = action.payload.error_msg
      state.signIn.status = action.payload.status
      state.signIn.load = false
      state.signIn.pageLoad = false
    },
    userLogout: (state, action) => {
      state.signIn.errorMsg = action.payload.error_msg
    },
    userCDHError: (state, action) => {
      state.signIn.CDHError = action.payload
    },
    fetchPermissions: (state, action) => {
      state.signIn.id = action.payload.id
      state.signIn.userName = action.payload.name
      state.signIn.instance_permissions.permissions =
        action.payload.instance_permissions
      state.signIn.instance_permissions.api_fetched = true
      state.signIn.active_permissions = action.payload.active_permissions
      state.signIn.study_permission = action.payload.study_permissions
      state.signIn.role = action.payload.role
      state.signIn.email = action.payload.email
      state.signIn.auth_info = action.payload.auth_info
      state.signIn.all_roles = action.payload.all_roles
      state.signIn.activeUserRole = action.payload.role
    },
    fetchLSAC: (state, action) => {
      state.LSACdata.account_switch_url = action.payload.account_switch_url
      state.LSACdata.app_info = action.payload.app_info
      state.LSACdata.data = action.payload.data
      state.LSACdata.logout_url = action.payload.logout_url
      state.LSACdata.token = action.payload.token
    },
    pageLoadSuccess: (state, action) => {
      state.signIn.user_ui_permission = action.payload.ui_permission
      state.signIn.pageLoad = action.payload.pageLoad
      state.signIn.showDefaultLayout = action.payload.showDefaultLayout
    },
    pageLoadError: (state) => {
      state.signIn.pageLoad = false
    },
    updateLoginURL: (state, action) => {
      state.signIn.loginUrl = action.payload.data.auth_url
    },
    loadLoginPage: (state, action) => {
      state.signIn.loadLoginPage = true
    },
    loadErrorPage: (state, action) => {
      state.signIn.loginLoadError = {
        isOpen: true,
        error: action.payload
      }
    },
    loginAuthData: (state, action) => {
      state.signIn.loginAuthData = action.payload
    },
    updateUserActiveRole: (state, action) => {
      state.signIn.activeUserRole = action.payload
    },
  },
})

export const {
  updateUserSession,
  loginInputChange,
  userLoginRequest,
  userLoginSuccess,
  userLoginFail,
  userLogout,
  userCDHError,
  fetchPermissions,
  fetchLSAC,
  pageLoadSuccess,
  loadLoginPage,
  loadErrorPage,
  updateLoginURL,
  loginAuthData,
  updateUserActiveRole,
} = userSlice.actions

export const loginUser = (email, password, ERROR_MSG) => async (dispatch) => {
  try {
    dispatch(userLoginRequest())
    const { data } = await DataService.post('/auth/login/', {
      email,
      password,
    })
    const {exp,tz,_jwt_token} = data.data;
    setItem('_token', _jwt_token)
    setItem('loginAuthData', JSON.stringify({exp,tz}))
    dispatch(
      userLoginSuccess({
        status: 1,
      })
    )
    dispatch(loginAuthData({exp,tz}))
    dispatch(pageLoad())
  } catch (error) {
    console.error('User login error', error)
    let error_text =
      typeof error === 'string'
        ? error.startsWith('INTERNAL SERVER ERROR: ')
          ? error.replace('INTERNAL SERVER ERROR: ', '')
          : error
        : ERROR_MSG

    dispatch(
      activateAlert({
        title: error_text,
        color: 'danger',
      })
    )
    dispatch(userLoginFail({ error_msg: error, status: -1 }))
  }
}

export const loginByLSAC = (token,exp,tz) => async (dispatch) => {
  setItem('_token', token)
  setItem('loginAuthData', JSON.stringify({exp,tz}))
  dispatch(loginAuthData({exp,tz}))
  dispatch(
    userLoginSuccess({
      status: 1,
    })
  )
  dispatch(pageLoad())
}

export const pageLoad = (records_per_page, current_page) => async (dispatch) => {
  try {
    const response = await dispatch(fetchUserMeta())
    const { instance_permissions, study_permissions, role, auth_info } = response
    //get instance and study details from cache
    let instance_id = getItem('instance_id')
    let instance_name = ''
    let study_id = getItem('_study_id')
    let source_system = getItem('source_system')
    let study_name = ''
    let studyStatus = getItem('study_status')
    let instance_update = false
    const study_status = getItem('study_status')

    // if instance is undefined or user does not have access to instance in cache
    if (instance_id === undefined || !(instance_id in instance_permissions)) {
      const instances = await dispatch(getInstanceList({ role }))
      instance_id = instances?.data[0]?.id
      instance_name = instances?.data[0]?.name
      instance_update = true
    }

    if (!instance_name) {
      const instances = await dispatch(getInstanceList({ role }))
      instance_name = instances?.data.find(v => v.id == instance_id)?.name
    }

    dispatch(
      updateInstanceID({
        instanceId: instance_id ?? 0,
        instanceName: instance_name,
      })
    )
    // if study is undefined or user does not have access to study in cache or instance has been updated now
    if (role !== roles['MODEL_MANAGER'] && role !== roles['ACCOUNT_ADMIN'] && role !== roles['SDQ_ADMIN']) {
      const study = await dispatch(
        getStudyList({ instance_id: instance_id, sortField: 'is_favorite', sortOrder: 'desc', per_page: 10, page: 1 })
      )
      study_name = getItem('study_id') ? getItem('study_id') : (study?.data?.data.find(v => v.id.toString() === study_id)?.study_id || '')
      if (
        study_id === undefined ||
        !(study_id in study_permissions) ||
        instance_update
      ) {
        dispatch(setStudyID({ study_id: '', id: '', src_system: source_system }))
        dispatch(setModalState(true))
      } else {
        dispatch(setStudyID({ study_id: study_name, id: study_id, src_system: source_system, status: study_status}))
      }
    }
    //store the user ui permission for this particualar instance
    const permissions = UIPermission(role, instance_permissions, instance_id, auth_info.type)

    dispatch(
      pageLoadSuccess({
        ui_permission: permissions,
        pageLoad: false,
        showDefaultLayout: true,
      })
    )
  } catch (error) {
    console.error('error - Page Load ', error)
  }
}

export const fetchUserMeta = () => async (dispatch) => { 
  try {
    const { data: roleData } = await DataService.get('/auth/user/meta')
    
    const {
      instance_permissions,
      study_permissions,
      id,
      first_name,
      last_name,
      role,
      email,
      auth_info,
      all_roles
      
    } = roleData.data

    dispatch(
      updateUserSession({
        role,
        id,
        status: id !== null && id !== '' ? 1 : 0,
      })
    )
    await dispatch(
      getAccountMetaData({ id, first_name, last_name, role, email })
    )
    const LS_loginAuthData = getItem('loginAuthData') ? JSON.parse(getItem('loginAuthData')): null;
    if(LS_loginAuthData){
      dispatch(loginAuthData(LS_loginAuthData))
    }
    
    
    const permissions = {}
    instance_permissions.forEach(
      (permission) =>
        (permissions[permission.instance_id] = permission.access?.map(
          (access) => access.module_name
        ))
    )

    dispatch(
      fetchPermissions({
        instance_permissions: permissions,
        study_permissions,
        id,
        name: first_name + last_name,
        role,
        auth_info,
        all_roles
      })
    )

    if (auth_info.type === "lsac") {
      setItem('app_info_type', auth_info.type)
      const LSAC = await dispatch(fetchLSACmeta())
      await dispatch(
        fetchLSAC({
          logout_url: LSAC.logout_url,
          account_switch_url: LSAC.account_switch_url,
          app_info: LSAC.app_info,
          token: LSAC.token,
          data: LSAC.data,
        })
      )
    }
    
    return { instance_permissions: permissions, study_permissions, role: role, auth_info: auth_info }
  } catch (error) {
    console.error('User Permission Fetch Error', error)
    dispatch(logoutUser('User Permission Fetch Error', {}))
  }
}

export const fetchLSACmeta = () => async () => {
  try {
    const { data: LSACdata } = await DataService.get('auth/lsac/meta')
    const { logout_url, account_switch_url, app_info, token, data } = LSACdata.data
    setItem('LSAC_logout_url', logout_url)
    
    return {
      logout_url: logout_url,
      account_switch_url: account_switch_url,
      app_info: app_info,
      token: token,
      data: data,
    }
  } catch (error) {
    console.log('Fetch LSAC meta Error', error)
  }
}

export const loginByToken = (token) => async (dispatch) => {
  DataService.post(`/auth/icc-token/`, { token })
    .then((res) => {
      const {exp,tz,_jwt_token} = res.data.data;
      dispatch(
        activateAlert({
          title: 'Login successfully',
          color: 'success',
        })
      )
      setItem('_token', _jwt_token)
      setItem('loginAuthData', JSON.stringify({exp,tz}))
      dispatch(loginAuthData({exp,tz}))
      
      dispatch(push('/'))
      dispatch(
        userLoginSuccess({
          status: 1,
        })
      )
      dispatch(pageLoad())
    })
    .catch((err) => {
      dispatch(userCDHError(true))
    })
}

export const activateUser = (password, invite_key) => async (dispatch) => {
  try {
    const {data} = await DataService.post(`/auth/users/activate/?invite_key=${invite_key}`, {password})
    
    window.location.href = '/'
    dispatch(
      activateAlert({
        title: 'User Activated successfully',
        color: 'success',
      }),
      
    )
  }
  catch (error) {
    console.error('User Activate Failed', error)
    dispatch(
      activateAlert({
        title: 'User Activated Failed',
        color: 'danger',
      }),
      
    )
  }
}

export const clearLocalStorage = (type) => {
  const instance_id = getItem('instance_id')                             
  const _study_id = getItem('_study_id')
  const study_id = getItem('study_id')
  const study_status = getItem('study_status')
  const source_system = getItem('source_system')
  let LSAC_logout_url = null;
  let app_info_type = null;
  if(type == 'phase1'){
    LSAC_logout_url = getItem('LSAC_logout_url')                             
    app_info_type = getItem('app_info_type')
  }
  
                                                   
  localStorage.clear()                                      
  setItem('instance_id', instance_id.toString())                                       
  setItem('_study_id',_study_id)
  setItem('study_id', study_id)
  setItem('study_status', study_status)  
  setItem('source_system',source_system)  
  if(type == 'phase1'){
    setItem('LSAC_logout_url', LSAC_logout_url)                                       
    setItem('app_info_type',app_info_type) 
  }
}


export const logoutUser = (error_msg, auth_info_type, logout_url = null) => async (dispatch) => {                                                
  dispatch(userLogout(error_msg))  
  await logOut({fromSuperNav:false, login_type: auth_info_type, logout_url: logout_url})

  if (auth_info_type !== "lsac") {
    dispatch(push('/'))
    window.location.reload()
  }
}

export const getauthURL = () => async (dispatch) => {
  try {
    const { data } = await DataService.get('/auth/url')
    dispatch(updateLoginURL({data}))
    if (data?.data?.auth_url !== null){
      window.location.replace(data?.data?.auth_url)
      
    }
    else{
      dispatch(loadLoginPage())
    }
  } 
  catch (error) {
    dispatch(loadErrorPage(error))
    console.error('Get Auth URL error: ', error)
  }
}


export const refreshToken = () => async (dispatch) => {
  try {
    const { data } = await DataService.get('/auth/refresh-token/')
    const {exp,tz,_jwt_token} = data.data;
    setItem('_token', _jwt_token)
    setItem('loginAuthData', JSON.stringify({exp,tz}))
    dispatch(loginAuthData({exp,tz}))
    
  } 
  catch (error) {
    console.error('refresh Token Error', error)
  }
}


export const roleSwitch = (role) => async (dispatch) => {
  try {

    let reqData = {
        "role_name" : role
    }
    
    await DataService.put('/auth/role-switch/',reqData)
    
    window.location.reload()
    
  } 
  catch (error) {
    console.error('Role switch Error', error)
  }
}

export default userSlice.reducer
