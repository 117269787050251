import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import { getItem } from 'utils/localStorageController'
import { delay } from 'utils/delay'
import * as _ from 'lodash'

export const defaultColumns = [
  { key: 'visitref', name: 'Visit' },
  { key: 'visit_ix', name: 'Visit Repeat' },
  { key: 'formrefname', name: 'Form' },
  { key: 'form_ix', name: 'Form Repeat' },
  { key: 'itemset_ix', name: 'Item Group Ix' },
]

const study_id = getItem('_study_id')

const initialState = {
  sheetData: [],
  primaryColumn: [],
  secondaryColumns: [],
  row_ids: {},
  table_data: [],
  bool_checks: {
    check_if_edited: false,
    is_all_pass: false,
    is_running: false,
    result_count: 0,
    can_download_logs: false,
  },
  currentSheetIndex: -1,
  activeSheetIndex: 0,
  totalSheets: 0,
  paging: {
    page: 1,
  },
  sheet: {
    current_sheet: 1,
    total_sheet: 1,
  },
  activeSheet: {
    index: -1,
    data: null,
  },
  load: {
    get_api_data: false,
    save_api_data: false,
    run_test_harness: false,
    logs_download: false,
  },
  last_execution_id: null,
  editEnabled: false,
}

const testHarnessSlice = createSlice({
  name: 'TestHarness',
  initialState,
  reducers: {
    getTestHarnessDataRequest: (state, action) => {
      state.load.get_api_data = true
    },
    getTestHarnessDataSuccess: (state, action) => {
      state.sheetData = action.payload.sheetData
      state.primaryColumn = action.payload.primaryColumn
      state.secondaryColumns = action.payload.secondaryColumns
      state.column_data = action.payload.column_data
      if (action.payload.sheetData && action.payload.sheetData.length > 0) {
        state.activeSheet = {
          index: 0,
          data: action.payload.sheetData[0],
        }
      }
      state.currentSheetIndex = action.payload.currentSheetIndex
      state.totalSheets = action.payload.totalSheets
      state.table_data = action.payload.table_data
      state.paging = action.payload.paging
      state.sheet.total_sheet = action.payload.total_sheet
      state.row_ids = action.payload.row_ids
      state.check_if_edited = false
      state.bool_checks.is_all_pass = action.payload.is_all_pass
      state.bool_checks.is_running = action.payload.is_running
      state.bool_checks.result_count = action.payload.result_count
      state.bool_checks.check_if_edited = false
      state.bool_checks.can_download_logs = action.payload.can_download_logs
      state.last_execution_id = action.payload.last_execution_id
      state.load.get_api_data = false
    },
    getTestHarnessDataFail: (state, action) => {
      state.load.get_api_data = false
    },
    updateTestHarnessData: (state, action) => {
      state.api_data = JSON.parse(JSON.stringify(action.payload.table_data))
      state.bool_checks.check_if_edited = true
    },
    saveTestHarnessDataRequest: (state, action) => {
      state.load.save_api_data = true
    },
    saveTestHarnessDataSuccess: (state, action) => {
      state.load.save_api_data = false
      state.bool_checks.check_if_edited = false
    },
    saveTestHarnessDataFail: (state, action) => {
      state.load.save_api_data = false
    },
    getTestStatus: (state, action) => {
      state.api_data = action.payload.api_data
      state.bool_checks.is_all_pass = action.payload.is_all_pass
      state.bool_checks.is_running = action.payload.is_running
      state.bool_checks.result_count = action.payload.result_count
      state.bool_checks.can_download_logs = action.payload.can_download_logs
      state.last_execution_id = action.payload.last_execution_id
    },
    runTestHarnessRequested: (state, action) => {
      state.load.run_test_harness = true
    },
    runTestHarnessDataSucess: (state, action) => {
      state.load.run_test_harness = false
    },
    runTestHarnessDataFail: (state, action) => {
      state.load.run_test_harness = false
    },
    clearRuleTestHarness: (state, action) => {
      state.sheet_data = {}
      state.column_data = {}
      state.row_ids = {}
      state.table_data = []
      state.bool_checks = {
        check_if_edited: false,
        is_all_pass: false,
        is_running: false,
      }
      state.editEnabled = false
      state.paging = {}
      state.sheet = {
        current_sheet: 1,
        total_sheet: 1,
      }
    },
    updateCurrentSheet: (state, action) => {
      state.sheet.current_sheet = action.payload.current_sheet
    },
    addANewSheet: (state, action) => {
      let sheet = generateSheetData(state.primaryColumn, state.secondaryColumns)
      state.sheetData = [...state.sheetData, { ...sheet, sheet: state.totalSheets + 1 }];
      state.totalSheets += 1;
    },
    addRowToASheet: (state, action) => {
      let { sheetData, primaryColumn, secondaryColumns, currentSheetIndex } =
        state
      const { payload } = action
      const { sheetIndex, domainIndex, isPrimary } = payload
      if (isPrimary) {
        sheetData[currentSheetIndex].primary.data.push(generateDefaultRowData(primaryColumn));
      } else {
        let columnDefinition = secondaryColumns[domainIndex];
        sheetData[currentSheetIndex].secondary[domainIndex].data.push(generateDefaultRowData(columnDefinition));
      }
    },

    deleteRowToASheet: (state, action) => {
      let { sheetData, currentSheetIndex } = state
      const { payload } = action
      const { sheetIndex, domainIndex, isPrimary, rowIndex } = payload
      if (isPrimary) {
        sheetData[currentSheetIndex].primary.data.splice(rowIndex, 1)
      } else {
        sheetData[currentSheetIndex].secondary[domainIndex].data.splice(
          rowIndex,
          1
        )
      }
    },
    clearRunDataEdit: (state, action) => {
      state.api_data = action.payload.table_data
      state.bool_checks = action.payload.bool_checks
    },
    addDataToASheet: (state, action) => {
      let { sheetData } = state;
      let { isPrimary, domainIndex, sheetIndex, field, value, dataIndex } = action.payload;
      if (isPrimary) {
        sheetData[sheetIndex].primary.data[dataIndex][field] = value
      } else {
        let secondary = sheetData[sheetIndex].secondary[domainIndex]
        secondary.data[dataIndex][field] = value
        sheetData[sheetIndex].secondary[domainIndex] = secondary
      }
      state.sheetData = sheetData
    },
    downloadTestLogsRequest: (state, action) => {
      state.load.logs_download = true
    },
    downloadTestLogsSuccess: (state, action) => {
      state.load.logs_download = false
    },
    downloadTestLogsFail: (state, action) => {
      state.load.logs_download = false
    },
    updateEnable: (state, action) => {
      state.editEnabled = !state.editEnabled
    },
    updateSheetIndex: (state, action) => {
      let payload = action.payload
      state.currentSheetIndex = payload
      state.activeSheetIndex = payload;
    },
  },
})

export const {
  getTestHarnessDataRequest,
  getTestHarnessDataSuccess,
  getTestHarnessDataFail,
  updateTestHarnessData,
  saveTestHarnessDataRequest,
  saveTestHarnessDataSuccess,
  saveTestHarnessDataFail,
  updatesExpectedResult,
  getTestStatus,
  runTestHarnessDataFail,
  runTestHarnessRequested,
  runTestHarnessDataSucess,
  updateMoveToTesting,
  clearRuleTestHarness,
  updateCurrentSheet,
  addANewSheet,
  clearRunDataEdit,
  downloadTestLogsRequest,
  downloadTestLogsSuccess,
  downloadTestLogsFail,
  updateEnable,
  addRowToASheet,
  deleteRowToASheet,
  addDataToASheet,
  updateSheetIndex,
} = testHarnessSlice.actions

export const getTestHarnessData =
  (EC_id) => async (dispatch) => {
    try {
      dispatch(getTestHarnessDataRequest);
      let columnData = [];
      const sheetdataArray = [];
      const { data: columnDataResponse, status: columnStatus } = await DataService.get(
        `/edit-checks/test-harness/${EC_id}/dataset`
      )
      if (columnStatus == 200) {
        columnData = columnDataResponse.data;
      }

      let primaryColumn, secondaryColumns = [];
      columnData.forEach((domainColumn, index) => {
        const columnData = {
          ...domainColumn,
          columns: [...defaultColumns, ...domainColumn.columns.map(x => ({ key: x, name: x }))]
        }
        if (index === 0) {
          primaryColumn = columnData
        } else {
          secondaryColumns.push(columnData)
        }
      })
      let testHarnessData = []
      const { data: testHarnessDataResponse, status: testHarnessStatus } = await DataService.get(
        `/edit-checks/test-harness/${EC_id}/test-data?page=1&study_id=${study_id}`
      )
      if (testHarnessStatus == 200) {
        testHarnessData = testHarnessDataResponse.data;
      }

      if (Object.keys(testHarnessData).length !== 0) {
        for (const sheet in testHarnessData) {
          const perSheetData = testHarnessData[sheet];
          const parsedSheetData = {
            sheet: sheet,
            primary: null,
            secondary: []
          };

          const groupedData = _(perSheetData).groupBy('is_primary')
            .map((value, key) => ({
              is_primary:JSON.parse(key) || false,
              data: value.map(x => {return {...x, expected_result : x.expected_result === "" || null ? "QUERY" : x.expected_result}}),
            }))
            .value()
           
          
          parsedSheetData.primary = groupedData.find(x=> x.is_primary);
          let secondaryDomain = groupedData.find(x=> !x.is_primary);
          let secondaryDomainData = [];
          if (secondaryDomain) {
            secondaryColumns.forEach(x=>{
              let data = secondaryDomain.data.filter(y => y.domain === x.domain)
              secondaryDomainData.push({domain: x.domain, is_primary: false, data})
            })
          }
          parsedSheetData.secondary =secondaryDomainData;
          if (parsedSheetData.secondary.length === 0) {
            secondaryColumns.forEach((x) => {
              let secondaryDomainData = generateDefaultData(x);
              parsedSheetData.secondary.push(secondaryDomainData)
            })
          }
          sheetdataArray.push(parsedSheetData);
        }
      } else {
        let sheet = generateSheetData(primaryColumn, secondaryColumns)
        sheetdataArray.push(sheet);
      }
      dispatch(
        getTestHarnessDataSuccess({
          currentSheetIndex: 0,
          totalSheets: sheetdataArray.length,
          sheetData: sheetdataArray,
          primaryColumn,
          secondaryColumns,
          is_all_pass: testHarnessDataResponse.other_info.is_all_pass,
          is_running: testHarnessDataResponse.other_info.is_running,
          editEnabled: false,
          can_download_logs: testHarnessDataResponse.other_info.hasOwnProperty('last_execution_id'),
          last_execution_id: testHarnessDataResponse.other_info.last_execution_id,
        })
      )
      if (testHarnessDataResponse.other_info.is_running) {
        dispatch(activateAlert({ color: 'warning', title: "The unit testing is currently running." }))
        await delay(5000)
        dispatch(getTestHarnessData(EC_id))
      }
    } catch (error) {
      console.error('Get test harness data fail', error)
      dispatch(getTestHarnessDataFail())
    }
  }

const generateSheetData = (primaryColumn, secondaryColumns) => {
  //Primary
  let primaryDomainData = generateDefaultData(primaryColumn)
  let sheet = {
    sheet: "1",
    primary: primaryDomainData,
    secondary: []
  }
  //Secondary
  secondaryColumns.forEach((x) => {
    let secondaryDomainData = generateDefaultData(x);
    sheet.secondary.push(secondaryDomainData)
  })
  return sheet;
}

export const saveTestHarnessData =
  (EC_id, data, MSG) => async (dispatch) => {
    try {
      let requestPayload = prepareRequestPayload(data);
      await DataService.post(
        '/edit-checks/test-harness/' + EC_id + '/test-data/',
        { data: requestPayload }
      )
      dispatch(saveTestHarnessDataSuccess())
      dispatch(
        activateAlert({
          color: 'success',
          title: MSG.SUCCESS
        })
      )
      dispatch(getTestHarnessData(EC_id))
    } catch (error) {
      console.error('Save test harness data fail', error)
      dispatch(
        activateAlert({ color: 'danger', title: MSG.FAILED })
      )
      dispatch(saveTestHarnessDataFail())
    }
  }

export const getTestRunStatus =
  (EC_id) => async (dispatch) => {
    try {
      let is_running = true
      do {
        await delay(5000)
        const { data } = await DataService.get(
          `/edit-checks/test-harness/${EC_id}/test-data?page=1&include_test_data=True&study_id=${study_id}`,
          {},
          null,
          true
        )
        is_running = data.other_info.is_running
      } while (is_running)
      dispatch(runTestHarnessDataSucess())
    } catch (error) {
      console.error('Run harness data fail', error)
      dispatch(runTestHarnessDataFail())
    }
  }

const prepareRequestPayload = (data) => {
  let requestPayload = {};
  data.reduce((pV, cV) => {
    let index = 0;
    let data = {};
    //Primary
    cV.primary.data.forEach((x) => {
      data[index] = { ...x, is_primary: true };
      index++
    });
    //Secondary
    cV.secondary.forEach(x => x.data.forEach(y => {
      data[index] = { ...y, is_primary: false };
      index++
    }))
    requestPayload[cV.sheet] = data;
    return pV
  }, requestPayload)
  return requestPayload;
}

export const runTestHarnessData = (EC_id, data) => async (dispatch) => {
  try {
    let requestPayload = prepareRequestPayload(data)
    await DataService.put(
      `/edit-checks/test-harness/${EC_id}/run-test?study_id=${study_id}`,
      { data: requestPayload }
    )
    await dispatch(getTestHarnessData(EC_id))
    dispatch(runtestHarnessResults(data))
  } catch (error) {
    console.error('Run harness data fail', error)
    dispatch(runTestHarnessDataFail())
  }
}

export const runtestHarnessResults = (data) => async (dispatch) => {
  let allPassed = true;
  
  for (const entry of data) {
    const primaryData = entry.primary.data;
    const allData = [...primaryData]

    for (const item of allData) {
      if (item.actual_result !== item.expected_result) {
        allPassed = false;
        break;
      }
    }
  }

  if (allPassed) {
    dispatch(activateAlert({ color: "success", title: "All test cases passed!" }));
  } else {
    dispatch(activateAlert({ color: "warning", title: "All test cases did not pass!" }));
  }
}

export const updateActiveSheet = (activeSheet) => {
  return (dispatch) => {
    dispatch(
      updateCurrentSheet({
        current_sheet: activeSheet,
      })
    )
  }
}

export const clearRunDataToEdit = (EC_id, table_data) => {
  return (dispatch) => {
    let table_data_state = JSON.parse(JSON.stringify(table_data))
    let primary = Object.keys(table_data_state)[0]
    let primaryObj = table_data_state[primary]
    for (let key in primaryObj) {
      primaryObj[key].actual_result = ''
    }

    let bool_checks = {
      is_all_pass: false,
      is_running: false,
      check_if_edited: true,
    }
    dispatch(
      clearRunDataEdit({
        table_data: table_data_state,
        bool_checks: bool_checks,
      })
    )
  }
}

export const clearRuleTestGrid = () => {
  return (dispatch) => {
    dispatch(
      clearRuleTestHarness({
        state: initialState,
      })
    )
  }
}

export const downloadRuleTestHarnessLogs =
  (last_execution_id) => async (dispatch) => {
    try {
      dispatch(downloadTestLogsRequest())
      const { data } = await DataService.get(
        `/jobs/log-file/${last_execution_id}?rule_type=EDIT_CHECK`
      )
      dispatch(downloadTestLogsSuccess())
      window.open(data.data.download_file_path, '_blank')
    } catch (error) {
      console.error('Download Run test harness logs data fail', error)
      dispatch(downloadTestLogsFail())
      dispatch(
        activateAlert({ color: 'danger', title: 'Download RTH Logs failed!' })
      )
    }
  }

export const addARow = (params) => async (dispatch) => {
  dispatch(addRowToASheet(params))
}

export const deleteARowAt = (params) => async (dispatch) => {
  dispatch(deleteRowToASheet(params))
}

export const addRowData = (params) => async (dispatch) => {
  dispatch(addDataToASheet(params))
}

let generateDefaultData = (columnsDefinitions) => {
  let data = generateDefaultRowData(columnsDefinitions);
  let domainData = {
    domain: columnsDefinitions.domain,
    data: [data],
  }
  return domainData
}

let generateDefaultRowData = (columnsDefinitions) => {
  let data = {
    domain: columnsDefinitions.domain
  }
  data = columnsDefinitions.columns.reduce((pV, cV) => {
    pV[cV.key] = ""
    pV.expected_result = "QUERY"
    return pV
  }, data)
  return data;
}

export default testHarnessSlice.reducer
