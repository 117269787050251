import styled, { css } from 'styled-components'
import { UncontrolledTooltip } from 'reactstrap'

// Default values
const backgroundColor = '#182a4e'
const borderColor = '#182a4e'

// pointer-events to fix the tooltip flickering when mouseover
export const TooltipRight = (props) => {
  const UncontrolledTooltipRight = styled(UncontrolledTooltip)`
    opacity: 1 !important;
    pointer-events: none;

    .tooltip-inner {
      font-family: "Muli", sans-serif !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      letter-spacing: 1px;
      line-height: 16px;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 232px !important;
      display: inline-block;
      position: relative;
      text-align: left;
      color: #FFFFFF;
      background-color: ${backgroundColor};
      border-width: 2px;
      border-style: solid;
      border-color: ${borderColor};
      border-radius: 4px;
      padding: 8px;
      box-shadow: 0px 7px 14px rgba(50,50,93,0.1), 0px 3px 6px rgba(0,0,0,0.08);
      box-sizing: border-box;
      color-scheme: light;
    }

    .arrow {
      display: none !important;
    }

    ${props.isArrow &&
    css`
      .tooltip-inner::before,
      .tooltip-inner::after {
        content: '';
        display: block;
        position: absolute;
        right: 100%;
        width: 0;
        height: 0;
        border-style: solid;
      }
    `}

    .tooltip-inner::after {
      top: calc(50% - 7px);
      border-color: transparent ${backgroundColor} transparent transparent;
      border-width: 7px;
    }

    .tooltip-inner::before {
      top: calc(50% - 10px);
      border-color: transparent ${borderColor} transparent transparent;
      border-width: 10px;
    }
  `
  return <UncontrolledTooltipRight {...props} />
}
