import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  requestCount: 0
}

const loaderSlice = createSlice({
  name: 'LoaderSlice',
  initialState: initialState,
  reducers: {
    incrementRequest(state) {
      state.requestCount += 1
    },
    decrementRequest(state) {
      state.requestCount -= 1
    }
  },
})

export const { incrementRequest, decrementRequest } = loaderSlice.actions
export default loaderSlice.reducer
