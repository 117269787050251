import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import ShowSpinner from 'components/Spinner/spinner'
import { Button, Card, CardHeader, CardBody, Row, Col } from 'sdq-ui'
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Alert
} from 'reactstrap'

import { loginInputChange, loginUser,  getauthURL} from 'reduxStore/slices/user/UserSlice'
import { useTranslation } from 'react-i18next'

function Login(props) {
  const { t } = useTranslation()
  const CONSTANTS = t('LOGIN')
  const { signIn } = props
  const { email, password } = signIn
  const session = useSelector((state) => state.user)
  const [visible, setVisible] = useState(true)
  const onDismiss = () => setVisible(false)

  const dispatch = useDispatch()
  const { signIn: { id } } = useSelector((state) => state.user)
  const { loginLoadError, loadLoginPage } = signIn
  useEffect(() => {
    if(id === null || id === '')
      dispatch(getauthURL())
  },[])

  const handleInputChange = (e) => {
    dispatch(loginInputChange({ name: e.target.name, value: e.target.value }))
  }

  const login = (e) => {
    e.preventDefault()
    dispatch(loginUser(email, password, CONSTANTS.LOGIN_FAIL_MSG))
  }

  if (session.signIn.load || session.signIn.pageLoad) {
    return <ShowSpinner />
  }

  return (
   
    <>
    {
      loadLoginPage ? (
      <Container className="mt-5">
      <Row className="justify-content-center">
        <Col lg="6" md="7" sm="12">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>{CONSTANTS.TITLE}</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="email"
                      name="email"
                      placeholder={CONSTANTS.EMAIL}
                      type="email"
                      value={email}
                      onChange={(e) => {
                        handleInputChange(e)
                      }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="password"
                      name="password"
                      placeholder={CONSTANTS.PASSWORD}
                      type="password"
                      value={password}
                      onChange={(e) => {
                        handleInputChange(e)
                      }}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button
                    primary
                    type="submit"
                    onClick={login}
                  >
                    {CONSTANTS.SIGN_IN}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
      ) : ''
    }
    {loginLoadError.isOpen && !loadLoginPage &&
      <Container className="mt-5">
        <Card className='w-50 mx-auto'>
          <CardHeader>
            <h3 className='mb-0'>
              <i class="fas fa-exclamation-triangle mr-2 text-danger"></i>
              {t('LOGIN.ERROR_PAGE.HEADER', { errorCode: loginLoadError.error?.code })}
            </h3>
          </CardHeader>
          <CardBody>
            <p className='font-weight-500 mb-1'>{loginLoadError.error.message}</p>
            <p className='font-weight-500'>{CONSTANTS.ERROR_PAGE.MESSAGE}</p>
            <Button primary small onClick={() => window.location.reload()}>Reload page</Button>
          </CardBody>
        </Card>   
      </Container>
    }
    </>
  )
}

export default Login
