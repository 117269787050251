import Loadable from 'react-loadable'
import { permissions } from 'variables/InstancePermissions'
import ShowSpinner from 'components/Spinner/spinner'
import { routesConstants } from 'variables/constants'

function Loading() {
  return <div> <ShowSpinner /> </div>
}

const Dashboard = Loadable({
  loader: () => import('./views/pages/dashboards/Dashboard'),
  loading: Loading,
})

const DataIngestionDetails = Loadable({
  loader: () => import('./views/pages/execution-details/data-ingestion/index'),
  loading: Loading,
})

const DQExecutorDetails =  Loadable({
  loader: () => import('./views/pages/execution-details/dq-executor/index'),
  loading: Loading,
})

const PredictionsListView = Loadable({
  loader: () => import('./views/pages/predictions/ListView'),
  loading: Loading,
})

const PredictionAnaylze = Loadable({
  loader: () => import('./views/pages/predictions/analyze/PredictionAnalyze'),
  loading: Loading,
})

const PredictionLogs = Loadable({
  loader: () => import('./views/pages/predictions/analyze/PredictionLogs'),
  loading: Loading,
})

const PredictionHistory = Loadable({
  loader: () => import('./views/pages/prediction-history/index'),
  loading: Loading,
})

const PreconfMetadata = Loadable({
  loader: () => import('./views/pages/edit-checks/data-manager/PreconfMetadata'),
  loading: Loading,
})

const New = Loadable({
  loader: () => import('./views/pages/edit-checks/data-manager/New'),
  loading: Loading,
})

const ForApproval = Loadable({
  loader: () => import('./views/pages/edit-checks/data-manager/ForApproval'),
  loading: Loading,
})

const Active = Loadable({
  loader: () => import('./views/pages/edit-checks/data-manager/Active'),
  loading: Loading,
})

const Inactive = Loadable({
  loader: () => import('./views/pages/edit-checks/data-manager/Inactive'),
  loading: Loading,
})

const Create = Loadable({
  loader: () =>
    import(
      './views/pages/edit-checks/data-manager/new-rule/CreateRuleDataForm'
    ),
  loading: Loading,
})

const UnassignedRules = Loadable({
  loader: () => import('./views/pages/edit-checks/developer/Unassigned'),
  loading: Loading,
})

const AssignedRules = Loadable({
  loader: () => import('./views/pages/edit-checks/developer/Assigned'),
  loading: Loading,
})

const UserList = Loadable({
  loader: () => import('./views/pages/user-management/Index'),
  loading: Loading,
})

const CodeEditor = Loadable({
  loader: () => import('./views/pages/edit-checks/developer/CodeEditor'),
  loading: Loading,
})

const TestHarness = Loadable({
  loader: () => import('./views/pages/edit-checks/developer/TestHarness'),
  loading: Loading,
})

const NewFunc = Loadable({
  loader: () => import('./views/pages/functions/developer/New'),
  loading: Loading,
})

const CreateFunc = Loadable({
  loader: () => import('./views/pages/functions/developer/CreateNewFunction'),
  loading: Loading,
})

const ActiveFunc = Loadable({
  loader: () => import('./views/pages/functions/developer/Active'),
  loading: Loading,
})

const InactiveFunc = Loadable({
  loader: () => import('./views/pages/functions/developer/Inactive'),
  loading: Loading,
})

const FuncForApproval = Loadable({
  loader: () => import('./views/pages/functions/lead-developer/ForApproval'),
  loading: Loading,
})

const StudyView = Loadable({
  loader: () => import('./views/pages/study-registration/StudyList'),
  loading: Loading,
})

const DesignSystem = Loadable({
  loader: () => import('./sdq-ui/DesignSystem'),
  loading: Loading,
})

const ScheduledJobs = Loadable({
  loader: () => import('./views/pages/jobs/Scheduled'),
  loading: Loading,
})

const JobRunHistory = Loadable({
  loader: () => import('./views/pages/jobs/RunHistory'),
  loading: Loading,
})

const ScheduledJobsHistory = Loadable({
  loader: () => import('./views/pages/jobs/JobHistory'),
  loading: Loading,
})

const ReviewJobs = Loadable({
  loader: () => import('./views/pages/jobs/ReviewJob'),
  loading: Loading,
})

const AccessToken = Loadable({
  loader: () => import('./views/pages/access-token/TokenList'),
  loading: Loading,
})

const MLSubcat = Loadable({
  loader: () => import('./views/pages/study-registration/MLSubcatList'),
  loading: Loading,
})

const QueryLog = Loadable({
  loader: () => import('./views/pages/query-log/QueryLog'),
  loading: Loading,
})

const DQConfigList = Loadable({
  loader: () => import('./views/pages/study-registration/DQConfigPage'),
  loading: Loading,
})

const StudyUserList = Loadable({
  loader: () => import('./views/pages/study-management/UserList'),
  loading: Loading,
})
const AccessDenied = Loadable({
  loader: () => import('./views/AcessDenied'),
  loading: Loading,
})

const Reports = Loadable({
  loader: () => import('./views/pages/reports/Reports'),
  loading: Loading,
})

const ReleaseManagement = Loadable({
  loader: () => import('./views/pages/release-management/ReleaseList'),
  loading: Loading,
})

const ReleaseReview = Loadable({
  loader: () => import('./views/pages/release-management/ReleaseReview'),
  loading: Loading,
})

const StudyManagement = Loadable({
  loader: () => import('./views/pages/study-management/StudyList'),
  loading: Loading,
})
const QueryDetailReport = Loadable({
  loader: () => import('./views/pages/query-detail-report/index'),
  loading: Loading,
})

const IRL = Loadable({
  loader: () => import('./views/pages/irl/index'),
  loading: Loading,
})

const LSACLogin = Loadable({
  loader: () => import('./views/LSACLogin'),
  loading: Loading,
})

const Configuration = Loadable({
  loader: () => import('./views/pages/admin/Configuration'),
  loading: Loading,
})

const SqlConsole = Loadable({
  loader: () => import('./views/pages/admin/SqlConsole'),
  loading: Loading,
})

const SDQAPIStatus = Loadable({
  loader: () => import('./views/pages/api-health-check/pages/index'),
  loading: Loading,
})

const Logout = Loadable({
  loader: () => import('./views/Logout'),
  loading: Loading,
})

const routes = [
  {
    path: routesConstants.Dashboard.path,
    exact: true,
    name: routesConstants.Dashboard.name,
    component: Dashboard,
    permissions: [permissions.DASHBOARD_READ, permissions.DASBOARD_WRITE],
  },
  {
    path: routesConstants.DataIngestionDetails.path,
    exact: true,
    name: routesConstants.DataIngestionDetails.name,
    component: DataIngestionDetails,
    permissions: [permissions.ACCESS_TOKEN_WRITE],
  },
  {
    path: routesConstants.DQExecutorDetails.path,
    exact: true,
    name: routesConstants.DQExecutorDetails.name,
    component: DQExecutorDetails,
    permissions: [permissions.ACCESS_TOKEN_WRITE],
  },
  {
    path: routesConstants.Predictions.path,
    exact: true,
    name: routesConstants.Predictions.name,
    component: PredictionsListView,
    permissions: [permissions.TODO_LIST],
  },
  {
    path: routesConstants.PredictionAnalyze.path,
    exact: true,
    name: routesConstants.PredictionAnalyze.name,
    component: PredictionAnaylze,
    permissions: [permissions.TODO_LIST],
  },
  {
    path: routesConstants.PredictionAnalyzeLogs.path,
    exact: true,
    name: routesConstants.PredictionAnalyzeLogs.name,
    component: PredictionLogs,
    permissions: [permissions.TODO_LIST],
  },
  {
    path: routesConstants.PredictionHistory.path,
    exact: true,
    name: routesConstants.PredictionHistory.name,
    component: PredictionHistory,
    permissions: [permissions.PREDICTION_HISTORY],
  },
  {
    path: routesConstants.New.path,
    exact: true,
    name: routesConstants.New.name,
    component: New,
    permissions: [permissions.NEW_RULE_WRITE],
  },
  {
    path: routesConstants.PreconfMetadata.path,
    exact: true,
    name: routesConstants.PreconfMetadata.name,
    component: PreconfMetadata,
    permissions: [permissions.PRECONF_WRITE],
  },
  {
    path: routesConstants.Create.path,
    exact: true,
    name: routesConstants.Create.name,
    component: Create,
    permissions: [permissions.NEW_RULE_WRITE],
  },
  {
    path: routesConstants.ForApproval.path,
    exact: true,
    name: routesConstants.ForApproval.name,
    component: ForApproval,
    permissions: [permissions.RULE_FOR_APPROVAL_WRITE],
  },
  {
    path: routesConstants.UnAssigned.path,
    exact: true,
    name: routesConstants.UnAssigned.path,
    component: UnassignedRules,
    permissions: [
      permissions.RULE_FOR_DEVELOPMENT_WRITE,
      permissions.RULE_FOR_TESTING_WRITE,
    ],
  },
  {
    path: routesConstants.AssignedRules.path,
    exact: true,
    name: routesConstants.AssignedRules.name,
    component: AssignedRules,
    permissions: [
      permissions.DEV_RULE_ASSIGNED_TO_ME_WRITE,
      permissions.TEST_RULE_ASSIGNED_TO_ME_WRITE,
    ],
  },
  {
    path: routesConstants.Active.path,
    exact: true,
    name: routesConstants.Active.name,
    component: Active,
    permissions: [permissions.ACTIVE_RULE_WRITE, permissions.ACTIVE_RULE_READ],
  },
  {
    path: routesConstants.Inactive.path,
    exact: true,
    name: routesConstants.Inactive.name,
    component: Inactive,
    permissions: [
      permissions.INACTIVE_RULE_WRITE,
      permissions.INACTIVE_RULE_READ,
    ],
  },
  {
    path: routesConstants.Edit.path,
    exact: true,
    name: routesConstants.Edit.name,
    component: Create,
    permissions: [permissions.NEW_RULE_WRITE],
  },
  {
    path: routesConstants.CodeEditor.path,
    exact: true,
    name: routesConstants.CodeEditor.name,
    component: CodeEditor,
    permissions: true,
  },
  {
    path: routesConstants.TestHarness.path,
    exact: true,
    name: routesConstants.TestHarness.name,
    component: TestHarness,
    permissions: [permissions.DEV_RULE_ASSIGNED_TO_ME_WRITE],
  },
  {
    path: routesConstants.EditUpVersion.path,
    exact: true,
    name: routesConstants.EditUpVersion.name,
    component: Create,
    permissions: [permissions.NEW_RULE_WRITE],
  },
  {
    path: routesConstants.NewFunc.path,
    exact: true,
    name: routesConstants.NewFunc.name,
    component: NewFunc,
    permissions: [permissions.NEW_FUNCTION_WRITE],
  },
  {
    path: routesConstants.CreateFunc.path,
    exact: true,
    name: routesConstants.CreateFunc.name,
    component: CreateFunc,
    permissions: true,
  },
  {
    path: routesConstants.ActiveFunc.path,
    exact: true,
    name: routesConstants.ActiveFunc.name,
    component: ActiveFunc,
    permissions: [permissions.ACTIVE_FUNCTION_READ],
  },
  {
    path: routesConstants.InactiveFunc.path,
    exact: true,
    name: routesConstants.InactiveFunc.name,
    component: InactiveFunc,
    permissions: [permissions.INACTIVE_FUNCTION_READ],
  },
  {
    path: routesConstants.FuncForApproval.path,
    exact: true,
    name: routesConstants.FuncForApproval.name,
    component: FuncForApproval,
    permissions: [permissions.FUNCTION_FOR_APPROVAL_WRITE],
  },
  {
    path: routesConstants.Reports.path,
    exact: true,
    name: routesConstants.Reports.name,
    component: Reports,
    permissions: [permissions.REPORTS],
  },

  {
    path: routesConstants.QueryLog.path,
    exact: true,
    name: routesConstants.QueryLog.name,
    component: QueryLog,
    permissions: [permissions.QUERY_LOG],
  },

  {
    path: routesConstants.UserList.path,
    exact: true,
    name: routesConstants.UserList.name,
    component: UserList,
    permissions: [permissions.USER_MANAGEMENT_WRITE],
  },
  {
    path: routesConstants.StudyView.path,
    exact: true,
    name: routesConstants.StudyView.name,
    component: StudyView,
    permissions: [permissions.STUDY_REGISTRATION],
  },
  {
    path: routesConstants.MLSubcat.path,
    exact: true,
    name: routesConstants.MLSubcat.name,
    component: MLSubcat,
    permissions: [
      permissions.STUDY_REGISTRATION,
      permissions.STUDY_REGISTRATION_WRITE,
      permissions.STUDY_REGISTRATION_READ,
    ],
  },
  {
    path: routesConstants.DQConfigList.path,
    exact: true,
    name: routesConstants.DQConfigList.name,
    component: DQConfigList,
    permissions: [
      permissions.STUDY_REGISTRATION,
      permissions.STUDY_REGISTRATION_WRITE,
      permissions.STUDY_REGISTRATION_READ,
    ],
  },
  {
    path: routesConstants.StudyUserList.path,
    exact: true,
    name: routesConstants.StudyUserList.name,
    component: StudyUserList,
    permissions: [permissions.STUDY_MANAGEMENT_WRITE],
  },
  {
    path: routesConstants.DesignSystem.path,
    exact: true,
    name: routesConstants.DesignSystem.name,
    component: DesignSystem,
    permissions: true,
  },

  {
    path: routesConstants.ScheduledJobs.path,
    exact: true,
    name: routesConstants.ScheduledJobs.name,
    component: ScheduledJobs,
    permissions: [permissions.JOB_MANAGEMENT],
  },
  {
    path: routesConstants.ScheduledJobsHistory.path,
    exact: true,
    name: routesConstants.ScheduledJobsHistory.name,
    component: ScheduledJobsHistory,
    permissions: [
      permissions.JOB_MANAGEMENT_WRITE,
      permissions.JOB_MANAGEMENT_READ,
      permissions.JOB_MANAGEMENT,
    ],
  },
  {
    path: routesConstants.ReviewJobs.path,
    exact: true,
    name: routesConstants.ReviewJobs.name,
    component: ReviewJobs,
    permissions: [
      permissions.JOB_MANAGEMENT,
      permissions.JOB_MANAGEMENT_WRITE,
      permissions.JOB_MANAGEMENT_READ,
    ],
  },
  {
    path: routesConstants.JobRunHistory.path,
    exact: true,
    name: routesConstants.JobRunHistory.name,
    component: JobRunHistory,
    permissions: [
      permissions.JOB_MANAGEMENT_WRITE,
      permissions.JOB_MANAGEMENT_READ,
    ],
  },

  {
    path: routesConstants.AccessToken.path,
    exact: true,
    name: routesConstants.AccessToken.name,
    component: AccessToken,
    permissions: [permissions.ACCESS_TOKEN_WRITE],
  },
  {
    path: routesConstants.AccessDenied.path,
    exact: true,
    name: routesConstants.AccessDenied.name,
    component: AccessDenied,
    permissions: true,
  },
  {
    path: routesConstants.ReleaseManagement.path,
    exact: true,
    name: routesConstants.ReleaseManagement.name,
    component: ReleaseManagement,
    permissions: ['release_management'],
  },
  {
    path: routesConstants.ReleaseReview.path,
    exact: true,
    name: routesConstants.ReleaseReview.name,
    component: ReleaseReview,
    permissions: [
      permissions.RELEASE_MANAGEMENT,
      permissions.RELEASE_MANAGEMENT_WRITE,
      permissions.RELEASE_MANAGEMENT_READ,
    ],
  },
  {
    path: routesConstants.StudyManagement.path,
    exact: true,
    name: routesConstants.StudyManagement.name,
    component: StudyManagement,
    permissions: [permissions.STUDY_MANAGEMENT_WRITE],
  },
  {
    path: routesConstants.Reports.path,
    exact: true,
    name: routesConstants.Reports.name,
    component: Reports,
    permissions: [permissions.REPORTS],
  },
  {
    path: routesConstants.QueryDetailReport.path,
    exact: true,
    name: routesConstants.QueryDetailReport.name,
    component: QueryDetailReport,
    permissions: [permissions.QDR],
  },
  {
    path: routesConstants.IRL.path,
    exact: true,
    name: routesConstants.IRL.name,
    component: IRL,
    permissions: [permissions.IRL_READ, permissions.IRL_WRITE],
  },
  {
    path: routesConstants.LSACAuth.path,
    exact: true,
    name: routesConstants.LSACAuth.name,
    component: LSACLogin,
    permissions: true,
  },
  {
    path: routesConstants.Configuration.path,
    exact: true,
    name: routesConstants.Configuration.name,
    component:  Configuration,
    permissions: [permissions.ACCESS_TOKEN_WRITE],
  },
  {
    path: routesConstants.SqlConsole.path,
    exact: true,
    name: routesConstants.SqlConsole.name,
    component:  SqlConsole,
    permissions: [permissions.ACCESS_TOKEN_WRITE, permissions.SQL_CONSOLE_WRITE],
  },
  {
    path: routesConstants.Logout.path,
    exact: true,
    name: routesConstants.Logout.name,
    component: Logout,
    permissions: true,
  },
]

export default routes


