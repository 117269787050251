import Styled from 'styled-components'
import FeatherIcon from 'feather-icons-react'
import { TooltipRight } from 'sdq-ui/Tooltip/right';

const Tooltip = Styled.span`
    position: relative;
    display: inline-block;
    &:hover {
        cursor: default;
        .tooltiptext {
            display: block;
        }
    }
`
const Tooltiptext = Styled.span`
    font-family: "Muli", sans-serif !important;
    display: none;
    width: 232px !important;
    max-height: auto !important;
    overflow-x: hidden;
    overflow-y: hidden;
    color: #FFFFFF;
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    text-align: left;
    line-height: 1rem;
    background-color: #172B4D;
    border: 1px solid #172B4D;
    border-radius: 4px;
    padding: 8px;
    white-space: pre-line;
    position: absolute;
    z-index: 99999 !important;
    box-shadow: 0px 7px 14px rgba(50, 50, 93, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.08);
    &.top{
        bottom: 100%;
    }
`

export const InfoIcon = ({
  desc,
  leftClass = '',
  isTooltipComponent = false,
  target = '',
}) => {
  return (
    <Tooltip>
      <FeatherIcon
        icon="info"
        className="helper-icon info-icon ml-2"
        id={`helper-icon-${target}`}
      />
      {isTooltipComponent ? (
        <TooltipRight placement="right" target={`helper-icon-${target}`}>
          {desc}
        </TooltipRight>
      ) : (
        <Tooltiptext className={`tooltiptext ${leftClass}`}>{desc}</Tooltiptext>
      )}
    </Tooltip>
  )
}
