import { roles } from 'variables/constants'

const role_based_page = {
  [roles.DATA_MANAGER]: '/dashboard',
  [roles.DEVELOPER]: '/dashboard',
  [roles.TESTER]: '/dashboard',
  [roles.LEAD_DEVELOPER]: '/dashboard',
  [roles.ACCOUNT_ADMIN]: {
    lsac: '/auth/access-token',
    jwt: '/user-management/UserList'
  },
  [roles.MODEL_MANAGER]: '/study-registration',
  [roles.SDQ_ADMIN]: '/admin/sql-console',
}

export default role_based_page
