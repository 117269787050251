import { createSlice } from '@reduxjs/toolkit'

const initialiState = {
  isAlertActive: false,
  content: '',
  type: '',
  title: '',
  alertContent: '',
  autoDismiss : false
}

const alertSlice = createSlice({
  name: 'AlertSlice',
  initialState: initialiState,
  reducers: {
    activateAlert(state, action) {
      const { content, color = 'success', title = '', autoDismiss = false } = action.payload
      state.isAlertActive = true
      state.content = content
      state.type = color
      state.title = title
      state.autoDismiss = autoDismiss
    },
    deactivateAlert(state) {
      state.isAlertActive = false
      state.type = ''
      state.title = ''
      state.content = ''
      state.autoDismiss = false
    },
  },
})

export const { activateAlert, deactivateAlert } = alertSlice.actions
export default alertSlice.reducer
