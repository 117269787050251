import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from '../alert/AlertSlice'
import { getItem } from 'utils/localStorageController'
import store from 'store'
import { setClearSearch, updateFilter } from '../column-level-search'

const study_id = getItem('_study_id')

const initialState = {
  functionByStatus: {
    load: false,
    data: [],
    count: [],
    searchText: '',
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting: {
      sort_field: '',
      sort_order: '',
    },
  },
  functionData: {
    name: '',
    description: '',
  },
  load: {
    updateWorkflow: false,
    getFunctionById: false,
    updateFunctionPropertiesLoad: false,
  },
  inheritResult: {
    load: false,
    data: []
  }
}

const FunctionSlice = createSlice({
  name: 'functions',
  initialState,
  reducers: {
    getFunctionByStatusRequest(state, action) {
      state.functionByStatus.load = action.payload
    },
    getFunctionByStatusSuccess(state, action) {
      state.functionByStatus.load = false
      state.functionByStatus.data = action.payload.data
      state.functionByStatus.pagination.current_page =
        action.payload.paging['page_number']
      state.functionByStatus.pagination.total_records =
        action.payload.paging['total_count']
      state.functionByStatus.pagination.records_per_page =
        action.payload.records_per_page
      state.functionByStatus.pagination.pages = Math.ceil(
        action.payload.paging['total_count'] / action.payload.paging['per_page']
      )
    },
    getFunctionByStatusFail(state, action) {
      state.functionByStatus.load = false
      state.functionByStatus.data = []
    },
    updateSearchText(state, action) {
      state.functionByStatus.searchText = action.payload
    },
    updateSortField(state, action) {
      state.functionByStatus.sorting.sort_field = action.payload.sortField
      state.functionByStatus.sorting.sort_order = action.payload.sortOrder
    },
    clearFuncData(state) {
      state.functionData = { name: '', description: '' }
    },
    getFunctionCountSuccess(state, action) {
      state.functionByStatus.count = action.payload.data
    },
    getFunctionCountFail(state) {
      state.functionByStatus.count = []
    },
    updateworkflow(state) {
      state.load.updateWorkflow = !state.load.updateWorkflow
    },
    updateFunctionProperties(state) {
      state.load.updateFunctionPropertiesLoad =
        !state.load.updateFunctionPropertiesLoad
    },
    updateFuncData(state, action) {
      state.functionData[action.payload.key] = action.payload.value
    },
    getFuncByIDRequest(state) {
      state.load.getFunctionById = true
    },
    getFuncByIDSuccess(state, action) {
      state.load.getFunctionById = false
      state.functionData = { ...state.functionData, ...action.payload.data }
    },
    getFuncByIDFail(state) {
      state.load.getFunctionById = false
    },
    updateFunctionData(state, action) {
      state.functionByStatus.data = action.payload?.data
    },
    inheritWithCopyAndEditRequest(state, action) {
      state.inheritResult.load = action.payload
    },
    inheritWithCopyAndEditSuccess(state, action) {
      state.inheritResult.load = false
      state.inheritResult.data = action.payload.data
    },
    inheritWithCopyAndEditFail(state, action) {
      state.inheritResult.load = false
      state.inheritResult.data = []
    },
  },
})

export const {
  getFunctionByStatusRequest,
  getFunctionByStatusSuccess,
  getFunctionByStatusFail,
  clearFuncData,
  updateSearchText,
  updateSortField,
  getFunctionCountSuccess,
  getFunctionCountFail,
  updateworkflow,
  updateFunctionProperties,
  updateFuncData,
  getFuncByIDRequest,
  getFuncByIDSuccess,
  getFuncByIDFail,
  updateFunctionData,
  inheritWithCopyAndEditRequest,
  inheritWithCopyAndEditSuccess,
  inheritWithCopyAndEditFail,
} = FunctionSlice.actions

export default FunctionSlice.reducer

export const getFunctionByStatus = (params) => async (dispatch) => {
  try {
    dispatch(getFunctionByStatusRequest(true))
    const { data } = await DataService.post(
      `/edit-checks/functions/all?study_id=${params?.api_body?.study_id ? params.api_body?.study_id : study_id}`,
      params.api_body
    )
    dispatch(
      getFunctionByStatusSuccess({
        data: data.data,
        paging: data.paging,
      })
    )
  } catch (error) {
    console.error('Get Functions by status error', error)
    dispatch(getFunctionByStatusFail())
  }
}

export const updateBulkFunctionWorkflow = (params) => async (dispatch) => {
  const columnLevelSearch = store.getState().columnLevelSearch
  const filterCount = Object.values(columnLevelSearch.filter).length
  try {
    let api_params = { ids : params.ids,status: params.status, comments: params.comment }
    let action = params?.action ? '/assign' : ''
    const { data } = await DataService.put(
      `/edit-checks/functions/workflow${action}?study_id=${study_id}`,
      api_params
    )
    dispatch(updateworkflow())
    dispatch(activateAlert({ color: 'success', title: params.content || data.message }))
    if(filterCount !== 0){
      dispatch(setClearSearch())
      dispatch(updateFilter({filter : {}}))
    }
    if (params?.path) {
      params.history.push(params.path)
    }
  } catch (error) {
    console.error('Update Function workflow error:', error)
    dispatch(activateAlert({ color: 'danger', title: error.message }))
  }
}

export const getFunctionCount = (studyId) => async (dispatch) => {
  try {
    const { data } = await DataService.get(
      `/edit-checks/functions/workflow/count?study_id=${studyId ? studyId : study_id}`
    )
    dispatch(
      getFunctionCountSuccess({
        data: data.data,
      })
    )
  } catch (error) {
    console.error('Get Functions Count error', error)
    dispatch(getFunctionCountFail())
  }
}

export const deleteFunction = (id, content) => async (dispatch) => {
  const columnLevelSearch = store.getState().columnLevelSearch
  const filterCount = Object.values(columnLevelSearch.filter).length
  try {
    const { data } = await DataService.delete(
      `/edit-checks/functions/${id}/delete?study_id=${study_id}`
    )

    dispatch(
      activateAlert({
        color: 'success',
        title: content,
      })
    )
    dispatch(updateworkflow())
    if(filterCount !== 0){
      dispatch(setClearSearch())
      dispatch(updateFilter({filter : {}}))
    }
  } catch (error) {
    console.error('Delete Function Error:', error)
    dispatch(activateAlert({ color: 'danger', title: error.message }))
  }
}

export const saveCodeScript = (params) => async (dispatch) => {
  const codeData = new FormData()
  codeData.append('source_code', params.code)

  try {
    const { data } = await DataService.post(
      `/edit-checks/functions/${params.id}/save?study_id=${study_id}`,
      codeData,
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    )
    if (params.action === 'save') {
        dispatch(activateAlert({ color: 'success', title: params.content || data.message}))
      if (params?.history) {
        params.history.goBack()
      }
    } else if (params.action === 'approve') {
      dispatch(
        updateBulkFunctionWorkflow({
          ids: [params.id],
          comment: '',
          status: 'PENDING_APPROVAL',
          content: params.content,
          path: '/edit-checks/functions/new?activetab=2',
          history: params.history,
        })
      )
    }
  } catch (error) {
    console.error('Save Function Code script error: ', error)
    dispatch(activateAlert({ color: 'danger', title: error.message }))
  }
}

export const createFunctionData = (params, history, MSG) => async (dispatch) => {
  const columnLevelSearch = store.getState().columnLevelSearch
  const filterCount = Object.values(columnLevelSearch.filter).length
  try {
    const { data } = await DataService.post(
      `/edit-checks/functions?study_id=${study_id}`,
      params
    )
    if(filterCount !== 0){
      dispatch(setClearSearch())
      dispatch(updateFilter({filter : {}}))
    }
    history.push(`/edit-checks/functions/code`, {
      id: data.data.id,
      isEdit: true,
      study_id: study_id,
    })
    dispatch(activateAlert({ color: 'success', title: MSG.success }))
  } catch (error) {
    console.error('Create New Function Fail:', error)
    dispatch(activateAlert({ color: 'danger', title: MSG.fail }))
  }
}

export const getFuncByID = (id) => async (dispatch) => {
  try {
    dispatch(getFuncByIDRequest())
    const { data } = await DataService.get(
      `/edit-checks/functions/${id}?study_id=${study_id}`
    )
    dispatch(
      getFuncByIDSuccess({
        data: data.data,
      })
    )
  } catch (error) {
    console.error('Get Function by id error:', error)
    dispatch(getFuncByIDFail())
  }
}

export const updateFunctionDataForm = (params, MSG) => async (dispatch) => {
  const columnLevelSearch = store.getState().columnLevelSearch
  const filterCount = Object.values(columnLevelSearch.filter).length
  try {
    await DataService.put(
      `/edit-checks/functions/${params.id}?study_id=${study_id}`,
      params.api_body
    )
    if(filterCount !== 0){
      dispatch(setClearSearch())
      dispatch(updateFilter({filter : {}}))
    }
    dispatch(updateFunctionProperties())
    dispatch(
      activateAlert({
        color: 'success',
        title: MSG,
      })
    )
  } catch (error) {
    console.error('Updating Function Properties error:', error)
    dispatch(activateAlert({ color: 'danger', title: error.message }))
  }
}

export const inheritWithCopyAndEdit = (params) => async (dispatch) => {
  try {
    dispatch(inheritWithCopyAndEditRequest(true))
    const { data } = await DataService.post(
      `edit-checks/functions/inherit`,
      params.api_body
    )
    dispatch(
      inheritWithCopyAndEditSuccess({
        data: data
      })
    )
    return data
  } catch (error) {
    console.error('Get Functions by status error', error)
    dispatch(inheritWithCopyAndEditFail())
    dispatch(activateAlert({ color: 'danger', title: error.message }))
  }
}
