import { DatePicker, TimePicker } from 'antd';
import FeatherIcon from 'feather-icons-react'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.css';
import './index.scss'
import moment from 'moment';

export default function CustomDateSearch(props) {
  const { onFilter, isTime, showTime } = props

  const [dateValue, setDateValue] = useState(null)
  const clearSearch = useSelector((state) => state.columnLevelSearch.clearSearch)
  const calenderIcon = (
      <FeatherIcon icon={'calendar'} className="suffix-icon" />
    )
  const clockIcon = (
      <FeatherIcon icon={'clock'} className="suffix-icon" />
    )
    
    useEffect(()=> {
        setDateValue(null)
    },[clearSearch])
    
    const filter = (value) => {
      value ?
       onFilter(value): onFilter(null)
    }

    const handlePickerChange = (value) => {
      setDateValue(value)
      if(!isTime && !showTime && moment(value).isValid()){
          value = moment(value).format("YYYY-MM-DD")
      }
      filter(value)
    }
    
    return isTime ? (
      <TimePicker
        value={dateValue}
        placeholder=""
        suffixIcon = {clockIcon}
        format = "HH:mm"
        onChange={(value) => { handlePickerChange(value) }}
        style={{ borderRadius: '4px', height: '26px' }}
      />
    ) : (
      <DatePicker
        showTime={showTime ?? false}
        value={dateValue}
        placeholder=""
        suffixIcon={calenderIcon}
        format = {showTime ? "DD-MMM-YYYY HH:mm:ss" : "DD-MMM-YYYY" }
        onChange={(value) => { handlePickerChange(value) }}
        style={{ borderRadius: '4px', height: '26px' }}
      />
    ) 
  }
