import { createAsyncThunk } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { getItem } from 'utils/localStorageController'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'

const study_id = getItem('_study_id')

const generateIRLListColumnDefinition = (data) =>  {
  let columnDefs = []
  columnDefs = Object.keys(data._source)
    .map((eachColumnField) => {
      if (typeof data._source[eachColumnField] !== 'object')
        return { field: eachColumnField, sortable: true ,filter: 'agTextColumnFilter', floatingFilter: true}
    })
    .filter((col) => col !== undefined)
  columnDefs = [
    ...columnDefs,
    {
      headerName: 'action',
      sortable: false,
      pinned: 'right',
      lockPinned: true,
      checkboxSelection: true,
      width: '110px',
    },
    {
      headerName: '',
      pinned: 'right',
      lockPinned: true,
      width: '120px',
      cellRenderer: 'PopUpCellRenderer'
    },
    
  ]
   return columnDefs
}

export const getIRLListingsData = createAsyncThunk(
  'IRL/getIRLListingsData',
  async (params, thunkAPI) => {
    try {
      const IRL = thunkAPI.getState().irl
      const { listingsData, listing } = IRL
      const { currentListing, customListing, quick_filters } = listing

      let colDefs = []
      let rowsThisPage = []
      let scrollId = ''
      let column_filter = {}
      let quick_filter = []
      let common_filter = {}

      scrollId = listingsData.scrollId

      if (Object.keys(params?.filter ?? {}).length !== 0) {
        Object.keys(params?.filter).forEach((filter) => {
          column_filter[filter] = params.filter[filter].filter
        })
      }

      if (params?.quick_filter) {
        quick_filter = [...params.quick_filter]
        scrollId = ''
      }

      if (params?.common_filter) {
        Object.keys(params.common_filter).forEach((eachFilter) => {
          if (Array.isArray(params.common_filter[eachFilter])) {
            console.log('commonfil1', params.common_filter[eachFilter])
            params.common_filter[eachFilter].forEach((filter) => {
              if (common_filter[eachFilter]) {
                common_filter[eachFilter] = [...common_filter[eachFilter],filter.value]
              }
              common_filter[eachFilter] = [filter.value]
            })
          }
        })
        scrollId = ''
      }

      let api_body = {
        per_page: 10,
        ...(scrollId && {
          scrollId,
        }),
        listing_id: currentListing?.id ?? 0,
        // filter_condition: ['AE', 'AETERM', '=', 'YES'],
        quick_filter: [...quick_filter],
        common_filters: {...common_filter},
        column_search: {
          ...column_filter,
        },
        ...(params?.sort_by && {
          sort: {
            [params.sort_by]: `${params?.sort_order}`,
          },
        }),
      }

      const { data } = await DataService.post(
        `/irl/data/?study_id=${study_id}`,
        api_body
      )

      colDefs = generateIRLListColumnDefinition(data.data?.hits?.hits[0])
      if (params?.firstCall) {
        return { colDefs }
      }
      rowsThisPage = data.data?.hits?.hits?.map((row) => row._source)

      if (params?.cb) {
        params.cb(rowsThisPage, data.data?.hits?.total?.value ?? 0)
      }

      return { scroll_id: data.data?._scroll_id ?? '', column_filter }
    } catch (error) {
      console.error('Get Listings data error:', error)
      if (error.includes('Listing Query Not Available')) {
        thunkAPI.dispatch(
          activateAlert({
            title: 'Listing Query Not Available',
            color: 'danger',
          })
        )
        return { colDefs: [], rowsThisPage: [] }
      }
    }
  }
)


export const saveCustomListing = createAsyncThunk(
  'IRL/saveCustomListing',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.post(
        `/irl/listings?study_id=${study_id}`,
        params.api_body
      )
      if (params?.cb) {
        params.cb()
      }
      thunkAPI.dispatch(
        activateAlert({
          title: data.message,
          color: 'success',
        })
      )
      return {listing_id: data.data.id}
    } catch (error) {
      console.error('Save custom Listings error:', error)
    }
  }
)

export const getFavouriteListings = createAsyncThunk(
  'IRL/getFavouriteListings',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.get(
        `/irl/listings/favourites?study_id=${study_id}`
      )
      let apiData = data.data
      apiData = apiData.map(listing => {
        return {
          ...listing,
          is_favourite: true
        }
      })
      return { data: apiData }
    } catch (error) {
      console.error('Get favourite Listings error:', error)
    }
  }
)

export const deleteFavouriteListings = createAsyncThunk(
  'IRL/deleteFavouriteListings',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.delete(
        `/irl/listings/${params.listing_id}?study_id=${study_id}`
      )
      thunkAPI.dispatch(
        activateAlert({
          title: data.message,
          color: 'success',
        })
      )
      thunkAPI.dispatch(getFavouriteListings())
    } catch (error) {
      console.error('Delete favourite Listings error:', error)
    }
  }
)

export const createFavouriteListing = createAsyncThunk(
  'IRL/createfavlisting',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.post(
        `/irl/listings/favourites?study_id=${study_id}`,
        {
          listing_id: params?.listing_id,
          is_favourite: params?.is_favourite
        }
      )
      thunkAPI.dispatch(
        activateAlert({
          title: data.message,
          color: 'success',
        })
      )
      return { is_favourite: params?.is_favourite, listing_id: params.listing_id }
    } catch (error) {
      console.error('Create favourite Listings error:', error)
    }
  }
)

export const getIsFavoriteListings = createAsyncThunk(
  'IRL/getIsFavoriteListings',
  async (params, thunkAPI) => {
    try {
      const {data} = await DataService.get(
        `/irl/listings/favourites/status/${params.listing_id}?study_id=${study_id}`
      )
      return {is_favourite: data.data.is_favourite, listing_id: params.listing_id}
    }catch (error){
      console.error('Get isFavorite Listings error: ', error)
    }
  }
)