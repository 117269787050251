import { createSlice } from '@reduxjs/toolkit'

const initalState = {
  filter : {},
  clearSearch : false
}

const columnSearchSlice = createSlice({
  name : 'columnSearchSlice',
  initialState : initalState,
  reducers : {
    updateFilter(state, action){
      state.filter = action.payload.filter
    },
    setClearSearch(state){
      state.clearSearch = !state.clearSearch
    },
  }
})

export const { setClearSearch, updateFilter } = columnSearchSlice.actions
export default columnSearchSlice.reducer