import {useEffect, useRef, useState} from 'react'
// react library for routing
import { useLocation, Route, Switch, Redirect } from 'react-router-dom'
import Sidebar from 'components/Sidebar/Sidebar.js'
import { useSelector, useDispatch } from 'react-redux'
import getNav from '__nav'
import routes from 'routes'
import { getItem } from 'utils/localStorageController'
import AppHeader from './AppHeader'
import { AppBody } from '../style'
import { pageLoad, refreshToken } from '../reduxStore/slices/user/UserSlice'
import { Container } from 'sdq-ui'
import Supernav from './AppHeader/Supernav'
import { auth_info, source_systems, roles } from 'variables/constants'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

let hidden, visibilityChange;

if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

function DefaultLayout(props) {
  const { t } = useTranslation()
  const nav = getNav(t('NAV'))
  const [sidenavOpen, setSidenavOpen] = useState(true)
  const user = useSelector((state) => state.user)
  const { signIn } = user
  const { user_ui_permission } = signIn
  let isAuth = signIn.status === 1 ? true : false
  const source_system = useSelector((state) => state.account.source_system)
  const { src_system, pagination } = useSelector(
    (state) => state.study.study
  )
   const { records_per_page, current_page } = pagination
  let token = getItem('_token')
  if (token) {
    // setting Auth as true , if the user has already signed in and refresh the page
    isAuth = true
  }

  const location = useLocation()
  const dispatch = useDispatch()

  const mainContentRef = useRef(null)

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    // mainContentRef.current.scrollTop = 0
  }, [location])

  const [visibilityHiddenStatus, setVisibilityStatus] = useState(document[hidden]);

    useEffect(() => {
        function handleVisibilityChange() {
            setVisibilityStatus(document[hidden]);
        }

        document.addEventListener(visibilityChange, handleVisibilityChange);

        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        }
    }, []);

  useEffect(() => {
    if (!signIn.instance_permissions.api_fetched) {
      dispatch(pageLoad(records_per_page, current_page))
    }
  }, [])


  useEffect(() => {
    if(visibilityHiddenStatus){
      dispatch(refreshToken())
    }
  }, [visibilityHiddenStatus])

  
  useEffect(() => { 
    let mins_difference = signIn.loginAuthData?.exp?moment.duration(moment.tz(signIn.loginAuthData.exp,signIn.loginAuthData.tz).diff(moment.tz(signIn.loginAuthData.tz))).asMinutes():null;
    console.log(mins_difference)
    if(mins_difference && mins_difference < 1 ){
      dispatch(refreshToken())
    }
  })

  let filteredNav = nav.filter((nav) =>
    nav.permission?.some((permission) => {
      return user_ui_permission?.includes(permission)
    })
  )
  let finalNav = filteredNav.map((nav) => {
    if (nav.views) {
      nav.views = nav.views.filter((nav) =>
        nav.permission?.some((permission) => {
          return user_ui_permission?.includes(permission)
        })
      )
    }
    return nav
  })
  if (src_system.toLowerCase() === source_systems.INFORM && signIn.role === roles.DATA_MANAGER){
    finalNav = finalNav.filter((nav) => nav.name !== 'Query Detail Report')
  }
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    setSidenavOpen(!sidenavOpen)
  }

  const hasPermission = (permissionList) => {
    if (permissionList === true) {
      return true
    }
    if (user_ui_permission) {
      return user_ui_permission?.some((permission) =>
        permissionList?.includes(permission)
      )
    }
    return true
  }

  const style = {
    marginTop : '65px'
  }

  return (
    <>
      {signIn.showDefaultLayout === true ? (
        <>
          {source_system === auth_info.sch ? <Supernav history={props.history}/> : <AppHeader history={props.history}/>}
          {source_system === auth_info.sch ? <AppBody style={style}/>: <AppBody/>}
          <AppBody>
            { (location.pathname !== '/access-denied' && location.pathname !== '/status') && (
              <Sidebar
                className="side-margined"
                routes={finalNav}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                logo={{
                  innerLink: '/',
                  imgSrc: require('assets/img/brand/sdq-logo.png').default,
                  imgAlt: '...',
                }}
              />
            )}
            <div className="main-content" ref={mainContentRef}>
            <Container
                className="components-display"
                style={{ height: '100%' }}
              >
                <Switch>
                  {routes.map((route, index) => {
                    return route.component && (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) =>
                          (src_system.toLowerCase() === source_systems.INFORM && signIn.role === roles.DATA_MANAGER && route.path === '/query-detail-report') ? (
                            <>
                              <Redirect to="/dashboard" />
                            </>
                          ) : user_ui_permission?.length === 0 ? (
                            <Redirect to="/access-denied" />
                          ) : isAuth && hasPermission(route.permissions) ? (
                            <>
                              <route.component {...props} />
                            </>
                          ) : (
                            <Redirect to="/" /> 
                          ) 
                        }
                      />
                    )
                  })}
                  <Redirect to="/" />
                </Switch>
                </Container>
              {sidenavOpen && (
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
              )}
            </div>
          </AppBody>
        </>
      ) : ''
      }
    </>
  )
}

export default DefaultLayout
