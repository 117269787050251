import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from '../alert/AlertSlice'

const initialState = {
    celeryList: {
        data: [],
        paging: {
            "page_number": 1,
            "per_page": 10,
            "total_count": 0
        },
        sorting: {
            sort_field: '',
            sort_order: '',
        },
        load: false,
        data_updated: false,
    },
}

const celeryManagementSlice = createSlice({
    name: 'celeryManagement',
    initialState,
    reducers: {
        getCeleryListRequest(state) {
            state.celeryList.load = true
        },
        getCeleryListSuccess(state, action) {
            state.celeryList.data = action.payload.data.data
            state.celeryList.paging = action.payload.data.paging
            state.celeryList.load = false
        },
        geCeleryListFail(state) {
            state.celeryList.data = []
            state.celeryList.paging = {
                "page_number": 1,
                "per_page": 10,
                "total_count": 0
            }
            state.celeryList.load = false
        },
        updateSortField(state, action) {
            state.celeryList.sorting.sort_field = action.payload.sortField
            state.celeryList.sorting.sort_order = action.payload.sortOrder
        },
        updateCeleryList(state) {
            state.celeryList.data_updated = !state.celeryList.data_updated
        },
    },
})

export const { getCeleryListRequest, getCeleryListSuccess, geCeleryListFail, updateSortField, updateCeleryList, getCeleryRolesSuccess } = celeryManagementSlice.actions

export const getCeleryList = (params) => async (dispatch) => {
    try {
        dispatch(getCeleryListRequest())
        let searchParams = params?.searchParams ? `&search=${params.searchParams}` : ''
        let page = params?.page ? `&page=${params.page}` : ''
        let per_page = params?.per_page ? `&per_page=${params.per_page}` : ''
        let sortField = params?.sortField ? `&sort_by=${params.sortField}` : ''
        let sortOrder = params?.sortOrder ? `&order=${params.sortOrder}` : ''
        const { data } = await DataService.get(
            `/edit-checks/celery-tasks?${searchParams}${page}${per_page}${sortField}${sortOrder}`
        )
        dispatch(getCeleryListSuccess({ data }))
    } catch (error) {
        console.error('Get User List error:', error)
    }
}

export const killCeleryTask = (params, MSG) => async (dispatch) => {
    try {
        const { data } = await DataService.post(
            `/edit-checks/celery-tasks/kill`,
            params
          )
        dispatch(getCeleryListSuccess({ data }))
        dispatch(
            activateAlert({
                color: 'success',
                title: MSG.SUCCESS
            })
        )
    } catch (error) {
        dispatch(
            activateAlert({
                color: 'danger',
                title: MSG.ERROR
            })
        )
        console.error(`user error`, error)
    }
}

export default celeryManagementSlice.reducer
