/*
This component is used to display a spinner using interceptors whenever there is an api call so the user knows there is a background
activity going on.

In case the response returns an error code [3**, 4**, 5**] etc that is also handled that here and this code is moved here from config.axios.js
file
*/

import { useEffect, useState } from 'react';
import ShowSpinner from 'components/Spinner/spinner';
import { incrementRequest, decrementRequest } from 'reduxStore/slices/loaders/loaderSlice';
import { logoutUser } from 'reduxStore/slices/user/UserSlice'
import { useDispatch, useSelector } from 'react-redux';
import { getItem } from 'utils/localStorageController'
import CodeError from 'utils/CodeError';
const MSG_SERVER_ERROR = 'INTERNAL SERVER ERROR'

function Loader() {

    const dispatch = useDispatch()
    const loader = useSelector((state) => state.loader)
    const { signIn: { auth_info }  } = useSelector((state) => state.user)
    const { requestCount } = loader

    const [load, setState] = useState({ loading: false })

    useEffect(() => {
        if (requestCount > 0) {
            setState({ loading: true });
        } else {
            setState({ loading: false });
        }
    }, [requestCount])

    useEffect(() => {
        window.axios.interceptors.request.use(
            config => {
                if(config?.isPolling) {
                    return config
                } else {
                    dispatch(
                        incrementRequest()
                    )
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        window.axios.interceptors.response.use(
            response => {
                dispatch(
                    decrementRequest()
                )
                if (response.status == "401" || response.status == "302") {
                    window.location.href = "logout";
                    window.sessionStorage.clear();
                }
                return response;
            },
            error => {
                const { response } = error
                dispatch(
                    decrementRequest()
                )
                if (response) {
                    switch (response.status) {
                        case 401:
                            // dispatch logout action
                            if (getItem('app_info_type') === 'lsac' && response.config.url === '/auth/logout') {

                                const base_url = 'https://' + window.location.hostname
                                let url = `${getItem('LSAC_logout_url')}?redirect_uri=${base_url}`
                                window.location.replace(url)

                            }else if (response.config.url !== '/auth/login/' && response.config.url !== '/auth/icc-token/' && response.config.url !== '/auth/logout') {
                                dispatch(logoutUser('', getItem('app_info_type'), getItem('LSAC_logout_url') ))
                                throw 'Error - Unauthourized to use application'

                            }

                        case 500:
                            throw new CodeError(`${MSG_SERVER_ERROR}: ${response?.data?.error || ''}`, 500);
                        default:
                            throw new CodeError(`${response?.data?.error || ''}`, response?.data?.error_code);
                    }
                }
                return Promise.reject(error)
            }
        );

    }, [window.axios])

    return (
        <>{(load.loading) ?

            <div className="loader-container">
                <ShowSpinner variant="primary" color="primary"/>
            </div>
            : ''}</>
    );
}

export default Loader;
