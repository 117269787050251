import { useRef, useEffect } from 'react'
import NotificationAlert from 'react-notification-alert'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { deactivateAlert } from 'reduxStore/slices/alert/AlertSlice'
import './alert.scss'

const AlertContainer = styled.div`
  display: block;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`

const AlertTitle = styled.span`
  font-weight: 700;
`

const AlertMessage = styled.span``

function Alert() {
  const dispatch = useDispatch()
  const notifyEl = useRef(null)
  const { isAlertActive, type, title, content, autoDismiss  } = useSelector((state) => state.alert)


  useEffect(() => {
    if (isAlertActive) {
      myFunc()
      dispatch(deactivateAlert())
    }
  }, [isAlertActive])

  let options = {}
  options = {
    place: 'tc',
    message: (
  <AlertContainer>
        <AlertTitle className="alert-title" data-notify="title">
          {title}
        </AlertTitle>
        <AlertMessage data-notify="message" className="alert-text">{content} </AlertMessage>
      </AlertContainer>
    ),
    type: type,
    icon: type === 'info' ? 'fas fa-info' : `${type === 'warning' ? 'pi pi-exclamation-circle' : `fas fa-${type === 'success' ? 'check' : 'close'}`}`,
    autoDismiss: autoDismiss ? 0 : (type === 'danger' ? 0 : 4) ,
    
  }

  const myFunc = () => {
    notifyEl.current.notificationAlert(options)
  }
  return <NotificationAlert ref={notifyEl}/>
}

export default Alert
