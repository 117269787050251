import { createAsyncThunk } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { getItem } from 'utils/localStorageController'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import { pollingTestRun } from '../EditCheckSlice'
import exportFromJSON from 'export-from-json'
import Papa from 'papaparse'
import { roles, preconfig_domain_const } from 'variables/constants'
import { downloadBlob } from 'utils/downloadBlob'
import moment from 'moment'
import { setClearSearch, updateFilter } from 'reduxStore/slices/column-level-search'

const study_id = getItem('_study_id')

export const getEditCheckByStatus = createAsyncThunk(
  'editchecks/getEditCheckByStatus',
  async (params, thunkAPI) => {
    let editCheckType = params?.type ? `/${params.type}` : '/all'
    let studyId = params?.studyId ? params?.studyId : study_id
    let thunkAPIState = thunkAPI.getState()
    let userRole = thunkAPIState.user.signIn.role

    try {
      const { data } = await DataService.post(
        `edit-checks${editCheckType}?study_id=${studyId}`,
        params.api_body
      )
      if (
        ((userRole === roles['TESTER'] && params.type === 'assigned') ||
          (userRole === roles['DATA_MANAGER'] &&
            params.api_body.status === 'PENDING_APPROVAL')) &&
        data.data.length > 0
      ) {
        thunkAPI.dispatch(
          getEditCheckRunStatus({
            editCheckIds: data.data.map((eachEditCheck) => eachEditCheck.id).join(',')
        }))
      }
      return data
    } catch (error) {
      console.error('Get EC by Status Error:', error)
      return {}
    }
  }
)

export const getEditCheckCount = createAsyncThunk(
  'editchecks/getEditCheckCount',
  async (studyId) => {
    try {
      const { data } = await DataService.get(
        `/edit-checks/count?study_id=${studyId ? studyId : study_id}`
      )
      return data
    } catch (error) {
      console.error('Get EC Count error:', error)
      return []
    }
  }
)

export const getEditCheckWithFilter = createAsyncThunk(
  'editchecks/getEditCheckWithFilter',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.post(
        `/edit-checks/status/${params.inAction}?study_id=${study_id}`,
        params.api_body
      )
      return data
    } catch (error) {
      console.error('Get Edit Checks with Filter error:', error)
      return {}
    }
  }
)
export const updateBulkWorkflow = createAsyncThunk(
  'editchecks/updateBulkworkflow',
  async (params, thunkAPI) => {
    const columnLevelSearch = thunkAPI.getState().columnLevelSearch
    const filterCount = Object.values(columnLevelSearch.filter).length
    let action = params?.action ? `/${params.action}` : ''
    try {
      const { data } = await DataService.put(
        `/edit-checks/workflow${action}?study_id=${study_id}`,
        params.api_body
      )
      thunkAPI.dispatch(
        activateAlert({
          color: 'success',
          title: params.content ?? data.message,
        })
      )
      if(filterCount !== 0){
        thunkAPI.dispatch(setClearSearch())
        thunkAPI.dispatch(updateFilter({filter : {}}))
      }
      if (params?.path) {
        params.history.push(params.path)
      }
    } catch (error) {
      console.error('Update workflow error:', error)
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title:
            error.length < 20
              ? error
              : `${error.split(' ').slice(0, 7).join(' ')}...`,
        })
      )
    }
  }
)

export const bulkDeleteEditCheck = createAsyncThunk(
  'editchecks/deleteEditCheck',
  async (params, thunkAPI) => {
    const columnLevelSearch = thunkAPI.getState().columnLevelSearch
    const filterCount = Object.values(columnLevelSearch.filter).length
    try {
      const { data } = await DataService.delete(
        `/edit-checks/delete?study_id=${study_id}`,
        params.api_body
      )
      thunkAPI.dispatch(
        activateAlert({
          color: 'success',
          title: params.content || data.message,
        })
      )
      if(filterCount !== 0){
        thunkAPI.dispatch(setClearSearch())
        thunkAPI.dispatch(updateFilter({filter : {}}))
      }
    } catch (error) {
      console.error('Delete EditCheck error:', error)
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title:
            error.length < 20
              ? error
              : `${error.split(' ').slice(0, 7).join(' ')}...`,
        })
      )
    }
  }
)

export const saveCodeScript = createAsyncThunk(
  'editchecks/savecodescript',
  async (params, thunkAPI) => {
    const codeData = new FormData()
    codeData.append('source_code', params.code)
    try {
      const { data } = await DataService.post(
        `/edit-checks/${params.id}/script?study_id=${study_id}`,
        codeData,
        {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      )
      if (params?.status === 'TESTING') {
        thunkAPI.dispatch(
          updateBulkWorkflow({
            api_body: {
              ids: params.id,
              status: 'TESTING',
            },
            action: '',
            path: `/edit-checks/assigned?activetab=2`,
            history: params.history,
            content: params.content,
          }),
        )
      } else {
        thunkAPI.dispatch(
          activateAlert({
            color: 'success',
            title: params.content,
          })
        )
      }
    } catch (error) {
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title: error.message,
        })
      )
      console.error('Save script error: ', error)
    }
  }
)

export const getEditCheckRunStatus = createAsyncThunk(
  'editchecks/getEditCheckRunStatus',
  async (params, thunkAPI) => {
    const { editCheckIds, isPolling = false } = params
    try {
      const { data } = await DataService.get(
        `/edit-checks/run/status?study_id=${study_id}&edit_check_ids=${editCheckIds}`,
        {},
        null,
        isPolling
      )
      return { runStatus: data.data }
    } catch (error) {
      console.error('Get EditCheck Run Status: ', error)
    }
  }
)

export const triggerEditCheckRun = createAsyncThunk(
  'editchecks/triggerEditCheckRun',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.put(
        `/edit-checks/run?study_id=${study_id}`,
        {
          edit_check_ids: params.editCheckids,
        }
      )

     

        thunkAPI.dispatch(pollingTestRun({editCheckIds: params.editCheckids.join(','), locationPath: params.locationPath}))
      

    } catch (error) {
      console.error('Trigger Edit Check api error', error)
    }
  }
)

export const getTestLogData = createAsyncThunk(
  'editchecks/getTestLogData',
  async (params, thunkAPI) => {
    let page = params?.page ? `${params.page}` : 1
    let per_page = params?.per_page ? `${params.per_page}` : 10
    let sort_field = params.sortField ? `&sort_by=${params.sortField}` : ''
    let sort_order = params.sortOrder ? `&order=${params.sortOrder}` : ''
    try {
      const { data } = await DataService.get(
        `/edit-checks/run/test_results/${params.edit_check_id}?study_id=${study_id}&per_page=${per_page}&page=${page}${sort_field}${sort_order}`
      )
      let apiData = [...data.data]
      return {logdata:apiData,from: params.from ? params.from : "", currentPage: params.page, paging: data.paging}
    } catch (error) {
      console.error('Get EditCheck Run Status: ', error)
    }
  }
)

export const getTestSummaryData = createAsyncThunk(
  'editchecks/getTestSummaryData',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.get(
        `/edit-checks/run/test_summary/${params.edit_check_id}?study_id=${study_id}`
      )
      return data.data
    } catch (error) {
      console.error('Get EditCheck Run Status: ', error)
    }
  }
)


export const addtestSummary = createAsyncThunk(
  'editchecks/addtestSummary',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.post(
        `/edit-checks/run/test_summary?study_id=${study_id}`,
        params.api_body
      )
      params.alertMessageContent && thunkAPI.dispatch(
        activateAlert({
          color: 'success',
          title: params.alertMessageContent,
        })
      )
      if(params?.callTestSummary){
        thunkAPI.dispatch(getTestSummaryData({edit_check_id: params.api_body.study_edit_check_id}))
      }
    } catch (error) {
      console.error('Save script error: ', error)
    }
  }
)

export const getDqConfigStatus = createAsyncThunk(
  'editchecks/getDqConfigStatus',
  async (params, thunkAPI) => {
    try {
      const studyId = params?.study_id ? params.study_id : study_id
      const { data } = await DataService.get(
        `/edit-checks/dq-config/${params.rowId}?study_id=${studyId}`
      )
      return {data: data.data, study_edit_check_id: params.rowId}
    } catch (error) {
      console.error('Get DQ config status error: ', error)
    }
  }
)

export const getMapItems = createAsyncThunk(
  'editchecks/getMapItems',
  async (params, thunkAPI) => {
    try {
      const studyId = params?.study_id ? params.study_id : study_id
      const { data } = await DataService.get(
        `/edit-checks/dq-config/get-map-items?study_id=${studyId}`
      )
      return data
    } catch (error) {
      console.error('Get DQ config status error: ', error)
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title: params?.content,
        }))
    }
  }
)

export const handleSubmitDqConfig = createAsyncThunk(
  'editchecks/handleSubmitDqConfig',
  async (params, thunkAPI) => {
    try {
      const rowId = params?.rowId ? `/${params.rowId}` : ''
      const { data } = await DataService[params.method](
        `edit-checks/dq-config${rowId}?study_id=${params.study_id}`,
        params.api_body
      )
      thunkAPI.dispatch(
        activateAlert({
          color: 'success',
          title: 'DQ config saved.',
        }))
      return data.data
    } catch (error) {
      console.error('Submit DQ config error:', error)
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title: 'DQ config save Failed.',
        }))
      return {}
    }
  }
)

export const getPreconfMetadata = createAsyncThunk(
  'editchecks/getPreconfMetadata',
  async (params, thunkAPI) => {
    try {
      const { search, status = '', page, per_page, sort_by, order, studyId } = params.api_body;
      let param_page = page ? `&page=${page}` : ''
      let param_per_page = per_page ? `&per_page=${per_page}` : ''
      let sort_field = sort_by ? `&sort_by=${sort_by}` : ''
      let sort_order = order ? `&order=${order}` : ''
      let param_study_id = studyId ? `&study_id=${studyId}`  : `&study_id=${study_id}`

      if (status === preconfig_domain_const.VARIABLE) {
        const { data } = await DataService.get(
          `/studies-config/domain-variable/list?column_search=${search || ''}${param_study_id}${param_page}${param_per_page}${sort_field}${sort_order}`
        )
        return data
      } else if (status === preconfig_domain_const.CONFIG) {
        const { data } = await DataService.get(
          `/studies-config/domain-config/list?search=${search || ''}${param_study_id}${param_page}${param_per_page}${sort_field}${sort_order}`
        )
        return data
      } else {
        return {}
      }
    } catch (error) {
      console.error('Get preconf metadata by domain:', error)
      return {}
    }
  }
)

export const getPreconfMetadataCount = createAsyncThunk(
  'editchecks/getPreconfMetadataCount',
  async () => {
    try {
      const { data } = await DataService.get(
        `/studies-config/count?study_id=${study_id}`
      )
      return data
    } catch (error) {
      console.error('Get EC Count error:', error)
      return []
    }
  }
)

export const exportPreconfMetadata = (params) => async () => {
  try {
    const { search, status = '', page, per_page, sort_by, order, studyId } = params.api_body;
    let param_page = page ? `&page=${page}` : ''
    let param_per_page = per_page ? `&per_page=${per_page}` : ''
    let sort_field = sort_by ? `&sort_by=${sort_by}` : ''
    let sort_order = order ? `&order=${order}` : ''
    let param_study_id = studyId ? `&study_id=${studyId}`  : `&study_id=${study_id}`

    let response = {}
    if (status === preconfig_domain_const.VARIABLE) {
      const { data } = await DataService.get(
        `/studies-config/domain-variable/export?column_search=${search || ''}${param_study_id}${param_page}${param_per_page}${sort_field}${sort_order}`
      )
      response = data
    } else if (status === preconfig_domain_const.CONFIG) {
      const { data } = await DataService.get(
        `/studies-config/domain-config/export?search=${search || ''}${param_study_id}${param_page}${param_per_page}${sort_field}${sort_order}`
      )
      response = data
    }
    if(!response)
      return

    const parse_data = Papa.parse(response, {
      header: true,
      skipEmptyLines: true,
    })

    const logData =
      parse_data['data'].length > 0
        ? parse_data['data']
        : [
          Object.assign(
            {},
            ...parse_data['meta']['fields'].map((key) => ({ [key]: '' }))
          ),
        ]
    const fileName = `export_${params.api_body.status}_${moment().format(
      'DD-MM-YYYY-HH-mm-ss'
    )}`
    exportFromJSON({
      data: logData,
      fileName,
      exportType: exportFromJSON.types.csv,
    })
  } catch (error) {
    console.error('Get preconf metadata by domain:', error)
    return {}
  }
}

export const inheritWithCopyAndEdit = createAsyncThunk(
  'editchecks/inheritWithCopyAndEdit',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.post(
        `edit-checks/inherit`,
        params.api_body
      )
      return data
    } catch (error) {
      console.error('Inherit Error:', error)
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title: error.message,
        }))
      return {}
    }
  }
)

export const handleExportApprovedDQ = createAsyncThunk(
  'editchecks/handleExportApprovedDQ',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.post(
        `/edit-checks/all?study_id=${study_id}`,
        params,
        {
          'Accept': 'application/pdf',
        }
      )

      if (data) {
        downloadBlob(data, `SDQ_${params.status}_DQS_${moment().utc().format('DD-MMM-YYYY-HH-mm-ss')}.pdf`)
      }
    } catch (error) {
      console.error('Export DQ error:', error)
      thunkAPI.dispatch(
        activateAlert({
          color: 'danger',
          title: 'Export DQ Failed.',
        }))
      return {}
    }
  }
)

export const getVersionHistory = createAsyncThunk(
  'editchecks/getVersionHistory',
  async (params, thunkAPI) => {
    try {
      const { data } = await DataService.get(
        `/edit-checks/version_history?study_id=${study_id}&id=${params.api_body.id}`
      )
      return {data: data.data}
    } catch (error) {
      console.error('Get DQ config status error: ', error)
    }
  }
)

export const quickEditRuleDataForm = createAsyncThunk(
  'editchecks/quickEditRuleDataForm',
  async (params, thunkAPI) => {
    const columnLevelSearch = thunkAPI.getState().columnLevelSearch
    const filterCount = Object.values(columnLevelSearch.filter).length
    try {
      const new_api_body = {}
      new_api_body.query_text = params.api_body?.query_text
      new_api_body.dataset = params.api_body?.dataset
      new_api_body.auto_closure = params.api_body?.auto_closure
      const { data } = await DataService.put(
        `/edit-checks/${params.api_body?.id}/minor?study_id=${study_id}`,
        new_api_body
      )
      thunkAPI.dispatch(
        activateAlert({
          color: 'success',
          title: data.data.message,
        })
      )
      if(filterCount !== 0){
        thunkAPI.dispatch(setClearSearch())
        thunkAPI.dispatch(updateFilter({filter : {}}))
      }
      return data
    } catch (error) {
      console.error('Update rule data form error:', error)
      thunkAPI.dispatch(activateAlert({ color: 'danger', title: error.message }))
      return null
    }
  }
)

export const getDescription = createAsyncThunk(
  'editchecks/description',
  async (params) => {
    try {
      const { data } = await DataService.post(
        `/dq/predictions/subcategory-description?study_id=${study_id}`,
        params,
      )

      return data

    } catch (error) {
      console.error('Get description error: ', error)
    }
  }
)

export const regenerateDQCode = createAsyncThunk(
  'editchecks/regenerateDQCode',
  async (params) => {
    try{
      const { id, study_id } = params
      const { data } = await DataService.post(
        `/edit-checks/${id}/generate-rule?study_id=${study_id}`
      )
      return data
    }
    catch(error){
      console.error("Regenerate DQ code error: ", error)
    }
  }
)
