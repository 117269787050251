import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function TextToggler(props) {
  const { text, collapseAt, textClassName, wrapperClassName } = props
  const { t } = useTranslation()
  const CONSTANTS = t('COMMON')

  const [isExpanded, setExpanded] = useState(false)
  const value =
    typeof text === 'string' || typeof text === 'number' || Array.isArray(text)
        ? isExpanded
          ? text
          : typeof text === 'number' 
            ? text.toString().slice(0, collapseAt)
            : text.slice(0, collapseAt) 
        : null

  return (
    <span className={wrapperClassName}>
      {Array.isArray(text) ? 
      value?.map(item => <span className={textClassName}> {item} </span> )
      : <span className={textClassName}>{value + ' '}</span> }
      {isExpanded ? (
        <span
          role="button"
          className="text-toggler-item"
          onClick={(e) => {
            e.stopPropagation()
            setExpanded(false)
          }}
        >
          {(text?.length > collapseAt) ? CONSTANTS.READ_LESS : ''}
        </span>
      ) : (
        <span
          role="button"
          className="text-toggler-item"
          onClick={(e) => {
            e.stopPropagation()
            setExpanded(true)
          }}
        >
          {(text?.length > collapseAt) ? '...'+CONSTANTS.READ_MORE : ''}
        </span>
      )}
    </span>
  )
}

TextToggler.defaultProps = {
  text: '',
  collapseAt: 15,
  textClassName: null,
  wrapperClassName: null,
}

export default TextToggler
