import { Fragment } from 'react'
import { RightSectionBox, IconBox } from '../style'
import AppProfile from './AppProfile'
import { StudySelector } from './../style'
import StudyList from 'components/StudyList/index'
import RoleList from 'components/RoleList'
import { useSelector } from 'react-redux'
import { roles, auth_info } from 'variables/constants'

const RightSection = ({history}) => {
  const user = useSelector((state) => state.user)
  const { signIn } = user
  return (
    <Fragment>
      <RightSectionBox>
        {signIn?.auth_info?.type === auth_info.jwt && <RoleList/>}
        <IconBox>
          <AppProfile history={history}/>
        </IconBox>
      </RightSectionBox>
      <StudySelector className='text-right'>
        {!(
          signIn.role === roles.ACCOUNT_ADMIN ||
          signIn.role === roles.MODEL_MANAGER ||
          signIn.role === roles.SDQ_ADMIN
        ) && (
          <>
            {' '}
            <span className="study-title">Study</span> <StudyList />
          </>
        )}
      </StudySelector>
    </Fragment>
  )
}

export default RightSection
