import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import { setClearSearch, updateFilter } from '../column-level-search'
import store from 'store'
import unionBy from 'lodash/unionBy'

const initialState = {
  hub_admin_study_list: {
    data: [],
    load: false,
  },
  source_system_list: [],
  study_data: {
    study_id: '',
    description: '',
    project: '',
    therapeutic_area: '',
    indication: '',
    phase: '',
    status: '',
    edc_url: '',
    deep_link_url: '',
    is_dq_enabled: false,
    is_ml_enabled: false,
    instance_id: 0,
    source_system: '',
    lower_instance_study_id: null,
    lower_instace_study_name: '',
  },
  study_load: false,
  unmapped_studies: {
    data: [],
    load: false,
  },
  matched_studies: [],
}

const StudyRegistrationSlice = createSlice({
  name: 'StudySlice',
  initialState: initialState,
  reducers: {
    updateStudyData(state, action) {
      state.study_data[action.payload.key] = action.payload.value
    },
    setStudyLoad(state) {
      state.study_load = !state.study_load
    },
    cleanStudyModal(state) {
      state.study_data = initialState.study_data
    },
    setStudyData(state, action) {
      state.study_data = action.payload.study_data
    },
    
    reqUnmappedStudies(state, action){
      state.unmapped_studies.load = true
    },
    saveUnmappedStudies(state, action) {
      state.unmapped_studies.data = action.payload.data.data
      state.unmapped_studies.load = false
    },
    getUnmappedStudiesFail(state, action){
      state.unmapped_studies.load = false
    },

    updateSourceSystem(state, action){
      state.source_system_list = action.payload.data.data
    },
    saveMatchedStudies(state, action) {
      let matchingStudies = []
      matchingStudies =  action.payload.data.data
      
      if (state.unmapped_studies.data.length > 0) {
        if (matchingStudies.length > 0) {
          state.unmapped_studies.data = unionBy(
            matchingStudies,
            state.unmapped_studies.data,
            'study_id'
          )
          state.matched_studies = matchingStudies
        } else {
          state.unmapped_studies.data = state.unmapped_studies.data.filter(
            (el) =>
              !state.matched_studies.some((element) => el.id === element.id)
          )
          state.matched_studies = []
        }

        state.study_data.lower_instace_study_name =
          state.unmapped_studies.data[0].study_id
        state.study_data.lower_instance_study_id =
          state.unmapped_studies.data[0].id
      }
      
    },

    reqHubAdminStudies(state, action){
      state.hub_admin_study_list.load = true
    },
    setHubAdminStudies(state, action) {
      state.hub_admin_study_list.data = action.payload
      state.hub_admin_study_list.load = false
    },
    getHubAdminStudiesFail(state, action){
      state.hub_admin_study_list.load = false
    },

    populateStudyData(state, action) {
      const { study_id } = action.payload
      const currentStudyData = state.hub_admin_study_list.data.find(
        (study) => study.study_id === study_id
      )
      state.study_data.study_id = study_id
      state.study_data.therapeutic_area = currentStudyData.therapeutic_area
      state.study_data.description = currentStudyData?.study_name ?? ''
      state.study_data.indication = currentStudyData.indication
      state.study_data.project = currentStudyData.project
      state.study_data.phase = currentStudyData.phase
      state.study_data.status = currentStudyData.status
      state.study_data.source_system = currentStudyData.source_system
    },
  },
})

export const registerStudy = (studyData, MSG) => async (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  try {
    await DataService.post(
      `/studies?instance_id=${studyData.instance_id}`,
      studyData
    )
    dispatch(setStudyLoad())
    dispatch(
      activateAlert({
        title: MSG.SUCCESS,
        color: 'success',
      })
    )
    if(filterCount !== 0){
      dispatch(setClearSearch())
      dispatch(updateFilter({filter : {}}))
    }
  } catch (error) {
    dispatch(
      activateAlert({
        title: MSG.FAILED,
        color: 'danger',
      })
    )
    console.error('Register new study error:', error)
  }
}

export const updateStudy = (studyData, study_id, MSG) => async (dispatch) => {
  const { columnLevelSearch } = store.getState()
  const filterCount = Object.values(columnLevelSearch.filter).length
  try {
    if(study_id){
      await DataService.put(`/studies/${study_id}`, studyData)
      dispatch(setStudyLoad())
      dispatch(
        activateAlert({
          title: MSG.SUCCESS,
          color: 'success',
        })
      )
      if(filterCount !== 0) {
        dispatch(setClearSearch())
        dispatch(updateFilter({filter : {}}))
      }
    }
    else throw new Error("Study ID not present")
  } catch (error) {
    dispatch(
      activateAlert({
        title: MSG.FAILED,
        color: 'danger',
      })
    )
    console.error('Update study error:', error)
  }
}

export const getUnmappedStudies = (id) => async (dispatch) => {
  try {
    dispatch(reqUnmappedStudies())
    const { data } = await DataService.get(`/studies/instances/${id}/unmapped/`)
    dispatch(saveUnmappedStudies({ data }))
  } catch (err) {
    dispatch(getUnmappedStudiesFail())
    console.error('Ger UnMapped studies Fail:', err)
  }
}

export const getMatchedUATStudies = (studyDetails) => async (dispatch) => {
  try {
    let instance = store?.getState()?.instance?.instance?.activeInstanceID;
    let requestData = {...studyDetails.study_details[0]};
    requestData['instance_id'] = +instance;
    requestData['status'] = requestData['status']?.toUpperCase()
    const { data } = await DataService.post(`/studies/mapped_uat_studies`, requestData);
    dispatch(saveMatchedStudies({ data }))
  } catch (err) {
    console.log(err)
  }
}

export const getHubAdminStudies = (params) => async (dispatch) => {
  try {
    dispatch(reqHubAdminStudies())
    const { data } = await DataService.get(
      `/studies/hub-admin?instance_id=${params.instance_id}`
    )
    dispatch(setHubAdminStudies(data.data))
  } catch (error) {
    dispatch(getHubAdminStudiesFail())
    console.error('Get Hub admin studies error:', error)
  }
}

export const getSourceSystem = (params) => async (dispatch) => {
  try{
    const {data} = await DataService.get(
      `/studies/source-system`
    )
    dispatch(updateSourceSystem({ data }))
  }
  catch (error) {
    console.error("Get Form name error:", error);
  }
}

export const {
  updateStudyData,
  setStudyLoad,
  cleanStudyModal,
  setStudyData,
  reqUnmappedStudies,
  saveUnmappedStudies,
  getUnmappedStudiesFail,
  updateSourceSystem,
  saveMatchedStudies,
  reqHubAdminStudies,
  setHubAdminStudies,
  getHubAdminStudiesFail,
  populateStudyData,
} = StudyRegistrationSlice.actions
export default StudyRegistrationSlice.reducer
