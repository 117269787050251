import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'

const initialState = {
  whiteLabelData: {
    isLoading:true,
    isActive:false,  
    account_logo: '',
    account_fav_icon: '',
  },
  account_id: '',
  pendo_key: '',
  source_system:'',
  version: '',
  accounts: {
    list: [],
    load: false,
  },
  is_ml_model_code_gen: false,
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountMetaData: (state, action) => {
        if(action.payload.account_logo && action.payload.account_logo != ''){
            state.whiteLabelData.isActive = true;
            document.getElementById("fav_icon").href= action.payload.account_fav_icon;
        }else{
            state.whiteLabelData.isActive = false;
            document.getElementById("fav_icon").href= process.env.PUBLIC_URL + 'favicon.png';
        }
        state.whiteLabelData.isLoading = false;
        state.whiteLabelData.account_logo = action.payload.account_logo;
        state.whiteLabelData.account_fav_icon = action.payload.account_fav_icon;
        state.account_id = action.payload.account_id
        state.pendo_key = action.payload.pendo_key
        state.source_system = action.payload.source_system
        state.version = action.payload.account_version
        state.is_ml_model_code_gen = action.payload.is_ml_model_code_gen
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccounts.pending, (state) => {
        state.accounts.load = true
      })
      .addCase(getAccounts.fulfilled, (state, action) => {
        const { data = []} = action.payload
        state.accounts.list = data || []
        state.accounts.load = false
      })
      .addCase(getAccounts.rejected, (state) => {
        state.accounts.data = []
        state.accounts.load = false
      })
    }
})

export const {
  setAccountMetaData,
} = accountSlice.actions

export const getAccountMetaData = ({ id, first_name, last_name, role, email }) => async (dispatch) => {
  try {
    const { data } = await DataService.get(`/accounts/meta/`) 
    dispatch(setAccountMetaData(data))
    const { account_id, pendo_key} = data;
    window.pendoInit(process.env.NODE_ENV, { id, first_name, last_name, role, email, account_id },pendo_key) 
  } catch (error) {
    console.error('getAccountMetaData error', error)
  }
}

export const getAccounts = createAsyncThunk(
  'getAccounts',
  async () => {
    try {
      const { data } = await DataService.get(`/accounts`)
      return data
    } catch (error) {
      console.error('Get accounts by Status Error:', error)
      return {}
    }
  }
)

export default accountSlice.reducer
