import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from '../alert/AlertSlice'

const initialState = {
  queryResult: {
    list: [],
    load: false,
  },
  history: {
    list: [],
    load: false,
  },
}

const sqlConsoleSlice = createSlice({
  name: 'sql_console',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(executeQuery.pending, (state) => {
        state.queryResult.load = true
      })
      .addCase(executeQuery.fulfilled, (state, action) => {
        const { data = [] } = action.payload
        state.queryResult.list = data || []
        state.queryResult.load = false
      })
      .addCase(executeQuery.rejected, (state) => {
        state.queryResult.data = []
        state.queryResult.load = false
      })
      .addCase(getQueryHistory.pending, (state) => {
        state.history.load = true
      })
      .addCase(getQueryHistory.fulfilled, (state, action) => {
        const { data = [] } = action.payload
        state.history.list = data || []
        state.history.load = false
      })
      .addCase(getQueryHistory.rejected, (state) => {
        state.history.data = []
        state.history.load = false
      })
  },
})

export const executeQuery = createAsyncThunk(
  'executeQuery',
  async (params, thunkAPI) => {
    try {
      const path = params.is_sdq_admin
        ? `/accounts/admin/query?account_id=${params.account_id}`
        : '/accounts/query'
      const { data } = await DataService.post(path, params)
      if (data.data.message) {
        thunkAPI.dispatch(
          activateAlert({
            title: data.data.message,
            color: 'success',
          })
        )
      }
      return data
    } catch (error) {
      console.error('Execute Query Error: ', error)
      thunkAPI.dispatch(
        activateAlert({
          title: error.message,
          color: 'danger',
        })
      )
      return {}
    }
  }
)

export const getQueryHistory = createAsyncThunk('getQueryHistory', async () => {
  try {
    const { data } = await DataService.get(
      `access-logs?module_name=admin_sql&page_size=100`
    )
    return data
  } catch (error) {
    console.error('Get Admin Api History Error', error)
    return {}
  }
})

export default sqlConsoleSlice.reducer
