import { createSlice } from '@reduxjs/toolkit'
import {
  getEditCheckByStatus,
  getEditCheckCount,
  getEditCheckWithFilter,
  updateBulkWorkflow,
  bulkDeleteEditCheck,
  getEditCheckRunStatus,
  getTestLogData,
  getTestSummaryData,
  getDqConfigStatus,
  handleSubmitDqConfig,
  getPreconfMetadata,
  getPreconfMetadataCount,
  inheritWithCopyAndEdit,
  getVersionHistory,
  handleExportApprovedDQ,
  quickEditRuleDataForm,
  getMapItems,
  getDescription,
} from './services/editCheckthunk'
import moment from 'moment'
import { delay } from 'utils/delay'
import { downloadCSVblob } from 'utils/downloadBlob'
import { DataService } from 'config.axios'

const initialState = {
  editChecksByStatus: {
    load: false,
    data: [],
    count: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting: {
      sort_field: '',
      sort_order: '',
    },
    rules_selected: [],
  },
  editCheckRunStatus: {
    dryRunStatus: {},
  },
  editChecksTestLog: {
    load: false,
    data: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting: {
      sort_field: '',
      sort_order: '',
    },
  },
  editChecksTestSummary: {
    load: false,
    data: [],
  },
  editChecksTestSummaryTableData: {
    load: false,
    data: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting: {
      sort_field: '',
      sort_order: '',
    },
  },

  dqConfig: {
    study_edit_check_id: null,
    preconformance_item_name: '',
    target_dataset_refname: '',
    target_section_refname: '',
    target_item_refname: '',
    last_run_date: '',
    comment: '',
    load: false,
  },
  updateWorkFlowLoad: false,
  preconfMetadata: {
    load: false,
    data: [],
    count: [],
    pagination: {
      current_page: 1,
      records_per_page: 10,
      total_records: 0,
      pages: 0,
    },
    sorting: {
      sort_field: '',
      sort_order: '',
    },
  },
  quickEditDQ: {
    load: false,
    status: '',
  },
  exportApprovedDQ: {
    selectedExport: {
      load: false,
    },
    export: {
      load: false,
    },
  },
  inheritResult: {
    load: false,
    data: [],
  },
  verisonHistory: {
    load: false,
    data: [],
  },
  map_item_response: [],
  dqDescriptions: [],
}

const editCheckSlice = createSlice({
  name: 'editchecks',
  initialState,
  reducers: {
    updateSortField(state, action) {
      state.editChecksByStatus.sorting.sort_field = action.payload.sortField
      state.editChecksByStatus.sorting.sort_order = action.payload.sortOrder
    },
    updateRulesSelected(state, action) {
      state.editChecksByStatus.rules_selected = action.payload?.rules_selected
    },
    updateDqConfig(state, action) {
      state.dqConfig = action.payload
    },
    resetDQConfig(state, action) {
      state.dqConfig =
        Object.keys(action.payload).length === 0
          ? initialState.dqConfig
          : action.payload
    },
    resetQuickEdit(state, action) {
      state.quickEditDQ = { load: false, status: '' }
    },
    resetDryRunStatus(state, action) {
      state.editCheckRunStatus.dryRunStatus = {}
    },
    updateEditCheckTestLogSort(state, action) {
      state.editChecksTestLog.sorting.sort_field = action.payload.sortField
      state.editChecksTestLog.sorting.sort_order = action.payload.sortOrder
    },
    updateEditCheckTestSummarySort(state, action) {
      state.editChecksTestSummaryTableData.sorting.sort_field =
        action.payload.sortField
      state.editChecksTestSummaryTableData.sorting.sort_order =
        action.payload.sortOrder
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditCheckByStatus.pending, (state, action) => {
        state.editChecksByStatus.load = true
      })
      .addCase(getEditCheckByStatus.fulfilled, (state, action) => {
        const { data = [], paging = {} } = action.payload
        state.editChecksByStatus.load = false
        state.editChecksByStatus.data = data
        state.editChecksByStatus.pagination.current_page = paging['page_number']
        state.editChecksByStatus.pagination.records_per_page =
          paging['per_page']
        state.editChecksByStatus.pagination.total_records =
          paging['total_count']
        state.editChecksByStatus.pagination.pages = Math.ceil(
          paging['total_count'] / paging['per_page']
        )
      })
      .addCase(getEditCheckByStatus.rejected, (state, action) => {
        state.editChecksByStatus.data = []
        state.editChecksByStatus.load = false
        state.editChecksByStatus.pagination.total_records = 0
        state.editChecksByStatus.pagination.pages = 0
      })

      .addCase(getEditCheckCount.fulfilled, (state, action) => {
        const { data = [] } = action.payload
        state.editChecksByStatus.count = data
      })
      .addCase(getEditCheckCount.rejected, (state, action) => {
        state.editChecksByStatus.count = []
      })
      .addCase(getEditCheckWithFilter.pending, (state, action) => {
        state.editChecksByStatus.load = true
      })
      .addCase(getEditCheckWithFilter.fulfilled, (state, action) => {
        const { data = [], paging = {} } = action.payload
        state.editChecksByStatus.load = false
        state.editChecksByStatus.data = data
        state.editChecksByStatus.pagination.current_page = paging['page_number']
        state.editChecksByStatus.pagination.records_per_page =
          paging['per_page']
        state.editChecksByStatus.pagination.total_records =
          paging['total_count']
        state.editChecksByStatus.pagination.pages = Math.ceil(
          paging['total_count'] / paging['per_page']
        )
      })
      .addCase(getEditCheckWithFilter.rejected, (state, action) => {
        state.editChecksByStatus.data = []
        state.editChecksByStatus.load = false
        state.editChecksByStatus.pagination.total_records = 0
        state.editChecksByStatus.pagination.pages = 0
      })

      .addCase(updateBulkWorkflow.fulfilled, (state, action) => {
        state.updateWorkFlowLoad = !state.updateWorkFlowLoad
      })

      .addCase(bulkDeleteEditCheck.fulfilled, (state, action) => {
        state.updateWorkFlowLoad = !state.updateWorkFlowLoad
      })
      .addCase(quickEditRuleDataForm.pending, (state, action) => {
        state.quickEditDQ.load = true
      })
      .addCase(quickEditRuleDataForm.fulfilled, (state, action) => {
        const { status = '' } = action.payload
        state.updateWorkFlowLoad = !state.updateWorkFlowLoad
        state.quickEditDQ.status = status
      })
      .addCase(quickEditRuleDataForm.rejected, (state, action) => {
        state.quickEditDQ.load = false
      })

      .addCase(getEditCheckRunStatus.pending, (state, action) => {
        state.editChecksByStatus.load = true
      })
      .addCase(getEditCheckRunStatus.fulfilled, (state, action) => {
        state.editChecksByStatus.load = false
        if (action.payload) {
          const { runStatus } = action.payload
          if (state.editChecksByStatus.data.length > 0) {
            state.editChecksByStatus.data?.map((editCheckData) =>
              runStatus.map((runData) => {
                if (runData.ec_id === editCheckData.id) {
                  editCheckData.run_status = runData.status
                  editCheckData.job_run_id = runData.job_run_id
                  editCheckData.ec_exc_log_id = runData.ec_exc_log_id
                  editCheckData.run_status_created_at = runData.created_at
                  editCheckData.run_user = runData.user
                  state.editCheckRunStatus.dryRunStatus = runData
                }
              })
            )
          } else {
            state.editCheckRunStatus.dryRunStatus = runStatus[0]
          }
        }
      })
      .addCase(getEditCheckRunStatus.rejected, (state, action) => {
        state.editChecksByStatus.load = false
      })
      .addCase(getTestLogData.pending, (state, action) => {
        state.editChecksTestLog.load = true
      })
      .addCase(getTestLogData.fulfilled, (state, action) => {
        if (action.payload?.from == 'TEST_SUMMARY') {
          const { paging } = action.payload
          const { pagination } = state.editChecksTestSummaryTableData
          state.editChecksTestSummaryTableData.data = action.payload.logdata
          pagination.current_page = paging['page_number']
          pagination.records_per_page = action.payload.paging['per_page']
          pagination.total_records = paging['total_count']
          pagination.pages = Math.ceil(
            paging['total_count'] / paging['per_page']
          )
        } else {
          const { paging } = action.payload
          const { pagination } = state.editChecksTestLog
          state.editChecksTestLog.data = action.payload.logdata
          pagination.current_page = paging['page_number']
          pagination.records_per_page = action.payload.paging['per_page']
          pagination.total_records = paging['total_count']
          pagination.pages = Math.ceil(
            paging['total_count'] / paging['per_page']
          )
        }
      })
      .addCase(getTestLogData.rejected, (state, action) => {
        state.editChecksTestLog.data = []
        state.editChecksTestLog.load = false
        state.editChecksTestLog.pagination.total_records = 0
        state.editChecksTestLog.pagination.pages = 0
      })
      .addCase(getVersionHistory.pending, (state, action) => {
        state.editChecksTestLog.load = true
      })
      .addCase(getVersionHistory.fulfilled, (state, action) => {
        state.verisonHistory.data = action.payload.data.map((v) => {
          return {
            ...v,
            version: v.version,
            created_at: moment(v.created_at).isValid() && moment(v.created_at),
          }
        })
        state.editChecksTestLog.load = false
      })
      .addCase(getVersionHistory.rejected, (state, action) => {
        state.verisonHistory.data = []
        state.editChecksTestLog.load = false
      })
      .addCase(getTestSummaryData.pending, (state, action) => {
        state.editChecksTestSummary.load = true
      })
      .addCase(getTestSummaryData.fulfilled, (state, action) => {
        state.editChecksTestSummary.load = false
        state.editChecksTestSummary.data = action.payload ?? []
      })
      .addCase(getTestSummaryData.rejected, (state, action) => {
        state.editChecksTestSummary.load = false
        state.editChecksTestSummary.data = []
      })
      .addCase(getDqConfigStatus.fulfilled, (state, action) => {
        state.dqConfig = {
          ...state.dqConfig,
          ...action.payload.data,
          study_edit_check_id: action.payload.study_edit_check_id,
          last_run_date: action.payload.data?.last_run_date
            ? action.payload.data?.last_run_date
            : '',
        }
      })

      .addCase(handleSubmitDqConfig.pending, (state, action) => {
        state.dqConfig.load = true
      })
      .addCase(handleSubmitDqConfig.fulfilled, (state, action) => {
        state.dqConfig = { ...state.dqConfig, ...action.payload, load: false }
      })
      .addCase(handleSubmitDqConfig.rejected, (state, action) => {
        state.dqConfig = { ...state.dqConfig, ...action.payload, load: false }
      })
      .addCase(getPreconfMetadata.pending, (state) => {
        state.preconfMetadata.load = true
      })
      .addCase(getPreconfMetadata.fulfilled, (state, action) => {
        const { data = [], paging = {} } = action.payload
        state.preconfMetadata.load = false
        state.preconfMetadata.data = data
        state.preconfMetadata.pagination.current_page = paging['page_number']
        state.preconfMetadata.pagination.records_per_page = paging['per_page']
        state.preconfMetadata.pagination.total_records = paging['total_count']
        state.preconfMetadata.pagination.pages = Math.ceil(
          paging['total_count'] / paging['per_page']
        )
      })
      .addCase(getPreconfMetadata.rejected, (state) => {
        state.preconfMetadata.load = false
      })
      .addCase(getPreconfMetadataCount.fulfilled, (state, action) => {
        const { data = [] } = action.payload
        state.preconfMetadata.count = data
      })
      .addCase(getPreconfMetadataCount.rejected, (state, action) => {
        state.preconfMetadata.count = []
      })
      .addCase(inheritWithCopyAndEdit.pending, (state, action) => {
        state.inheritResult.load = true
      })
      .addCase(inheritWithCopyAndEdit.fulfilled, (state, action) => {
        const data = action.payload
        state.inheritResult.load = false
        state.inheritResult.data = data
      })
      .addCase(inheritWithCopyAndEdit.rejected, (state, action) => {
        state.inheritResult.data = []
        state.inheritResult.load = false
      })

      .addCase(handleExportApprovedDQ.pending, (state, { meta }) => {
        state.exportApprovedDQ.selectedExport.load =
          meta?.arg?.isSelected || false
        state.exportApprovedDQ.export.load = !meta?.arg?.isSelected || false
      })
      .addCase(handleExportApprovedDQ.fulfilled, (state) => {
        state.exportApprovedDQ.selectedExport.load = false
        state.exportApprovedDQ.export.load = false
      })
      .addCase(handleExportApprovedDQ.rejected, (state) => {
        state.exportApprovedDQ.selectedExport.load = false
        state.exportApprovedDQ.export.load = false
      })
      .addCase(getMapItems.fulfilled, (state, action) => {
        state.map_item_response = action?.payload?.data
      })
      .addCase(getDescription.fulfilled, (state, action) => {
        state.dqDescriptions = action.payload
      })
  },
})

export const {
  updateSortField,
  updateRulesSelected,
  updateDqConfig,
  resetDQConfig,
  exportApprovedDQ,
  resetQuickEdit,
  resetDryRunStatus,
  updateEditCheckTestLogSort,
  updateEditCheckTestSummarySort,
} = editCheckSlice.actions
export default editCheckSlice.reducer

export const pollingTestRun = (params) => async (dispatch) => {
  try {
    let is_running = true
    let timeCount = 0
    do {
      if (window.location.pathname === params?.locationPath) {
        if (window.location.pathname === '/edit-checks/code') {
          //Breaking the polling when the current edit check id is not the polling edit checck id. This is a temporary fix. Need to refactor.
          if (params.editCheckIds != window.currentEC.id) {
            is_running = false
            return
          }
        }
        await dispatch(
          getEditCheckRunStatus({
            editCheckIds: params.editCheckIds,
            isPolling: true,
          })
        )
        await delay(15000)
        timeCount = timeCount + 1
        if (timeCount === 20) is_running = false
      } else {
        is_running = false
        return
      }
    } while (is_running)
  } catch (error) {
    console.error('polling test run error', error)
  }
}

export const downloadEditCheckTestResults = (params) => async (dispatch) => {
  try {
    const { data } = await DataService.get(
      `edit-checks/export-csv/?study_ec_id=${params.sec_id}&study_id=${params.study_id}`
    )

    const moment = require('moment')
    const now = moment()
    const formattedDateTime = now.format('DDMMMYYYY_HHmmss')

    const fileName = `${params.name}_TestRunResults_${formattedDateTime}`

    downloadCSVblob(data, fileName)
  } catch (error) {
    console.log('Download Edit Check Test Results error', error)
  }
}
