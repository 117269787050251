export const permissions = {
  // dashboard
  DASHBOARD_READ: 'dashboard_read',
  DASBOARD_WRITE: 'dashboard_write',

  // user management
  USER_MANAGEMENT_WRITE: 'user_management_write',
  ACCESS_TOKEN_WRITE: 'access_token_write',
  AUDIT_LOG_WRITE: 'audit_log_write',

  // SDQ admin
  SQL_CONSOLE_WRITE: 'sql_console_write',

  // edit checks
  EDIT_CHECKS: 'edit_checks',
  NEW_RULE_WRITE: 'new_rule_write',
  NEW_RULE_DRAFT_WRITE: 'new_rule_draft_write',
  NEW_RULE_IN_DEVELOPMENT_WRITE: 'new_rule_in_development_write',
  NEW_RULE_IN_TESTING_READ: 'new_rule_in_testing_read',
  RULE_FOR_APPROVAL_WRITE: 'rule_for_approval_write',
  RULE_FOR_DEVELOPMENT_WRITE: 'rule_for_development_write',
  RULE_FOR_TESTING_WRITE: 'rule_for_testing_write',
  DEV_RULE_ASSIGNED_TO_ME_WRITE: 'dev_rule_assigend_to_me_write',
  DEV_RULE_ASSIGNED_PENDING_WRITE: 'dev_rule_assigned_pending_write',
  DEV_RULE_ASSIGNED_TESTING_WRITE: 'dev_rule_assigned_testing_write',
  DEV_RULE_ASSIGNED_APPROVAL_WRITE: 'dev_rule_assigned_approval_write',
  TEST_RULE_ASSIGNED_TO_ME_WRITE: 'test_rule_assigned_to_me_write',
  TEST_RULE_ASSIGNED_PENDING_WRITE: 'test_rule_assigned_pending_write',
  TEST_RULE_ASSIGNED_APPROVAL_WRITE: 'test_rule_assigned_approval_write',
  ACTIVE_RULE_WRITE: 'active_rule_write',
  ACTIVE_RULE_READ: 'active_rule_read',
  INACTIVE_RULE_WRITE: 'inactive_rule_write',
  INACTIVE_RULE_READ: 'inactive_rule_read',
  PRECONF_WRITE:'preconfig_write',

  // functions
  EDIT_CHECKS_FUNCTION: 'edit_checks_functions',
  NEW_FUNCTION_WRITE: 'new_function_write',
  NEW_FUNCTIONS_PENDING_WRITE: 'new_functions_pending_write',
  NEW_FUNCTIONS_PENDING_APPROVAL_WRITE: 'new_functions_pending_approval_write',
  NEW_FUNCTIONS_REJECTED_WRITE: 'new_functions_rejected_write',
  FUNCTION_FOR_APPROVAL_WRITE: 'fun_for_approval_write',
  ACTIVE_FUNCTION_READ: 'active_functions_read',
  INACTIVE_FUNCTION_READ: 'inactive_fun_read',

  // Predictions
  TODO_LIST: 'todo_list',
  TODO_LIST_WRITE: 'todo_list_write',

  PREDICTION_HISTORY: 'prediction_history',

  // query log
  QUERY_LOG: 'query_log',

  // study registration
  STUDY_REGISTRATION: 'study_registration',
  STUDY_REGISTRATION_WRITE: 'study_registration_write',
  STUDY_REGISTRATION_READ: 'study_registration_read',

  // study management
  STUDY_MANAGEMENT_WRITE: 'study_management_write',
  STUDY_MANAGEMENT_READ: 'study_management_read',

  //job management
  JOB_MANAGEMENT: 'job_managment',
  JOB_MANAGEMENT_WRITE: 'job_management_write',
  JOB_MANAGEMENT_READ: 'job_management_read',

  // release management
  RELEASE_MANAGEMENT: 'release_management',
  RELEASE_MANAGEMENT_WRITE: 'release_management_write',
  RELEASE_MANAGEMENT_READ: 'release_managment_read',

  // reports
  REPORTS: 'reports',

  // irl
  IRL_WRITE: 'irl_write',
  IRL_READ: 'irl_read',

  // qdr
  QDR: 'qdr',
}
