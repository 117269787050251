import { lsac_global_permission, global_permissions } from "variables/UserGlobalPermission"
import { roles } from 'variables/constants'

export const UIPermission = (role, instance_permission, instance_id, login_type) => {
  return [roles.ACCOUNT_ADMIN, roles.SDQ_ADMIN].includes(role)
    ? login_type === 'lsac'
      ? lsac_global_permission[role]
      : global_permissions[role]
     : instance_permission[instance_id]
}
