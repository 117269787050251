import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import ProfileIcon from 'Images/svg/ProfileIcon'
import {
  ProfileFirstLetter,
  ProfileIconWrapper,
  DropdownMenu,
  DropdownItem,
  MenuText,
} from '../style'
import FeatherIcon from 'feather-icons-react'
import switchAccount from 'utils/switchAccount'

const AppProfile = ({history}) => {
  let iconRef = useRef(null)
  const { signIn: { auth_info, userName }, LSACdata: account_switch_url   } = useSelector((state) => state.user)
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  useEffect(() => {
    document.addEventListener('mouseup', handleClick, false)
    return () => {
      document.removeEventListener('mouseup', handleClick, false)
    }
  }, [])

  const handleClick = (e) => {
    if (
      !iconRef.current.contains(e.target) &&
      !e?.target?.closest('#profile-dropdown')
    ) {
      setShowDropdownMenu(false)
    }
  }

  return (
    <div>
      <ProfileIconWrapper
        onClick={() => {
          setShowDropdownMenu(!showDropdownMenu)
        }}
        ref={iconRef}
      >
        <ProfileIcon />
        <ProfileFirstLetter>
          {userName?.charAt(0)?.toUpperCase()}
        </ProfileFirstLetter>
      </ProfileIconWrapper>

      {showDropdownMenu ? (
        <DropdownMenu id="profile-dropdown">
          <DropdownItem className="noti-title" header tag="div">
            <h6 className="text-overflow m-0">Welcome <span className="text-primary font-weight-bold">{userName}</span></h6>
          </DropdownItem>
          {
            auth_info?.type === 'lsac' && (
              <DropdownItem
            onClick={() => {
              switchAccount(account_switch_url)
            }}
          >
           <FeatherIcon icon={'refresh-cw'} size={'20'}/>
            <MenuText>Switch Account</MenuText>
          </DropdownItem>
            )
          }
          <DropdownItem
            onClick={() => {
              history.push('/logout')
            }}
          >
            <i className="ni ni-user-run" />
            <MenuText>Logout</MenuText>
          </DropdownItem>
        </DropdownMenu>
      ) : null}
    </div>
  )
}

export default AppProfile
