import { Row, Input } from 'sdq-ui'
import {debounce} from 'utils/debounce'
import { useState, useEffect } from 'react'
import '../SearchBar/index.scss'

function SearchBar(props) {
  const {
    id,
    forwardRef = null,
    label,
    noMargin,
    apiCall,
    value = '',
    onSearchChange = () => {},
    activeTab = 0
  } = props

  const optimizedVersion = debounce(apiCall, 600)  

  const [searchValue,setSearchValue] = useState(value ? value : '')

  useEffect(() => {
    setSearchValue(value)
  },[activeTab])

  const handleSearchChange = (value) => {
    optimizedVersion(value)
    setSearchValue(value)
    onSearchChange(value)
  }

  return (
    <Row className={`px-3 ${props?.headerSearch ? '' : 'mb-4'} ${props?.wrapperClass ?? ''}`}>
      <Input
        innerRef={forwardRef}
        className={`form-control form-control-sm ${props?.classes}`}
        type={'search'}
        id={id}
        data-test-id={id}
        placeholder="&#xF002; Search"
        fontFamily="Arial, FontAwesome"
        onChange={(e) => handleSearchChange(e.target.value)}
        value={searchValue}
      />
    </Row>
  )
}

export default SearchBar
