import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState, useEffect, useCallback } from 'react'
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import CustomSearch from 'components/CustomSearch';
import { Paginator } from 'primereact/paginator';
import './table.scss'
import { text_length, text_length_70 } from 'variables/constants'
import CustomDateSearch from 'components/CustomDateSearch'
import { useTranslation } from 'react-i18next';
import TextToggler from 'components/TextToggler';
import _ from 'lodash'

export const Table = (props) => {
    const { t } = useTranslation()
    const { TABLE } = t('COMPONENTS')
    const {
      total_records,
      records_per_page,
      current_page,
      onTableChange,
      data,
      clientSide,
      onRowClick,
      defaultSorted,
      isPaginated,
      isExpandable,
      expandedRows,
      onRowToggle,
      onRowExpand,
      rowExpansionTemplate,
      error_message
    } = props
    const [first, setFirst] = useState( ((current_page - 1)* records_per_page) || 0  );
    const [recordsPerPage, setRecordsPerPage] = useState(records_per_page ||  0)
    const [changeObj, setChangeObj] = useState({ page: 1, sizePerPage: 10, filters: {}, sortField: null, sortOrder: null, cellEdit: '' })
    const [sortField, setField] = useState('')
    const [sortOrder, setSortOrder] = useState('')
    const [tableData, setTableData] = useState([])
    const [totalRecords, setTotalRecords] = useState(0)

    const matchModes = [
        { label: 'Contains', value: 'CONTAINS' },
    ];

    useEffect(() => {
        let newObj = { ...changeObj }
        newObj.sortField = defaultSorted?.length && defaultSorted[0].dataField
        newObj.sortOrder = defaultSorted?.length && defaultSorted[0].order
        setChangeObj(newObj)
    }, [])

    useEffect(() => {
        if(current_page) {
            setFirst((current_page - 1)*recordsPerPage)
            setChangeObj({
                ...changeObj,
                page:  current_page
            })
        }
    }, [current_page])

    useEffect(() => {
        setTotalRecords(total_records ?? data.length)

        if (clientSide) {
            clientSidePagination(first)
        }
    }, [data])

    useEffect(() => {
        if (clientSide) {
            clientSidePagination()
            setFirst(0)
        }
    }, [recordsPerPage])

    useEffect(() => {
        setRecordsPerPage(records_per_page ? records_per_page : (isPaginated ? 10 : data.length))
    }, [records_per_page])

    const clientSidePagination = (page = 0) => {
        if (clientSide) {
            let tableData = data?.filter((itm, ind) => {
                return +page <= ind && ind < ((+page) + +recordsPerPage)
            })
            setTableData(tableData)
        }
    }

    const onSort = (e) => {
        let newObj = { ...changeObj }
        newObj.sortField = e.sortField
        newObj.sortOrder = e.sortOrder == 1 ? 'asc' : 'desc'
        setChangeObj(newObj)
        setField(e.sortField)
        setSortOrder(e.sortOrder)
        onTableChange && onTableChange('sort', newObj)
    }

    const onFilter = (e, filterType = '') => {
        let filters = {}
        Object.entries(e.filters).forEach(item => {
            if (item[1].value != null && item[1].value != '') {
                let obj = {
                    filterVal: item[1].value, filterType: props?.columns.filter(itm => {
                        return itm.dataField == item[0]
                    })[0].filter?.props?.type
                }
                filters[item[0]] = obj
            }
        })

        let newObj = { ...changeObj }
        newObj.filters = filters
        newObj.page = 1
        setChangeObj(newObj)
        onTableChange && onTableChange('filter', newObj)
    }

    const onPageChange = (e) => {
        console.log('pagechange', e);
        setFirst(e.first)
        let newObj = { ...changeObj }
        newObj.sizePerPage = +e.rows
        newObj.page = +e.page + 1
        setChangeObj(newObj)
        !_.isEqual(changeObj, newObj) && onTableChange && onTableChange('pagination', newObj)
        clientSide && clientSidePagination(e.first)
    }

    const truncateField = useCallback((entry, column) => {
        let { field } = column
        let value = entry[field]
        return typeof value == 'string' ? (
          <TextToggler
            text={value}
            collapseAt={
              props?.columns.length < 5 ? text_length_70 : text_length
            }
            textClassName={value?.length > text_length ? 'truncateClass' : ''}
          />
        ) : (
          value
        )
    }, [data])

    const sortFunction = (e) => e.data

    const clientSortFunction = (e) => {
        let newData = [...data]
        newData.sort(sortData)
        let sortedData = newData.filter((itm, ind) => {
            return first <= ind && ind < ((first) + recordsPerPage)
        })

        return sortedData
    }

    const sortData = (a, b) => {
        let firstItem = typeof a[sortField] == 'string'  ? a[sortField].toLowerCase()  : a[sortField]
        let secondItem = typeof b[sortField] == 'string'  ? b[sortField].toLowerCase()  : b[sortField]

        if (firstItem > secondItem) {
            if (sortOrder == 1) return 1;
            else return -1
        }
        if (firstItem == secondItem) return 0;
        if (firstItem < secondItem) {
            if (sortOrder == 1) return -1;
            else return 1
        }
    }

    const calenderFilterTemplate = (options, isTime, showTime) => {
        return (
            <CustomDateSearch value={options.value} onFilter={(e) => options.filterApplyCallback(e)} isTime={isTime} showTime={showTime} />
        );
    }

    const filterTemplate = (options) => {
        return (
            <CustomSearch onFilter={(e) => options.filterApplyCallback(e)} />
        )
    }

    const dynamicColumns = props?.columns.map((col, i) => {
        return <Column key={col.dataField} field={col.dataField} header={col.text != '' ? col.text : col.headerFormatter} sortable={col.sort}
            body={col.formatter ? col.formatter : truncateField}
            filter={col.filterRenderer ? true : false} showFilterMenu={false} showClearButton={false}
            sortFunction={clientSide ? clientSortFunction :  sortFunction}
            style={{ minWidth: col.minWidth ?? ''}}
            filterPlaceholder="&#xF002;"
            filterElement={col?.filter?.props?.type == 'DATE' ? (options) => calenderFilterTemplate(options, col.isTime, col.showTime) : (options) => filterTemplate(options)}
            filterFunction={(e) => onFilter(e, col.filterType)}
            hidden={col.hidden}
            filterMatchModeOptions={matchModes}
        />;
    });

    const expanderColumn = <Column expander={true} />

    const template = {
        layout: `RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink`,
        'RowsPerPageDropdown': (options) => {
            return (
                <div className='margin-t'>
                    <span>{TABLE.SHOW}: </span>
                    <select
                        data-test-id="select-pagination-size-per-page"
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm page-select"
                        style={{ width: '40px', padding: '0px' }}
                        onChange={(e) => {
                            setRecordsPerPage(e.target.value)
                            clientSide && clientSidePagination(e.first)
                        }}
                        value={recordsPerPage}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select> {TABLE.RECORDS}
                </div>
            );
        },
        'CurrentPageReport': (options) => {
            let last = options.currentPage * +options.rows
            props.setRecordsOnCurrentPage && props?.setRecordsOnCurrentPage(options.first - options.last)
            return (
                <span className='currentPageDetails'>
                    {TABLE.SHOWING} {options.first} {TABLE.TO} {last > options.last ? options.last : last} {TABLE.OF} {options.totalRecords} {TABLE.RECORDS}
                </span>
            )
        }
    }

    return (
        <>
            <DataTable value={clientSide ? tableData : data} className={`${props.classes} table-responsive table table-hover custom-table false`}
                rowClassName={props.rowClassName ? props.rowClassName : ''}
                filterDisplay={!props.hideFilters ? "row" : ''}
                onFilter={onFilter} onSort={onSort}
                sortField={sortField} sortOrder={sortOrder}
                onRowClick={onRowClick ? (e) => onRowClick(e) : ''}
                rows={5} first={first}
                expandedRows={expandedRows}
                onRowToggle={onRowToggle}
                onRowExpand={onRowExpand}
                rowExpansionTemplate={rowExpansionTemplate}
                emptyMessage = {error_message ?? 'No results found'}
            >
                {[isExpandable && expanderColumn,...dynamicColumns]}
            </DataTable>

            {props.data.length && isPaginated ?
                <Paginator  template={template} first={first} totalRecords={totalRecords} rows={recordsPerPage} onPageChange={(e) => onPageChange(e)}> </Paginator>
                : ''}
        </>
    );
}

Table.defaultProps = {
    data: [],
    clientSide: false,
    defaultSorted: [],
    isPaginated: true,
    hideFilters: false,
    isExpandable: false
}

export default Table;
