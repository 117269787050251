import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import { setClearSearch, updateFilter } from '../column-level-search'
import store from 'store'

const initialState = {
  studyList: {
      data: [],
      paging: {
        "page_number": 1,
        "per_page": 10,
        "total_count": 0
      },
      sorting: {
        sort_field: '',
        sort_order: '',
      },
  },
  ruleList: {
    history: false,
    study_name: null,
    study_id: null,
    active_dq_count:null,
    inactive_dq_count:null,
    data: [],
    paging: {
        "page_number": 1,
        "per_page": 10,
        "total_count": 0
      },
    sorting: {
        sort_field: '',
        sort_order: '',
    },
  },
  releaseHistoryList: {
    data: [],
    paging: {
        "page_number": 1,
        "per_page": 10,
        "total_count": 0
      },
    sorting: {
        sort_field: '',
        sort_order: '',
    },
  },
  release:{
      rules_selected:[],
      version: 0,
      comment: '',
      released: false,
  }
}

const ReleaseManagementSlice = createSlice({
  name: 'releaseManagement',
  initialState,
  reducers: {
    studyListForRelease: (state, action) => {
        state.studyList.data = action.payload.data.data
        state.studyList.paging = action.payload.data.paging
    },
    updateSortFieldStudyList(state, action) {
        state.studyList.sorting.sort_field = action.payload.sortField
        state.studyList.sorting.sort_order = action.payload.sortOrder
      },
    releaseHistoryList (state, action) {
        state.releaseHistoryList.data = action.payload.data.data
        state.releaseHistoryList.paging = action.payload.data.paging
    },
    updateSortFieldHistoryList(state, action) {
        state.releaseHistoryList.sorting.sort_field = action.payload.sortField
        state.releaseHistoryList.sorting.sort_order = action.payload.sortOrder
      },
    releaseHistoryReviewList(state, action){
        state.ruleList.data = action.payload.data
        state.ruleList.paging = action.payload.paging
        state.ruleList.active_dq_count = action.payload.active_dq_count
        state.ruleList.inactive_dq_count = action.payload.inactive_dq_count
    },
    updateSortFieldReviewList(state, action) {
        state.ruleList.sort_field = action.payload.sortField
        state.ruleList.sort_order = action.payload.sortOrder
      },
    cleanReviewPage(state) {
        state.ruleList = initialState.ruleList
    },
    masterCheckSelection(state, action){
        state.release.rules_selected = action.payload?.rules_selected       
    },
    releaseRulesList(state, action){
        state.ruleList.data = action.payload.api_data
        state.ruleList.paging = action.payload.paging
    },
    updateComment (state, action) {
        state.release.comment = action.payload.comment
    },
    pushedTOPROD (state, action) {
        state.release.version = action.payload.version
        state.release.released = !state.release.released
        state.release.comment = ''
        state.release.rules_selected = []
    },
    clearSelectedRules(state, action){
        state.release.rules_selected = []
        state.ruleList.data = action.payload.list
        state.release.comment = ''
    }
  },
})

export const { studyListForRelease, updateSortFieldStudyList, releaseHistoryList , updateSortFieldHistoryList, releaseHistoryReviewList, updateSortFieldReviewList, cleanReviewPage, releaseRulesList, masterCheckSelection,updateComment, pushedTOPROD, clearSelectedRules} = ReleaseManagementSlice.actions

export const getStudyListForRelease = (params) => async (dispatch) => {
    try {
        let page = params?.page ? `&page=${params.page}` : ''
        let per_page = params?.per_page ? `&per_page=${params.per_page}` : ''
        let sortField = params?.sortField ? `&sort_by=${params.sortField}` : ''
        let sortOrder = params?.sortOrder ? `&order=${params.sortOrder}` : ''
        let searchParams = ''
        if(params?.column_search){
          Object.keys(params?.column_search).forEach(
            (searchKey) =>
              (searchParams += `&search_${searchKey}=${params.column_search[searchKey]}`)
          )
        }
      const { data } = await DataService.get(
        `/release-versions?status=pending${page}${per_page}${sortField}${sortOrder}${searchParams}`
      )
      dispatch(studyListForRelease({data}))
    } catch (error) {
      console.error('Get Study list for Release error:', error)
    }
}

  export const getReleaseHistoryList = (params) => async (dispatch) => {
    try {
        let page = params?.page ? `&page=${params.page}` : ''
        let per_page = params?.per_page ? `&per_page=${params.per_page}` : ''
        let sortField = params?.sortField ? `&sort_by=${params.sortField}` : ''
        let sortOrder = params?.sortOrder ? `&order=${params.sortOrder}` : ''
        let searchParams = ''
        if(params?.column_search){
          Object.keys(params?.column_search).forEach(
            (searchKey) =>
              (searchParams += `&search_${searchKey}=${params.column_search[searchKey]}`)
          )
        }

    
      const { data } = await DataService.get(
        `/release-versions/history?${page}${per_page}${sortField}${sortOrder}${searchParams}`
      )
      dispatch(releaseHistoryList({data}))
    } catch (error) {
      console.error('Get Release History List error:', error)
    }
  }

  export const getReleaseHistoryReviewList = (params) => async (dispatch) => {
    try {
        let page = params?.page ? `&page=${params.page}` : ''
        let per_page = params?.per_page ? `&per_page=${params.per_page}` : ''
        let sortField = params?.sortField ? `&sort_by=${params.sortField}` : ''
        let sortOrder = params?.sortOrder ? `&order=${params.sortOrder}` : ''
        let searchParams = ''
        if(params?.column_search){
          Object.keys(params?.column_search).forEach(
            (searchKey) =>
              (searchParams += `&search_${searchKey}=${params.column_search[searchKey]}`)
          )
        }
    
      const { data } = await DataService.get(
        `/release-versions/${params.id}?${page}${per_page}${sortField}${sortOrder}${searchParams}`
      )
      dispatch(releaseHistoryReviewList({data: data.data.edit_check_details ?? [], paging: data.paging, active_dq_count: data.data.active_dq_count ?? null, inactive_dq_count: data.data.inactive_dq_count ?? null}))
    } catch (error) {
      console.error('Get Release History Review List error:', error)
    }
  }
  
  export const getReleaseRuleList = (params) => async (dispatch) => {
    try {
        let page = params?.page ? `&page=${params.page}` : ''
        let per_page = params?.per_page ? `&per_page=${params.per_page}` : ''
        let sortField = params?.sortField ? `&sort_by=${params.sortField}` : ''
        let sortOrder = params?.sortOrder ? `&order=${params.sortOrder}` : ''
        let searchParams = ''
        if(params?.column_search){
          Object.keys(params?.column_search).forEach(
            (searchKey) =>
              (searchParams += `&search_${searchKey}=${params.column_search[searchKey]}`)
          )
        }
    
      const { data } = await DataService.get(
        `/release-versions/study/${params.id}?status=pending${page}${per_page}${sortField}${sortOrder}${searchParams}`
      )
      let api_data = data.data
      

      dispatch(releaseRulesList({api_data, paging: data.paging}))
    } catch (error) {
      console.error('Get Release Rule List:', error)
    }
  }

  export const pushToPROD = (id,ec_ids, comment, content) => async (dispatch) => {
    const { columnLevelSearch } = store.getState()
    const filterCount = Object.values(columnLevelSearch.filter).length
    try {
            const { data } = await DataService.post(
                `/release-versions/`, {uat_study_id: id, description: comment, rule_ids: ec_ids}
              )
              if(data.data.version){
                dispatch(pushedTOPROD({version: data.release_id}))
              dispatch(
                  activateAlert({
                    title: content,
                    color: 'success',
                  })
                )
              }
        if(filterCount !== 0){
          dispatch(setClearSearch())
          dispatch(updateFilter({filter : {}}))
        }
       
    }
    catch(error){
        console.error('Push to PROD error:', error)
        dispatch(
            activateAlert({
              title: 'Release error',
              color: 'danger',
            })
          )
    }
  }
export default ReleaseManagementSlice.reducer
