import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import { activateAlert } from 'reduxStore/slices/alert/AlertSlice'
import {
  saveCustomListing,
  getFavouriteListings, getIsFavoriteListings, createFavouriteListing,
  getIRLListingsData,
} from './services/irl-thunk'
import { getItem, setItem } from 'utils/localStorageController'

const initialState = {
  domain_dataset: {},
  smart_search: {
    data: [
      {
        type: 'Domain',
        value: '',
      },
    ],
  },
  listing: {
    data: [],
    currentListing: {},
    fav_listings_data: [],
    customListing: {
      name: '',
      filterCondition: '',
    },
  },
  common_filter: {
    subject: [],
    site: [],
    form: [],
    visit: [],
    trigger_filter: false
  },
  quick_filters: {
    data_change: false,
    re_review: false,
    un_review: false,
    pending_dq: false,
    open_queries: false,
  },
  listingsData: {
    columnDefinition: [],
    scrollId: '',
    sortField: '',
    sortOrder: '',
    ColumnSeach: {}
  },
}

const study_id = getItem('_study_id')

const IRLSlice = createSlice({
  name: 'IRL',
  initialState,
  reducers: {
    updateSmartSearchValue: (state, action) => {
      state.smart_search.data[action.payload.index].value = action.payload.value
    },
    pushNewSmartSeacrhComponent: (state, action) => {
      state.smart_search.data.push({
        type: action.payload.type,
        value: action.payload.value,
      })
    },
    removeSmartSearchComponent: (state, action) => {
      state.smart_search.data = [
        ...state.smart_search.data.slice(0, action.payload.index),
        ...state.smart_search.data.slice(action.payload.index + 1),
      ]
    },
    setDomainDataSEtData: (state, action) => {
      state.domain_dataset = action.payload.data
    },
    clearSmartSearchComponent: (state) => {
      state.smart_search.data = [{ type: 'Domain', value: '' }]
    },
    clearListingSearch: (state) => {
      state.listing.currentListing = [{type: 'OTB', value: ''}]
      state.listing.currentListing = [{type: 'CUSTOM', value: ''}]
    },
    setListingData: (state, action) => {
      state.listing.data = action.payload.data
    },
    updateCustomListing: (state, action) => {
      state.listing.customListing[action.payload.name] = action.payload.value
    },
    updatequickFilter: (state, action) => {
      state.quick_filters[action.payload.filter] = action.payload.value
    },
    updateCommonFilter: (state, action) => {
      Object.keys(action.payload.subjid).forEach((key) => {
        state.common_filter.subject = state.common_filter.subject.concat(
          action.payload.subjid[key]
        )
      })
      Object.keys(action.payload.formname).forEach((key) => {
        state.common_filter.form = state.common_filter.form.concat(
          action.payload.formname[key]
        )
      })
      Object.keys(action.payload.siteno).forEach((key) => {
        state.common_filter.site = state.common_filter.site.concat(
          action.payload.siteno[key]
        )
      })
      Object.keys(action.payload.visit_nm).forEach((key) => {
        state.common_filter.visit = state.common_filter.visit.concat(
          action.payload.visit_nm[key]
        )
      })
    },
    setCurrentListing: (state, action) => {
      state.listing.currentListing = action.payload
      state.listing.data = state.listing.data.filter(data => (data.id !== action.payload.id))
      state.listing.data = [action.payload, ...state.listing.data]
    },
    setColumnDefs: (state, action) => {
      state.listingsData.columnDefinition = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCustomListing.fulfilled, (state, action) => {
        state.listing.customListing.name = ''
        state.listing.customListing.filterCondition = ''
        let temp_listing = {id: action.payload.listing_id, is_favourite: true}
        state.listing.data = [...state.listing.data, temp_listing]
        state.listing.currentListing = temp_listing
      })
      .addCase(getFavouriteListings.fulfilled, (state, action) => {
        state.listing.fav_listings_data = action.payload.data
      })

      .addCase(getIRLListingsData.fulfilled, (state, action) => {
        if (action.payload?.colDefs)
          state.listingsData.columnDefinition = action.payload?.colDefs

        if (action.payload?.scroll_id)
          state.listingsData.scrollId = action.payload?.scroll_id
          state.listingsData.ColumnSeach =
            action.payload?.column_filter ?? state.listingsData.ColumnSeach
      })
      
      .addCase(getIsFavoriteListings.fulfilled, (state, action) => {
        state.listing.data.map((listing) => {
          if (listing.id == action.payload.listing_id) {
            listing.is_favourite = action.payload.is_favourite
          }
          return listing
        })
      })

      .addCase(createFavouriteListing.fulfilled, (state, action) => {
        state.listing.data.map((listing) => {
          if (listing.id == action.payload.listing_id) {
            listing.is_favourite = action.payload.is_favourite
          }
          return listing
        })
      })
  },
})

export const {
  updateSmartSearchValue,
  pushNewSmartSeacrhComponent,
  removeSmartSearchComponent,
  setDomainDataSEtData,
  clearSmartSearchComponent,
  setListingData,
  updateCustomListing,
  updatequickFilter,
  setColumnDefs,
  clearListingSearch,
  setCurrentListing,
updateCommonFilter} = IRLSlice.actions

export const getdomainDataSet = () => async (dispatch) => {
  try {
    if(study_id){
      const { data } = await DataService.get(`/studies/${study_id}/dataset/`)
      dispatch(setDomainDataSEtData({ data: data.data }))
    }
    else throw new Error("Study ID not present !")
  } catch (error) {
    console.error('Get Study Domain DataSet Error:', error)
  }
}

export const getListingData = () => async (dispatch) => {
  try{
    const {data} = await DataService.get(
      `/irl/listings?study_id=${study_id}`
    )
    let apiData = data.data
    apiData = apiData.map(listing => {
      return {
        ...listing,
        is_favourite: true
      }
    })
    dispatch(setListingData(({data: apiData})))
  }
  catch(error){
    console.error("Get Listing Data error:", error)
  }
}

export const getFilterData = () => async (dispatch) => {
  try{
    const {data} = await DataService.get(
      `/irl/filter/common?study_id=${study_id}`
    )
    console.log("data",data)
    dispatch(updateCommonFilter((data.data)))
  }
  catch(error){
    console.error("Get Filter Data error: ", error)
  }
}


export default IRLSlice.reducer


