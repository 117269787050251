import { createSlice } from '@reduxjs/toolkit'
import { DataService } from 'config.axios'
import moment from 'moment'
import { downloadBlob } from 'utils/downloadBlob'
import { getItem } from 'utils/localStorageController'
import { auditLogConst } from 'variables/constants'

const study_id = getItem('_study_id')
const prediction_entity = auditLogConst.predictions.entity
const study_assignment_entity = auditLogConst.StudyAssignment.entity
const initialState = {
  list: [],
}

const AuditLogSlice = createSlice({
  name: 'auditLog',
  initialState,
  reducers: {
    setList: (state, action) => {
      const { data } = action.payload
      state.list = data
    },
    clearList: (state) => {
      state.list = []
    },
  },
})

export const { setList, clearList } = AuditLogSlice.actions

export const getAuditLogList =
  ({ entity, entity_id,Study_id, entity_name, exportLog }) =>
  async (dispatch) => {
    try {
      let updated_entity = ''
      if(entity == 'EDIT_CHECKS') {
        updated_entity = 'DQ'
      } else if(entity == 'EDIT_CHECKS_FUNCTIONS') {
        updated_entity = 'DQ_FUNCTIONS'
      } else {
        updated_entity = entity
      }

      let resPDF = exportLog ? { Accept: 'application/pdf' } : {}
      let additional_params = ``
      additional_params +=
      (entity === prediction_entity) ? `&study_id=${study_id}` :
        (entity === study_assignment_entity) ? `&study_id=${Study_id}` : ``
        let entity_id_param = entity_id ? `&entity_id=${entity_id}` : ``

      const { data } = await DataService.get(
        `/audit-logs/?entity_changed=${entity}${entity_id_param}&page=1&per_page=100${additional_params}`,
        resPDF
      )
      if (exportLog) {
        const fileName = `SDQ_AUDIT_LOG_${updated_entity}_${moment().utc().format('DD-MMM-YYYY-HH-mm-ss')}`
        downloadBlob(data, fileName)
      } else {
        dispatch(setList(data))
      }
    } catch (error) {
      console.error('getAllAuditLog error', error)
    }
  }

export default AuditLogSlice.reducer
