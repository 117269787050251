import { useEffect, useState } from 'react'
import BurgerIconBlack from 'Images/svg/BurgerIconBlack'
import BurgerIcon from 'Images/svg/BurgerIcon'
import { BurgerIconWarp } from '../style'
import { useDispatch, useSelector } from 'react-redux'
import { auth_info } from 'variables/constants'
import { sideNavOpen, sideNavClosed } from 'reduxStore/slices/instance'

const BurgerButton = () => {
  const [sideNav, setSidenav] = useState(true)
  const source_system = useSelector((state) => state.account.source_system)
  const dispatch = useDispatch()

  useEffect(() => {
    if (sideNav === true &&  document.getElementById('app-side-bar')?.style?.width !== '60px') {
      dispatch(sideNavOpen())
    } else {
      dispatch(sideNavClosed())
    }
  },[sideNav])

  const toggleSideBar = () => {
    let sidebarWidth = document.getElementById('app-side-bar')?.style?.width
    if (sidebarWidth != '60px') {
      document.getElementById('app-side-bar').style.width = '60px'
      document.body.classList.remove('g-sidenav-show')
      setSidenav(false)
    } else {
      document.getElementById('app-side-bar').style.width = '250px'
      document.body.classList.add('g-sidenav-show')
      setSidenav(true)
    }
  }
  return (
    <BurgerIconWarp onClick={toggleSideBar} id="burger-icon-wrap">
      {source_system === auth_info.sch ? <BurgerIconBlack /> : <BurgerIcon/> }
    </BurgerIconWarp>
  )
}

export default BurgerButton
